import React, { useState } from 'react'
import { Logger } from '@spa-core/logger'
import { isPuppeteer, getHtmlForKey } from './utils'
import { AddedSSRElements, ComponentLoaderOptions, LoadedComponents, LoaderOptions, Module } from './interfaces'
import { stripDiv } from './utils'
import cleanKey from '@spa-core-js/util/cleanKey'
import InViewport from './InViewport'
import { ComponentType } from '@spa-core/store/content-slots/constants'
import { SSR_ELEMENTS_KEY } from './constants'

export enum Hydrate {
    ALWAYS = 'always',
    DELAY = 'delay',
    HOVER = 'hover',
    IN_VIEW = 'inView',
    NEVER = 'never',
}

const loadedComponents: LoadedComponents = {}
const addedElements: AddedSSRElements = {}
let initialStateElement: HTMLElement = typeof document === 'undefined' ? undefined : document.getElementById('initial-state')
const windowSSRElements: any = window[SSR_ELEMENTS_KEY]
const componentLoaderOptions: ComponentLoaderOptions = {}

const addSSRElemenentsArray = (): void => {
    const head: HTMLHeadElement = document.head || document.getElementsByTagName('head')[0]
    initialStateElement = document.createElement('script')
    initialStateElement.id = 'ssr-elements'
    head.appendChild(initialStateElement)
    initialStateElement.appendChild(document.createTextNode(`window.${SSR_ELEMENTS_KEY} = [];`))
}

const addSSRElemenent = (key: string): void => {
    if (addedElements[key]) return
    const head: HTMLHeadElement = document.head || document.getElementsByTagName('head')[0]
    initialStateElement = document.createElement('script')
    initialStateElement.id = 'ssr-elements'
    head.appendChild(initialStateElement)
    initialStateElement.appendChild(document.createTextNode(`window.${SSR_ELEMENTS_KEY}.push('${key}');`))
    addedElements[key] = true
}

if (isPuppeteer) addSSRElemenentsArray()

export const getLoadComponentFunction =
    (importComponent: () => any, componentName: string, renderComponentWhenLoaded: (loaded: boolean) => void): (() => void) =>
    (): void => {
        importComponent().then((module: Module): void => {
            loadedComponents[componentName] = module.default
            renderComponentWhenLoaded(true)
        })
        if (isPuppeteer) {
            /**
             * When prerendering dont bother with trying to render any fallback content
             */
            return null
        }
    }

export const loadWithPrerender = (importComponent: () => any, componentName: string, options: LoaderOptions = undefined) => {
    componentLoaderOptions[componentName] = options
    return (componentInstanceProps: any) => {
        const { children, ssrKey: ssrKeyProp, hydrate, forceLoadOnDevice: forceLoadOnDeviceProp } = componentInstanceProps
        Logger.trace('rendering', componentName, children, ssrKeyProp)
        const [, renderComponentWhenLoaded] = useState<boolean>(false)
        const ssrKey: string = cleanKey(ssrKeyProp)
        // Todo avoid recomiling every render
        const loadComponent: () => void = getLoadComponentFunction(importComponent, componentName, renderComponentWhenLoaded)

        let hydrationMethod: Hydrate = hydrate || componentLoaderOptions[componentName]?.hydrate || Hydrate.ALWAYS
        const forceLoadOnDevice: boolean =
            forceLoadOnDeviceProp || componentLoaderOptions[componentName]?.forceLoadOnDevice || false
        if (window.innerWidth < 768 && hydrationMethod !== Hydrate.ALWAYS) {
            hydrationMethod = Hydrate.IN_VIEW
        }
        if (!windowSSRElements) {
            hydrationMethod = Hydrate.ALWAYS
        }

        if (!loadedComponents[componentName]) {
            loadComponent()
            if (!windowSSRElements && isPuppeteer) {
                // When prerendering dont bother with trying to render any fallback content
                return null
            }

            // For mobile views rely on the scrolling for hydrating the non loaded components
            // unless you add the force option
            if (window.innerWidth < 768 && windowSSRElements && !forceLoadOnDevice && hydrationMethod === Hydrate.ALWAYS) {
                const _fallback: string = getHtmlForKey(ssrKey)
                let fallback: string = _fallback
                if (_fallback.length === 0) {
                    fallback = '<div></div>'
                    loadComponent()
                }
                return (
                    <InViewport loadComponent={loadComponent}>
                        <div dangerouslySetInnerHTML={{ __html: fallback }} />
                    </InViewport>
                )
            }

            switch (hydrationMethod) {
                case Hydrate.DELAY:
                    setTimeout(loadComponent, componentLoaderOptions[componentName]?.timeout || 1000)
                    // In client without the real compoent
                    // look for pre-rendered content to use instead
                    return <div dangerouslySetInnerHTML={{ __html: getHtmlForKey(ssrKey) }} />
                case Hydrate.IN_VIEW:
                    return (
                        <InViewport loadComponent={loadComponent}>
                            <div dangerouslySetInnerHTML={{ __html: getHtmlForKey(ssrKey) }} />
                        </InViewport>
                    )
                case Hydrate.ALWAYS:
                    loadComponent()
                    if (componentName === ComponentType.BANNER || componentName === ComponentType.BANNER_WRAPPER) {
                        const allHtml: string = getHtmlForKey(ssrKey)
                        const { html, classesInWrapper } = stripDiv(allHtml)
                        return <div className={classesInWrapper} dangerouslySetInnerHTML={{ __html: html }} />
                    }
                    return <div dangerouslySetInnerHTML={{ __html: getHtmlForKey(ssrKey) }} />
                case Hydrate.NEVER:
                    return <div dangerouslySetInnerHTML={{ __html: getHtmlForKey(ssrKey) }} />
                case Hydrate.HOVER:
                default:
                    return (
                        <div
                            dangerouslySetInnerHTML={{ __html: getHtmlForKey(ssrKey) }}
                            onMouseEnter={loadComponent}
                            onTouchStart={loadComponent}
                            onScroll={loadComponent}
                            onClick={loadComponent}
                        />
                    )
            }
        }
        const element: React.ReactNode =
            React.createElement(loadedComponents[componentName], componentInstanceProps, componentInstanceProps.children) || null
        if (isPuppeteer) {
            addSSRElemenent(ssrKey)
        }
        return <>{element}</>
    }
}
