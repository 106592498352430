import React from 'react'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { useSelector } from 'react-redux'
import { ContentSlotComponentProps } from '@spa-ec/components/ContentSlot/interfaces'

type ComponentState = {
    sessionConfig: SessionConfig
}

export interface Props extends ContentSlotComponentProps {
    external?: boolean | string
    target?: string
    to?: string
    titleClassName?: string
    executeBeforeNavigation?: Function
    title?: string
    className?: string
    children?: React.ReactNode
    linkTitle?: string
}

const Link: React.FC<Props> = ({
    target,
    to,
    titleClassName,
    title,
    className,
    executeBeforeNavigation,
    children,
    linkTitle,
    ssrKey,
    elementUid,
    contentSlotCatalogVersion,
    componentParentType,
    external = false,
}): React.ReactNode => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    external = external === 'true' || external === true
    target = target === 'NEWWINDOW' || target === 'newWindow' ? '_blank' : target
    target = target === 'SAMEWINDOW' || target === 'sameWindow' ? '_self' : target

    let url: string = ''
    if (!external && to.indexOf('http') === 0) {
        if (sessionConfig.urlPrefix.indexOf('http') === 0) {
            url = to.replace(sessionConfig.urlPrefix, '')
        } else {
            url = to.replace(`${sessionConfig.baseUrl}${sessionConfig.urlPrefix}`, '')
        }
    } else {
        url = to
    }

    const stylingClassesForTitle: string = titleClassName || undefined

    return (
        <div
            data-ssr-key={ssrKey}
            data-smartedit-component-id={ssrKey}
            data-smartedit-component-uuid={elementUid}
            data-smartedit-catalog-version-uuid={contentSlotCatalogVersion}
            data-smartedit-component-type={componentParentType}
            className="smartEditComponent"
        >
            {url.indexOf('www') < 0 && !external ? (
                <ReactRouterDomLink
                    title={title}
                    className={className}
                    target={target}
                    to={url}
                    onClick={() => {
                        executeBeforeNavigation?.()
                    }}
                >
                    {children}
                    <div
                        className={
                            to?.toLowerCase() === window.location.href.toLowerCase()
                                ? 'selected ' + stylingClassesForTitle
                                : stylingClassesForTitle
                        }
                    >
                        {linkTitle || ''}
                    </div>
                </ReactRouterDomLink>
            ) : (
                <div>
                    {url.indexOf('www') > 0 || url.indexOf('http://') === 0 || url.indexOf('https://') === 0 ? (
                        <a title={title} href={url} target={target}>
                            {linkTitle || children}
                        </a>
                    ) : (
                        <a title={title} href={url}>
                            {children}
                        </a>
                    )}
                </div>
            )}
        </div>
    )
}

export default Link
