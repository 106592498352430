import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import browserSvc from '@spa-core-js/services/browserSvc'
import { ActionTypes, MessageLevels, NAME as globalMessagesReducerName } from '@spa-core/store/global-messages/constants'
import { Message } from '@spa-core/store/global-messages/interfaces'
import { Store } from '@spa-core/store'
import MessageComponent from './Message'
import scrollToComponent from 'react-scroll-to-component'
import { addMessage, removeMessage } from '@spa-core/store/global-messages/actions'

type ComponentState = {
    messages: Message[]
}

const GlobalMessages: React.FC = () => {
    const messagesStore = ({ reducers }: Store) => reducers[globalMessagesReducerName].messages
    const selector = createSelector(
        [messagesStore],
        (messagesStore): ComponentState => ({
            messages: messagesStore,
        }),
    )
    const { messages }: ComponentState = useSelector(selector)

    let displayingMessageIds: (number | string)[] = []
    const animatedListWrapperRef = useRef<any>(null)
    const [openMessage, setOpenMessage] = useState<boolean>(false)

    const dispatch = useDispatch()

    const displayLogOutMessage = (logoutMsgStr: string): void => {
        const logoutMsg = JSON.parse(logoutMsgStr)
        logoutMsg.type = ActionTypes.ADD_MESSAGE
        browserSvc.sessionRemove('logout_msg')
        const payload: Message = {
            title: logoutMsg.title,
            message: logoutMsg.message,
            level: MessageLevels.INFO,
            id: logoutMsg.id,
            displaySeconds: 10,
        }
        dispatch(addMessage(payload))
    }

    useEffect(() => {
        const logoutMsgStr: string = browserSvc.sessionGet('logout_msg')
        if (logoutMsgStr) displayLogOutMessage(logoutMsgStr)
        const newMessages: Message[] = messages.filter(({ id }) => !displayingMessageIds.includes(id))
        newMessages.forEach(setRemoveMessageTimeout.bind(this))

        if (newMessages.length) {
            setOpenMessage(true)
            scrollToComponent(animatedListWrapperRef?.current)
        }
        displayingMessageIds.push(...newMessages.map(({ id }) => id))
    }, [messages.length])

    const setRemoveMessageTimeout = (message: Message) => {
        setTimeout(() => {
            setOpenMessage(false)
            dispatch(removeMessage(message.id))
            displayingMessageIds = displayingMessageIds.filter((id) => id !== message.id)
        }, message.displaySeconds * 1000)
    }

    const animatedListAndMessages = useMemo(
        () => (
            <div className={openMessage ? 'animate_grow' : 'animate_shrink'}>
                {messages.map((message: Message) => (
                    <MessageComponent
                        key={message.id}
                        id={message.id}
                        level={message.level}
                        title={message.title}
                        message={message.message}
                        openMessage={openMessage}
                    />
                ))}
            </div>
        ),
        [messages.length, openMessage],
    )

    return animatedListAndMessages
}

export default GlobalMessages
