import React from 'react'
import Link from '@ui-elem/Link'
import { ContentSlotComponentProps } from '../../interfaces'

export interface Props extends ContentSlotComponentProps {
    url: string
    content: string
}

/**
 * Class for USPItems in the header of the page. The main purpose of this component is to render the
 * items in the header of the page and to handle if there is a link associated with the item or not.
 */
const USPItem: React.FC<Props> = ({ url, content, ssrKey, elementUid, contentSlotCatalogVersion, componentParentType }) => (
    <div
        className={'e2e-ver-usp-item smartEditComponent text-sm flex'}
        data-smartedit-component-id={ssrKey}
        data-smartedit-component-uuid={elementUid}
        data-smartedit-catalog-version-uuid={contentSlotCatalogVersion}
        data-smartedit-component-type={componentParentType}
    >
        {url ? <Link to={url}>{content}</Link> : <div className="m-auto" dangerouslySetInnerHTML={{ __html: content }} />}
    </div>
)

export default USPItem
