import { ActionTypes } from './constants'
import { CSAdminStore } from './interfaces'
import { Action } from '../interfaces'

export const INITIAL_STATE: CSAdminStore = {
    setCustomerErrorMessage: {},
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): CSAdminStore => {
    switch (type) {
        case ActionTypes.SET_SET_CUSTOMER_ERROR_MESSAGE:
            const { errorMessage, customerId } = payload
            return {
                ...state,
                setCustomerErrorMessage: {
                    ...state.setCustomerErrorMessage,
                    [customerId]: errorMessage,
                },
            }
        default:
            return state
    }
}
