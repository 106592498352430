import { ActionTypes } from './constants'
import { CustomerTypeWarningDialog, LogInPayload } from './interfaces'

export const fetchPromotions = () => ({
    type: ActionTypes.FETCH_PROMOTIONS,
})

export const updateSessionConfig = () => ({
    type: ActionTypes.UPDATE_SESSION_CONFIG,
})

export const productDetailsViewed = (productCode: string) => ({
    type: ActionTypes.PRODUCT_DETAILS_VIEWED,
    payload: {
        productCode,
    },
})

export const productInformationExpanded = (expanded: boolean) => ({
    type: ActionTypes.PRODUCT_INFORMATION_EXPANDED,
    payload: {
        expanded,
    },
})

export const updateCustomerTypeWarningDialog = (payload: CustomerTypeWarningDialog) => ({
    type: ActionTypes.SET_CUSTOMER_TYPE_CHANGE_WARNING,
    payload: {
        payload,
    },
})

export const productSpecificationExpanded = (expanded: boolean) => ({
    type: ActionTypes.PRODUCT_DETAILS_VIEWED,
    payload: {
        expanded,
    },
})

export const setPurchaseQuantity = (productCode: string, quantity: number) => ({
    type: ActionTypes.SET_PURCHASE_QUANTITY,
    payload: {
        productCode,
        quantity,
    },
})

export const checkCustomerToBeLoggedOff = (targetCountryUrl?: string) => ({
    type: ActionTypes.CHECK_CUSTOMER_TO_BE_LOGGED_OFF,
    payload: {
        targetCountryUrl,
    },
})

export const logoutSwitchCustomerTypeOrChangeLanguage = (targetCountryUrl: string = undefined) => ({
    type: ActionTypes.LOG_OUT_SWITCH_CUSTOMER_TYPE_OR_CHANGE_LANGUAGE,
    payload: {
        targetCountryUrl,
    },
})

export const setCollapsablePanelExpanded = (collapsablePanelId: string, expanded: boolean) => ({
    type: ActionTypes.SET_COLLAPSABLE_PANEL_EXPANDED,
    payload: {
        collapsablePanelId,
        expanded,
    },
})

export const fetchPersonalizedDeliveryOptions = (postcode?: string) => ({
    type: ActionTypes.FETCH_PERSONALIZED_DELIVERY_OPTIONS,
    payload: {
        postcode,
    },
})

export const fetchAccountProfile = () => ({
    type: ActionTypes.FETCH_ACCOUNT_PROFILE,
})

export const subscribeToNewsletter = (email: string) => ({
    type: ActionTypes.SUBSCRIBE_TO_NEWSLETTER,
    payload: {
        email,
    },
})

export const resetForm = () => ({
    type: ActionTypes.RESET_SUBSCRIBE_TO_NEWSLETTER_FORM,
})

export const requestPasswordEmail = (email: string) => ({
    type: ActionTypes.REQUEST_PASSWORD_EMAIL,
    payload: {
        email,
    },
})

export const requestPasswordCustomerNumber = (customerNumber: string) => ({
    type: ActionTypes.REQUEST_PASSWORD_CUSTOMERNUMBER,
    payload: {
        customerNumber,
    },
})

export const resetRequestPasswordForm = () => ({
    type: ActionTypes.RESET_REQUEST_PASSWORD_FORM,
})

export const logIn = (payload: LogInPayload) => ({
    type: ActionTypes.LOG_IN,
    payload,
})

export const setLogInErrorMessage = (loginErrorMessage: string) => ({
    type: ActionTypes.SET_LOG_IN_ERROR_MESSAGE,
    payload: {
        loginErrorMessage,
    },
})

export const refreshSitemap = () => ({
    type: ActionTypes.REFRESH_SITEMAP,
})

export const fetchOrderHistory = (page: number) => ({
    type: ActionTypes.FETCH_ORDER_HISTORY,
    payload: {
        page,
    },
})

export const fetchOrder = (orderCode: string) => ({
    type: ActionTypes.FETCH_ORDER,
    payload: {
        orderCode,
    },
})
