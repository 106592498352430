import { ProductAvailability } from '../products/constants'
import { getProductStockStatus, parseImageSubset } from '../products/utils'
import { CartEntry, CartEntryStockStatus, RecommendedCartEntry } from './interfaces'

export const parseCartEntries = (entries: CartEntry[], fallbackImage: string): CartEntry[] =>
    entries.map((entry: CartEntry) => ({
        ...entry,
        product: {
            ...entry.product,
            imagesSubset: parseImageSubset(entry.product.imagesSubset, fallbackImage),
            stockStatus: getCartEntryStockStatus(entry?.entryStockStatus || entry?.product?.stockStatus),
        },
    }))

export const parseRecommendedCartEntries = (entries: RecommendedCartEntry[], fallbackImage: string): RecommendedCartEntry[] =>
    entries.map((entry: RecommendedCartEntry) => ({
        ...entry,
        product: {
            ...entry.product,
            imagesSubset: parseImageSubset(entry.product.imagesSubset, fallbackImage),
            stockStatus: getProductStockStatus(entry?.product?.stockStatus),
        },
    }))

export const getCartEntryStockStatus = (sourceStockStatus: CartEntryStockStatus): CartEntryStockStatus => {
    if (sourceStockStatus) {
        const useProductStockStatusCode: boolean =
            sourceStockStatus.productStockStatusCode === 'NewProductProlongedDeliveryTime_761' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToThreeWeeks_781' ||
            sourceStockStatus.productStatusCode === 'ToBeDiscontinued_256' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToOneWeek_2' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToOneWeek_711' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToThreeWeeks_5' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToThreeWeeks_781' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToTwoWeeks_4' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToTwoWeeks_721' ||
            sourceStockStatus.productStockStatusCode.startsWith('GreenSymbolShowingIn')

        const availabilityEnum: ProductAvailability =
            typeof sourceStockStatus === 'object' ? sourceStockStatus.availabilityEnum : sourceStockStatus
        const stockStatusDescription: string = useProductStockStatusCode
            ? 'product.stockStatus.' + sourceStockStatus.productStockStatusCode
            : 'stockstatus.' + sourceStockStatus.availabilityEnum.toLowerCase()

        const productStockStatusCode: string = useProductStockStatusCode
            ? 'product.stockStatus.' + sourceStockStatus.productStockStatusCode
            : 'stockstatus.' + sourceStockStatus.availabilityEnum.toLowerCase()

        return {
            availabilityEnum,
            stockStatusDescription,
            productStockStatusCode,
            productStatusCode: sourceStockStatus.productStatusCode,
        }
    }
}
