import { ActionTypes } from './constants'
import { BreadcrumbsStore } from './interfaces'
import { Action } from '../interfaces'
import { getBreadcrumbKey } from './utils'

export const INITIAL_STATE: BreadcrumbsStore = {
    breadcrumbs: {},
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): BreadcrumbsStore => {
    switch (type) {
        case ActionTypes.FETCHED_BREADCRUMBS: {
            const { categoryCode, brand, productCode, pageId, breadcrumbData } = payload
            const breadcrumbKey: string = getBreadcrumbKey(categoryCode, brand, productCode, pageId)
            return {
                ...state,
                breadcrumbs: {
                    ...state.breadcrumbs,
                    [breadcrumbKey]: breadcrumbData,
                },
            }
        }
        default:
            return state
    }
}
