import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as ProductDetailsName } from '@spa-ec/components/ProductDetails/ProductDetails.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as SummaryTableName } from '@spa-ec/displayComponents/CartTable/SummaryTable/SummaryTable.theme';
import { NAME as ProductTableName } from '@spa-ec/components/ProductTable/ProductTable.theme';
import { NAME as ProductRowName } from '@spa-ec/components/ProductTable/Rows/ProductRow.theme';
import { NAME as PrisjaktCheapestName } from '@spa-ec/components/PrisjaktCheapest/PrisjaktCheapest.theme';
import { NAME as SiteLogoName } from '@spa-ec/components/Header/HeaderSiteLogo/SiteLogo.theme';
import { ProductShelfCategoryKey } from '@spa-core/store/products/constants';
import { NAME as ShipmentAddressFormName, } from '@spa-ec/components/Checkout/AddressSection/ShipmentAddressForm.theme';
import theme from './theme.conf';
const contentPageTheme = {
    linkColor: '#000',
    linkColorHover: '#000',
    showSideNavigationOnStaticPages: true,
    sideContentPadding: 'px-4',
};
const productDetailsTheme = {
    showCartIcon: true,
    showEnvironmentalCertificates: true,
};
const pageTitleTheme = {
    noBorder: true,
    fg: theme.col.tertiary.fg,
    bg: 'white',
    showLogoutIcon: false,
    combineSections: true,
    bottomBorderColor: '#343434',
};
const cartTableTheme = {
    showProductDetails: true,
    showPerPiecePriceTitle: true,
    showPerKronaInCartTable: false,
};
const summaryTableTheme = {
    showPerPiecePriceTitle: true,
};
const productTableTheme = {
    categoriesOrder: [
        ProductShelfCategoryKey.ONE_BUY_PRODUCTS,
        ProductShelfCategoryKey.RECOMMENDED,
        ProductShelfCategoryKey.ORIGINAL,
        ProductShelfCategoryKey.FREE_CATEGORIES_LIST,
    ],
    checkCategoriesForProducts: [
        ProductShelfCategoryKey.RECOMMENDED,
        ProductShelfCategoryKey.OPTIMUM,
        ProductShelfCategoryKey.BUDGET,
        ProductShelfCategoryKey.ORIGINAL,
    ],
    hideShelfTitleOnMobile: false,
    showPrinterModelRecommendation: true,
    showVaccumModelRecommendation: false,
};
const productRowTheme = {
    showOneKronaSticker: true,
    productCodeColor: theme.col.pale.alt,
};
const shipmentAddressFormTheme = {
    showShippingAddressCellphone: true,
};
const prisjaktcheapestTheme = {
    storeIds: { '112ink-se': 3025, '112ink-no': 12754 },
};
const siteLogoTheme = {
    siteLogo: {
        imagesRootPath: '/images/sitelogo',
        paths: {
            b2c: {
                se: {
                    sv: '/112ink_ekoprint.svg',
                },
                dk: {
                    da: '/112ink_ekoprint.svg',
                },
                fi: {
                    fi: '/112ink_ekoprint.svg',
                },
                no: {
                    no: '/112ink_ekoprint.svg',
                },
                default: '/112ink_ekoprint.svg',
            },
            b2b: {
                default: '/112ink_ekoprint.svg',
            },
        },
    },
};
export default {
    [ContentPageName]: contentPageTheme,
    [ProductDetailsName]: productDetailsTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [SummaryTableName]: summaryTableTheme,
    [ProductTableName]: productTableTheme,
    [ProductRowName]: productRowTheme,
    [PrisjaktCheapestName]: prisjaktcheapestTheme,
    [SiteLogoName]: siteLogoTheme,
    [ShipmentAddressFormName]: shipmentAddressFormTheme,
};
