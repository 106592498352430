import React, { useState } from 'react'
import TextInput from '@ui-elem/TextInput/TextInput'
import Button, { ButtonColors } from '@ui-elem/Button/Button'
import { t } from '@spa-core/locale'
import { Icons } from '@ui-elem/Icon/Icon'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { resetForm, subscribeToNewsletter } from '@spa-core/store/app/actions'
import { NewsletterSubscriptionStatus, NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import classNames from 'classnames'

enum Styles {
    NEWSLETTER_BORDER_CONFIG = 'newsletter_border_config',
    NEWLETTER_BUTTON_CONFIG = 'newletter_button_config',
}

const newsletterValidationSchema: Yup.ObjectSchema<NewsletterForm> = Yup.object().shape({
    email: Yup.string().email(t('checkout.form.email.invalid')).required(),
})

interface NewsletterForm {
    email: string
}

type ComponentState = {
    subscriptionError: boolean
    subscriptionSuccess: boolean
}

const NewsLetter: React.FC = () => {
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false)

    const subscriptionStatusStore = ({ reducers }: Store) => reducers[appReducerName].subscriptionStatus
    const selector = createSelector(
        [subscriptionStatusStore],
        (subscriptionStatus): ComponentState => ({
            subscriptionError: subscriptionStatus === NewsletterSubscriptionStatus.SUBSCRIPTION_FAILED,
            subscriptionSuccess: subscriptionStatus === NewsletterSubscriptionStatus.SUBSCRIPTION_SUCCESS,
        }),
    )
    const { subscriptionError, subscriptionSuccess }: ComponentState = useSelector(selector)

    const dispatch = useDispatch()

    const newsletterForm = useFormik<NewsletterForm>({
        initialValues: {
            email: '',
        },
        onSubmit: (values: NewsletterForm) => {
            dispatch(subscribeToNewsletter(values.email))
            setFormSubmitted(true)
        },
        validationSchema: newsletterValidationSchema,
    })

    const submitForm = () => {
        setFormSubmitted(true)
        if (newsletterForm.isValid) {
            newsletterForm.handleSubmit()
        }
    }

    const onChange = (e: any) => {
        if (formSubmitted) {
            dispatch(resetForm())
            newsletterForm.resetForm()
            setFormSubmitted(false)
        }
        newsletterForm.handleChange(e)
    }

    return (
        <div
            className={classNames(
                Styles.NEWSLETTER_BORDER_CONFIG,
                'md:w-4/5 items-center md:m-auto m-4 p-4 flex flex-col md:flex-row min-h-fit justify-center e2e-ver-newsletter',
            )}
        >
            <div className={'w-full md:w-2/5'}>
                <div className={'font-bold'}>{t(['newsletter.title', 'phrase.exclamation.recieve_our_news_letter'])}</div>
                <div>{t('newsletter.subtitle')}</div>
            </div>
            <div className={'w-full md:w-3/5'}>
                <div className="flex flex-col md:flex-row gap-2">
                    <div className="w-full md:w-4/6">
                        <TextInput
                            name="email"
                            placeholder={`${t('phrase.enter_your_email_here')}...*`}
                            id="footer-value"
                            errorMessage={subscriptionError ? t('newsletter.subscription.fail') : undefined}
                            successMessage={subscriptionSuccess ? t('newsletter.subscription.success') : undefined}
                            formSubmitted={formSubmitted}
                            value={newsletterForm.values.email}
                            onChange={onChange}
                            icon={Icons.Envelope}
                        />
                    </div>
                    <div className="w-full md:w-2/6 e2e-ver-newsletter-button flex items-center">
                        <Button
                            onClick={submitForm}
                            buttonColor={ButtonColors.PRIMARY}
                            fluid={true}
                            buttonPadding="px-4 py-3"
                            buttonText={t('word.send')}
                            disabled={newsletterForm.isSubmitting}
                            className={Styles.NEWLETTER_BUTTON_CONFIG}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsLetter
