import { Logger } from '@spa-core/logger'
import { isPuppeteer } from '@spa-ec/components/Loader/utils'
import deriveArea from '../../util/deriveArea'
import browserSvc from '@spa-core-js/services/browserSvc'
import { GoogleAnalyticsCategory, MatomoLevel, TrackingActionTypes, TrackingQueues } from '../constants'
import { NAME as appReducerName } from '../../store/app/constants'
import { CookiebotConsent, CookiebotUtil } from '../utils/CookiebotUtil'
import { addToTrackingQueue, getTrackingQueue } from '../utils'
import { SessionConfig } from '@spa-core/store/app/interfaces'

const MATOMO_WINDOW_KEY: string = '_paq'

const pushTracking = (trackingObject: any) => {
    if (cookiebotUtil.cookiebotIsLoaded && cookiebotUtil.hasStatisticsConsent) {
        Logger.info('pushTracking - window[_paq]', window?.[MATOMO_WINDOW_KEY])
        window?.[MATOMO_WINDOW_KEY]?.push(trackingObject)
    } else {
        addToTrackingQueue(TrackingQueues.MATOMO)(trackingObject)
    }
}

const onLoadedCallback = () => {
    Logger.info('Matomo sendTrackingQueue has consent', cookiebotUtil.hasStatisticsConsent)
    if (cookiebotUtil.hasStatisticsConsent) {
        const trackingQueue: any[] = getTrackingQueue(TrackingQueues.MATOMO)
        Logger.info('sendTrackingQueue', trackingQueue)
        trackingQueue.forEach((trackingObject) => window[MATOMO_WINDOW_KEY]?.push(trackingObject))
        sessionStorage.setItem(TrackingQueues.MATOMO, undefined)
    }
}

const onConsentChangeCallback = (consent: CookiebotConsent): void => {
    if (consent.statistics === true) {
        window[MATOMO_WINDOW_KEY].push(['setCookieConsentGiven'])
        window[MATOMO_WINDOW_KEY].push(['setConsentGiven'])
    } else {
        window[MATOMO_WINDOW_KEY].push(['forgetCookieConsentGiven'])
        window[MATOMO_WINDOW_KEY].push(['forgetConsentGiven'])
    }
}
const cookiebotUtil: CookiebotUtil = new CookiebotUtil({
    onLoadedCallback,
    onConsentChangeCallback,
})

let spaSessionSent: boolean = false
let firstPageView: boolean = true

const getMeta = (metaName: string): string => {
    const metas = document.getElementsByTagName('meta')

    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i].getAttribute('content')
        }
    }
    return ''
}

let hasessionid

const fixValue = (value: any): any => {
    if (typeof value === 'string') {
        return parseFloat(value.replace(/,/g, '.'))
    }
    return value
}

const analytics = (store) => (next) => (action) => {
    if (!store) {
        return
    }
    let MATOMO_LEVEL
    if (typeof window !== 'undefined') {
        MATOMO_LEVEL = window['MATOMO_LEVEL'] || MatomoLevel.ALL
    }

    const sessionConfig: SessionConfig = store?.getState().reducers?.[appReducerName]?.sessionConfig || window['sessionConf']

    if (!sessionConfig.isSPA || !window[MATOMO_WINDOW_KEY] || MATOMO_LEVEL === 0) {
        next(action)
        return
    }

    let pCount
    let cCount
    let mCount

    if (!spaSessionSent && !isPuppeteer && typeof window !== 'undefined' && window[MATOMO_WINDOW_KEY]) {
        spaSessionSent = true
        hasessionid = getMeta('HASESSIONID')
        window[MATOMO_WINDOW_KEY].push(['setCustomVariable', 1, 'HASESSIONID', hasessionid, 'visit'])
        window[MATOMO_WINDOW_KEY].push(['trackEvent', 'SESSION', 'SPA_SESSION', 'VAL'])
        window[MATOMO_WINDOW_KEY].push(['setUserId', browserSvc.cookieGet('ID2')])
    }

    const { type, payload } = action
    if (payload?.matomoLevel <= MATOMO_LEVEL) {
        switch (type) {
            case TrackingActionTypes.PAGE_VIEW:
                if (!firstPageView) {
                    pushTracking(['setCustomUrl', decodeURIComponent(location.href)])
                    pushTracking(['trackPageView'])
                }
                firstPageView = false
                break
            case TrackingActionTypes.FETCHED_CART:
                pushTracking(['clearEcommerceCart'])
                payload?.cartData?.entries?.forEach((entry) => {
                    const cat: string[] = []
                    if (entry?.product?.categories?.length > 0) {
                        for (let j = 0; j < entry.product.categories.length; j++) {
                            cat.push(entry.product.categories[j].code)
                        }
                    }
                    pushTracking([
                        'addEcommerceItem',
                        entry.product.code, // (Required) productSKU
                        entry.product.name, // (Optional) productName
                        cat, // (Optional) productCategory
                        entry.buyPrice.value, // (Recommended) price
                        entry.quantity, // (Optional, defaults to 1) quantity
                    ])
                })

                // Pass the Cart's Total Value as a numeric parameter
                pushTracking(['trackEcommerceCartUpdate', payload?.cartData?.subTotal?.value])
                break
            case TrackingActionTypes.VALIDATED_CHECKOUT_INPUT_FIELD:
                if (payload.valid === false) {
                    pushTracking(['trackEvent', deriveArea(), 'ADDRESS_INPUT_ERROR', payload.key])
                }
                break
            case TrackingActionTypes.SEND_PLACE_ORDER:
                pushTracking(['trackEvent', deriveArea(), 'PREFERRED_PAYMENT_OPTION', payload.paymentOption])
                pushTracking(['trackEvent', deriveArea(), 'PREFERRED_SHIPMENT_METHOD', payload.shipmentMethod])
                break
            case TrackingActionTypes.FETCHED_ORDER_CONFIRMATION_DATA:
                try {
                    if (!payload?.data?.affiliateReportData) return
                    // No point of delaying these as they are sent at once anyway
                    const entriesLength: number = payload?.data?.entries?.length || 0
                    for (let i: number = 0; i < entriesLength; i++) {
                        const entry: any = payload.data.entries[i]
                        const categories: string[] = []
                        for (let j = 0; j < entry.product.categories.length; j++) {
                            categories.push(entry.product.categories[j].code)
                        }
                        pushTracking([
                            'addEcommerceItem',
                            entry.product.code, // (Required) productSKU
                            entry.product.name, // (Optional) productName
                            categories,
                            fixValue(entry.buyPriceExVatSEK), // old: buyPrice.value, // (Recommended) price
                            entry.quantity, // (Optional, defaults to 1) quantity
                        ])
                    }

                    // Order Array - Parameters should be generated dynamically
                    pushTracking([
                        'trackEcommerceOrder',
                        payload.data.code, // (Required) orderId
                        fixValue(payload.data.subTotalExVatSEK), // Subtotal excluding VAT and excluding shipping, always in SEK, see IC-14765.
                        fixValue(payload.data.totalTaxSEK), // Tax in SEK, IC-14765.
                        fixValue(payload.data.deliveryCostExVatSEK), // Shipping, excluding tax and in SEK, IC-14765.
                    ])
                } catch (err) {
                    Logger.error('Analytics reporting error:', err)
                }
                break
            case TrackingActionTypes.SESSION_CONFIG_UPDATED:
                pushTracking(['trackEvent', payload.gaCat || GoogleAnalyticsCategory.OTHER, payload.gaType, payload.gaLabel])
                break
            case TrackingActionTypes.SEARCH_RESULTS:
                pCount = payload?.searchResult?.productSearchResult?.pagination?.totalNumberOfResults || 0
                cCount = payload?.searchResult?.categories?.length || 0

                mCount = 0
                if (payload?.searchResult?.modelSearchResult?.[0]) {
                    payload?.searchResult.modelSearchResult.forEach((modelSearchResult) => {
                        mCount += modelSearchResult.totalNumberOfResults
                    })
                }
                pushTracking(['trackEvent', 'SEARCH', type, payload.gaLabel, pCount + cCount + mCount])
                break
            case TrackingActionTypes.AUTOCOMPLETE_SEARCH_RESULTS:
                pCount = payload?.result?.products?.length || 0
                cCount = payload?.result?.categories?.length || 0
                pushTracking(['trackSiteSearch', payload.term, 'SEARCH', pCount + cCount])
                break
            case TrackingActionTypes.HEADER_SEARCH_RESULT_BOX_CLOSED:
                pushTracking(['trackSiteSearch', '__no_action__' + payload.gaLabel, 'SEARCH', 0])
                pushTracking(['trackEvent', 'SEARCH', type, payload.gaLabel, payload.gaVal])
                break
            case TrackingActionTypes.SET_CATEGORY:
                pushTracking(['setEcommerceView', false, false, payload.categoryCode])
                break
            case TrackingActionTypes.ACTIVATE_FILTER:
                pushTracking(['trackEvent', 'FILTER', payload.id, window.location.href.split('#')[0]])
                break
            default:
                if (`${type}`?.startsWith(`${TrackingActionTypes.HEADER_SEARCH_RESULT_BOX_CLOSED}:`) && payload) {
                    pushTracking(['trackEvent', deriveArea(payload.gaCat), type, payload.gaLabel, payload.gaVal])
                }
        }
    }
    next(action)
}

export default analytics
