import { Action } from '../interfaces'
import { ActionTypes, NavigationType } from './constants'
import { NavigationStore } from './interfaces'

export const INITIAL_STATE: NavigationStore = {
    doNavigate: false,
    navigationType: NavigationType.NAVIGATE,
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): NavigationStore => {
    switch (type) {
        case ActionTypes.NAVIGATE_TO:
            return {
                ...state,
                doNavigate: true,
                navigationType: NavigationType.NAVIGATE,
                url: payload.url,
            }
        case ActionTypes.REDIRECT_TO:
            return {
                ...state,
                doNavigate: true,
                navigationType: NavigationType.REDIRECT,
                url: payload.url,
            }
        case ActionTypes.RESET:
            return {
                navigationType: NavigationType.NAVIGATE,
                doNavigate: false,
                url: undefined,
            }
        default:
            return state
    }
}
