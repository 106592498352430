const palette = {
    ekoOrange: '#ed6219',
    ekoOrangePeer: '#f2915e',
    ekoMoss: '#027a13',
    ekoMossPeer: '#4da259',
    ekoMint: '#5dbe7f',
    ekoMintPeer: '#8dd1a5',
    sepOcean: '#9bc3c2',
    sepPink: '#e8008c',
    _112Blue: '#008adb',
    _112BlueShade: '#0179c0',
    _112Red: '#ee342a',
    _112RedShade: '#da2c22',
    _112Orange: '#f06500',
    _112OrangeLight: '#f48341',
    _112OrangeLighter: '#f8ac6c',
    _112OrangeTint: '#fee8d6',
    _112OrangeGradient: 'linear-gradient(to bottom, #fee8d6, #f8ac6c)',
    orange: '#e93e30',
    orangeHighlight: '#f05b49',
    blackGradient1: '#111',
    blackGradient2: '#222',
    blackGradient3: '#333',
    blackGradient6: '#666',
    blackGradient9: '#999',
    blackGradientb: '#b2b2b2',
    gray: '#cccecf',
    grayLight: '#e7e7e7',
    offWhite: '#f4f4f4',
    inkySkyBlueShadeLight: '#deedf9',
};
const theme = {
    siteId: '112ink',
    palette,
    radius: '0',
    col: {
        primary: {
            alt: 'white',
            fg: palette.ekoOrange,
            peer: palette.ekoOrangePeer,
        },
        secondary: {
            alt: 'white',
            fg: palette.ekoMoss,
            peer: palette.ekoMossPeer,
        },
        tertiary: {
            alt: 'white',
            fg: palette.ekoMint,
            peer: palette.ekoMintPeer,
        },
        panel: {
            alt: '#343434',
            fg: '#ebebeb',
            peer: '#f5f5f5',
        },
        info: {
            alt: 'white',
            fg: '#6376ba',
            peer: '#5367aa',
        },
        error: {
            alt: '#f15c4a',
            fg: '#ffe5e6',
            peer: 'white',
        },
        pale: {
            alt: '#343434',
            fg: 'white',
            peer: palette.grayLight,
        },
        vars: {
            priceDiscountActive: palette._112RedShade,
            bodyBg: palette._112OrangeGradient,
        },
    },
    skipColorClasses: true,
    font: {
        size: {
            x4: '32px',
            x3: '24px',
            x2: '20px',
            x1: '18px',
            m: '15.6px',
            s1: '14px',
            s2: '12px',
            s3: '10px',
        },
        family: 'Lato',
        header: {
            family: 'Lato',
        },
        special: {
            family: 'Lato',
        },
    },
};
export default theme;
