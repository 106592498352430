import { ContentSlotItemProperties } from '@spa-core/store/content-slots/interfaces'
import { ContentSlotComponentProps } from '../../../interfaces'
import { Props } from '../Banner'

export const decamelize = (str: string, separator: string = undefined): string => {
    separator = typeof separator === 'undefined' ? '_' : separator
    // Safe in this context with data from the cms
    return str
        .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2') // NOSONAR
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2') // NOSONAR
        .toLowerCase()
}

export const generateBannerProps = (
    properties: ContentSlotItemProperties,
    contentSlotComponentProps: ContentSlotComponentProps,
): Props => ({
    ...contentSlotComponentProps,
    url: properties?.url,
    buttonAboveContent: properties?.buttonAboveContent,
    content: properties?.content,
    width: properties?.width,
    responsiveWidthOnMobile: properties?.responsiveWidthOnMobile,
    imgSrc: properties?.imgSrc,
    target: properties?.target,
    textShadowsInTitle: properties?.textShadowsInTitle,
    buttonColorType: properties?.buttonColorType,
    textBlockPositionTop: properties?.textBlockPositionTop,
    contentAboveHeading: properties?.contentAboveHeading,
    textAlignment: properties?.textAlignment,
    buttonColor: properties?.buttonColor,
    splashType: properties?.splashType,
    splashText: properties?.splashText,
    splashPosition: properties?.splashPosition,
    splashClass: properties?.splashClass,
    splashColor: properties?.splashColor,
    imgSmSrc: properties?.imgSmSrc,
    imgSmSrcOnHover: properties?.imgSmSrcOnHover,
    imgSmAltText: properties?.imgSmAltText,
    imgSmAltTextOnHover: properties?.imgSmAltTextOnHover,
    imgSrcOnHover: properties?.imgSrcOnHover,
    color: properties?.color,
    buttonText: properties?.buttonText,
    buttonAlignment: properties?.buttonAlignment,
    contentSize: properties?.contentSize,
    contentSmSize: properties?.contentSmSize,
    title: properties?.title,
    headlineSize: properties?.headlineSize,
    headlineSmSize: properties?.headlineSmSize,
    legalText: properties?.legalText,
    legalTextAlignment: properties?.legalTextAlignment,
})
