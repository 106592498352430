export const NAME = 'products'

export const ActionTypes = {
    FETCH_PRODUCT_BY_CODE: `${NAME}/FETCH_PRODUCT_BY_CODE`,
    FETCHED_PRODUCTS: `${NAME}/FETCHED_PRODUCTS`,
    FETCHED_SLIM_PRODUCTS: `${NAME}/FETCHED_SLIM_PRODUCTS`,
    FETCH_PRODUCT_RELATIONS: `${NAME}/FETCH_PRODUCT_RELATIONS`,
    FETCH_CROSS_SELL_PRODUCT_CODES: `${NAME}/FETCH_CROSS_SELL_PRODUCT_CODES`,
    FETCHED_CROSS_SELL_PRODUCT_CODES: `${NAME}/FETCHED_CROSS_SELL_PRODUCT_CODES`,
    FETCH_REPLACEMENT_PRODUCT: `${NAME}/FETCH_REPLACEMENT_PRODUCT`,
    FETCHED_REPLACEMENT_PRODUCT: `${NAME}/FETCHED_REPLACEMENT_PRODUCT`,
    FETCHED_PRODUCT_RELATIONS: `${NAME}/FETCHED_PRODUCT_RELATIONS`,
    UPDATE_PRODUCT_LOADING_STATUS: `${NAME}/UPDATE_PRODUCT_LOADING_STATUS`,
    FETCH_PRODUCTS_IN_BATCH: `${NAME}/FETCH_PRODUCTS_IN_BATCH`,
    FETCH_SUBSCRIPTION_OPTIONS: `${NAME}/FETCH_SUBSCRIPTION_OPTIONS`,
    FETCHED_SUBSCRIPTION_OPTIONS: `${NAME}/FETCHED_SUBSCRIPTION_OPTIONS`,
    SET_SELECTED_SUBSCRIPTION_OPTION_ID: `${NAME}/SET_SELECTED_SUBSCRIPTION_OPTION_ID`,
    SET_STOCK_MONITORED: `${NAME}/SET_STOCK_MONITORED`,
    REGISTER_FOR_STOCK_NOTIFICATION: `${NAME}/REGISTER_FOR_STOCK_NOTIFICATION`,
    UNREGISTER_FOR_STOCK_NOTIFICATION: `${NAME}/UNREGISTER_FOR_STOCK_NOTIFICATION`,
    SET_STOCK_MONITOR_SUBSCRIBE_EMAIL: `${NAME}/SET_STOCK_MONITOR_SUBSCRIBE_EMAIL`,
    SET_STOCK_MONITOR_SUBSCRIBE_ERROR: `${NAME}/SET_STOCK_MONITOR_SUBSCRIBE_ERROR`,
    FETCH_PROMOTIONS: `${NAME}/FETCH_PROMOTIONS`,
    FETCHED_PROMOTIONS: `${NAME}/FETCHED_PROMOTIONS`,
    SET_FETCHING_PRODUCTS: `${NAME}/SET_FETCHING_PRODUCTS`,
    FETCH_PRODUCT_REVIEW_BY_CODE: `${NAME}/FETCH_PRODUCT_REVIEW_BY_CODE`,
    FETCHED_PRODUCT_REVIEW_BY_CODE: `${NAME}/FETCHED_PRODUCT_REVIEW_BY_CODE`,
    OPEN_COMPARE_POPUP: `${NAME}/OPEN_COMPARE_POPUP`,
    SET_COMPARE_POPUP_FLAG: `${NAME}/SET_COMPARE_POPUP_FLAG`,
    CLOSE_COMPARE_POPUP: `${NAME}/CLOSE_COMPARE_POPUP`,
    FETCH_REPURCHASE_PRODUCT_DATA: `${NAME}/FETCH_REPURCHASE_PRODUCT_DATA`,
    FETCHED_REPURCHASE_PRODUCT_DATA: `${NAME}/FETCHED_REPURCHASE_PRODUCT_DATA`,
    FETCH_PRODUCT_SHELF: `${NAME}/FETCH_PRODUCT_SHELF`,
    SET_PRODUCT_SHELF: `${NAME}/SET_PRODUCT_SHELF`,
    SET_HAS_LOADED_PRODUCT_SHELF: `${NAME}/SET_HAS_LOADED_PRODUCT_SHELF`,
}

export const STR_HAS_HIGHLIGHT_PATTERN_REGEX = new RegExp('<em[^>]*>(:?.*)</em>', 'g')

export enum ProductAvailability {
    BUYABLE = 'BUYABLE',
    NOT_BUYABLE = 'NOT_BUYABLE',
    PROLONGED_DELIVERY = 'PROLONGED_DELIVERY',
}

export enum ProductLoadingStatus {
    PRE_FETCH = 'PRE_FETCH',
    FETCHING = 'FETCHING',
    FETCHED = 'FETCHED',
    NOT_FOUND = 'NOT_FOUND',
}

export enum ProductOneBuyStatus {
    NONE = 'NONE',
    SHOW = 'SHOW',
    DISABLED = 'DISABLED',
}

export enum ProductShelfCategoryKey {
    RECOMMENDED = 'recommended',
    BUDGET = 'budget',
    ORIGINAL = 'original',
    OPTIMUM = 'optimum',
    UPSELLING = 'upselling',
    ONE_BUY_PRODUCTS = 'oneBuyProducts',
    FREE_CATEGORIES_LIST = 'freeCategoriesList',
}
