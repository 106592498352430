import cloneDeep from 'fast-clone';

const _findNode4Cat = (catId, node, pathArr) => {
  if (!catId) return;
  let key = '/c/' + catId;
  let pos = node.url.indexOf(key);
  if (pos < 0) {
    key = '/t/' + catId;
    pos = node.url.indexOf(key);
  }

  // Check that the url contains and ends with the key
  if (pos > 0 && pos + key.length - node.url.length === 0) {
    // Match the node was found
    return { node, pathArr };
  }

  if (node.items) {
    for (let i = 0; i < node.items.length; i++) {
      const _node = node.items[i];
      const newArr = cloneDeep(pathArr);
      newArr.push(i);
      const res = _findNode4Cat(catId, _node, newArr);
      if (res) {
        return res;
      }
    }
  }
};

// Extra function that makes the headerNodes have the same stucture as each node
// The empty arrays i the array where each index will be added, this path is then
export const findNode4Cat = (catId, nodeArr) => _findNode4Cat(catId, { url: '', items: nodeArr }, []);

const _findNodeByUid = (uid, node, pathArr) => {
  if (!uid) return;
  // Check that the url contains and ends with the key
  if (node.uid === uid) {
    // Match the node was found
    return { node, pathArr };
  }

  if (node.items) {
    for (let i = 0; i < node.items.length; i++) {
      const _node = node.items[i];
      const newArr = cloneDeep(pathArr);
      newArr.push(i);
      const res = _findNodeByUid(uid, _node, newArr);
      if (res) {
        return res;
      }
    }
  }
};

// Extra function that makes the headerNodes have the same stucture as each node
// The empty arrays i the array where each index will be added, this path is then
export const findNodeByUid = (uid, nodeArr) => _findNodeByUid(uid, { items: nodeArr }, []);

const _getNode = (pathInTreeArr, node, posInPathArr) => {
  // If there are no node object there is nothing to do
  if (!node) return;

  if (posInPathArr === pathInTreeArr.length) {
    // A the destination return retults
    return node;
  }
  // If there no children and this was not right return bust
  if (!node.items) return;

  if (posInPathArr >= pathInTreeArr.length) return;
  return _getNode(pathInTreeArr, node.items[pathInTreeArr[posInPathArr]], posInPathArr + 1);
};

export const getNode = (pathInTreeArr, nodeArr) => {
  return _getNode(pathInTreeArr, nodeArr[pathInTreeArr[0]], 1);
};
