import { ActionTypes } from './constants'
import {
    FetchContentSlotByTypeAndUidPayload,
    FetchContentSlotWithSectionIdAndPageIdPayload,
    FetchProductContentSlotPayload,
} from './interfaces'

export const setFetchingContentSlot = (pageIdAndPosition: string, fetching: boolean) => ({
    type: ActionTypes.SET_FETCHING_CONTENT_SLOTS,
    payload: {
        pageIdAndPosition,
        fetching,
    },
})

export const setFetchingContentSlots = (pageId: string, fetching: boolean) => ({
    type: ActionTypes.SET_FETCHING_CONTENT_SLOTS,
    payload: {
        pageId,
        fetching,
    },
})

export const fetchContentSlots = (pageId: string) => ({
    type: ActionTypes.FETCH_CONTENT_SLOTS,
    payload: {
        pageId,
    },
})

export const fetchProductContentSlot = (payload: FetchProductContentSlotPayload) => ({
    type: ActionTypes.FETCH_PRODUCT_CONTENT_SLOT,
    payload,
})

export const fetchComponentContentSlot = (payload: FetchContentSlotByTypeAndUidPayload) => ({
    type: ActionTypes.FETCH_COMPONENT_CONTENT_SLOT,
    payload,
})

export const fetchSectionIdPageIdContentSlot = (payload: FetchContentSlotWithSectionIdAndPageIdPayload) => ({
    type: ActionTypes.FETCH_SECTION_ID_PAGE_ID_CONTENT_SLOT,
    payload,
})
