import { Logger } from '@spa-core/logger'
import { ExternalScriptContentType } from '@spa-core/store/app/constants'
import { ExternalScript, SessionConfig } from '@spa-core/store/app/interfaces'
import lodashTemplate from 'lodash/template'

interface ScriptTagAttribute {
    name: string
    value: string
}

const dangerouslyAddScript = (
    script: string,
    id: string,
    renderInHead: boolean = false,
    scriptTagAttributes: ScriptTagAttribute[] = [],
): void => {
    const scriptElement: any = document.createElement('script')
    scriptTagAttributes.forEach(({ name, value }: ScriptTagAttribute) => {
        scriptElement.setAttribute(name, value)
    })
    scriptElement.type = 'text/javascript'
    scriptElement.setAttribute('data-ic-id', id)
    if (script) {
        const inlineCode = document.createTextNode(script)
        scriptElement.appendChild(inlineCode)
    }
    if (renderInHead) {
        document.head.appendChild(scriptElement)
    } else {
        document.body.appendChild(scriptElement)
    }
}

const insertPixel = (urls: string | string[], id: string): void => {
    if (Array.isArray(urls)) {
        urls.forEach((url: string, index: number) => {
            appendToDocument(
                '<img class="tmp-pixel" src="' + url + '" width="1" height="1" style="border:0; visibility: hidden;"/>',
                id + '-' + index,
            )
        })
        return
    }
    appendToDocument('<img class="tmp-pixel" src="' + urls + '" width="1" height="1" style="border:0; visibility: hidden;"/>', id)
}

const deleteElementWithId = (id: string): void => {
    const elements: NodeListOf<Element> = document.querySelectorAll('[data-ic-id^=' + id + ']')
    elements.forEach((element: Element) => {
        element.remove()
    })
}

const appendToDocument = (scriptCode: string, id: string, renderInHead: boolean = false): void => {
    const htmlElement: HTMLHtmlElement = document.createElement('html')
    htmlElement.innerHTML = '<body>' + scriptCode + '</body>'
    const elements: HTMLCollection = htmlElement.children[1].children
    for (let i: number = 0; i < elements.length; i++) {
        const element: Element = elements[i]
        element.setAttribute('data-ic-id', id)
        const tagName: string = element.tagName
        if (tagName === ExternalScriptContentType.SCRIPT) {
            const elementInnerHTML: string = element.innerHTML
            const scriptTagAttributes: ScriptTagAttribute[] = []
            for (let j: number = 0; j < element?.attributes?.length || 0; j++) {
                scriptTagAttributes.push({
                    name: element.attributes[j].name,
                    value: element.attributes[j].value,
                })
            }
            dangerouslyAddScript(elementInnerHTML, id, renderInHead, scriptTagAttributes)
        } else if (renderInHead) {
            document.head.appendChild(element.cloneNode(true))
        } else {
            document.body.appendChild(element.cloneNode(true))
        }
    }
}

export const handleScript = (script: ExternalScript, scriptHydrationData: any, sessionConfig: SessionConfig): void => {
    // Early return if puppeteer
    if (navigator.userAgent.indexOf('HeadlessChrome') >= 0) return
    if (!script.content) return

    if (sessionConfig.isCrawler && sessionConfig.enableThirdPartyForCrawler === false) return

    const hydratedScript: string = lodashTemplate(script.content)(scriptHydrationData)

    try {
        if (script.contentType === ExternalScriptContentType.URLS_AFTER_ORDER) {
            deleteElementWithId(script.id || '_abt67A')
            insertPixel(hydratedScript, script.id || '_abt67A')
        }
    } catch (err) {
        Logger.error('Error adding script in url:', err)
    }

    try {
        if (script.contentType === ExternalScriptContentType.SCRIPT) {
            deleteElementWithId(script.id || '_abt67B')
            dangerouslyAddScript(hydratedScript, script.id || '_abt67B', script.renderInHead)
        }
    } catch (err) {
        Logger.error('Error performing get calls for urlsAfterOrder:', err)
    }

    // check for html to be injected
    try {
        if (script.contentType === ExternalScriptContentType.HTML) {
            deleteElementWithId(script.id || '_abt67C')
            appendToDocument(hydratedScript, script.id || '_abt67C', script.renderInHead)
        }
    } catch (err) {
        Logger.error('Error performing get calls for urlsAfterOrder:', err)
    }
}
