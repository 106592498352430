import PropTypes from 'prop-types';
import { Logger } from '@spa-core/logger';
import browserSvc from 'score/browserSvc';
import axios from 'axios';
import qs from 'qs';
import jsonp from 'jsonp';
import { getReducer } from '@spa-core/legacy-adapter/utils';
import { NAME as appReducerName } from '@spa-core/store/app/constants';

const raxConfig = {
  retryDelay: 100,
};

// Array that contains JSESSIONID that has been switched from in order to prevent reporting multiple times.
const switchList = [];

let resetSession = () => {};

export const init = (rSession) => {
  resetSession = rSession;
};
export class net {
  logUrl(id, val, id2, val2) {
    const ID2 = browserSvc.cookieGet('ID2');
    const JSESSIONID = browserSvc.cookieGet('JSESSIONID');
    const sessionConfig = getReducer(appReducerName).sessionConfig;
    // Do not change to another gif like log.gif, this makes this request vulnerable to the adblockers
    const url = `${
      sessionConfig.contextPath
    }/_ui/dist/images/lightbox-blank.gif?${id}=${val}&JSESSIONID=${JSESSIONID}&ID2=${ID2}&siteId=${sessionConfig.siteName}${
      id2 ? '&' + id2 + '=' + val2 : ''
    }`;
    axios.get(url);
  }
  /**
   * Requests Data from URL and returns the promise after doing so
   * @param url: URL from which data is to be requested
   * @param options: (Optional cache options)
   *                 - cache: ['none', 'session', 'persistent']
   *                 - ttl: seconds, only valid with cache persistent, -1 means forever
   * @returns Data
   */
  get(url, options) {
    return new Promise((resolve, reject) => {
      // Pause all network calls until page/init has been called
      const ORG_JSESSIONID = browserSvc.cookieGet('JSESSIONID');
      axios.get(url, { ...options, raxConfig }).then(
        (res) => {
          resolve(res.data);

          const JSESSIONID = browserSvc.cookieGet('JSESSIONID');
          // Check if the has been a server jump
          if (ORG_JSESSIONID && ORG_JSESSIONID !== JSESSIONID && !switchList.includes(ORG_JSESSIONID)) {
            this.logUrl('JSESSIONID_SWITCH_FROM', ORG_JSESSIONID);
            // prevent double reporting
            switchList.push(ORG_JSESSIONID);
          }
        },
        (error) => {
          Logger.warn('Failed getting ', url);
          Logger.warn('Error when receiving data ', error);
          const JSESSIONID = browserSvc.cookieGet('JSESSIONID');
          // Check if the has been a server jump
          if (ORG_JSESSIONID && ORG_JSESSIONID !== JSESSIONID && !switchList.includes(ORG_JSESSIONID)) {
            this.logUrl('JSESSIONID_SWITCH_FROM', ORG_JSESSIONID);
            // prevent double reporting
            switchList.push(ORG_JSESSIONID);
          }

          if (error.status === 200) {
            try {
              const output = JSON.parse(error.responseText);
              resolve(output);
            } catch (err) {
              resolve(err.responseText);
            }
          }
          if (error.status === 412) {
            try {
              resetSession().then(() => {
                  console.log('done - error'); // eslint-disable-line
                reject(error);
              });
            } catch (err) {
              resolve(err.responseText);
            }
          } else {
            reject(error);
          }
        }
      );
    });
  }
  directGet(url) {
    return axios.get(url);
  }

  getWithAuthToken(url, token) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            INKCLUB_AUTH_TOKEN: token,
          },
      }).then( // eslint-disable-line
          (res) => {
            resolve(res.data);
          },
          (error) => {
            Logger.warn('Failed getting ', url);
            Logger.warn('Error when receiving data ', error);
            reject(error);
          }
        );
    });
  }

  post(actionUrl, postData, isRetry = false, getCsrfToken = undefined) {
    const sessionConfig = getReducer(appReducerName).sessionConfig;
    let csrfToken = sessionConfig.CSRFToken;
    if (getCsrfToken) {
      csrfToken = getCsrfToken;
    }
    return new Promise((resolve, reject) => {
      // // Submit form via jQuery/AJAX
      let secureData;
      if (postData !== undefined) {
        secureData = postData + '&CSRFToken=' + csrfToken + '&CSRF=' + csrfToken;
      } else {
        secureData = 'CSRFToken=' + csrfToken + '&CSRF=' + csrfToken;
      }
      // Send a POST request
      axios({
        method: 'post',
        url: actionUrl,
        data: secureData,
        headers: {
          CSRFToken: csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      })
        .then((response) => {
          let res = response.data;
          if (typeof res !== 'object' && res.length === 0) {
            res = {};
          res.xhr = {status:response.status};//eslint-disable-line
          }
          if (typeof res === 'object') {
          res.xhr = {status:response.status};//eslint-disable-line
          }
          resolve(res);
        })
        .catch(async (error) => {
          if (error.response) {
            Logger.warn(error.response.data);
            Logger.warn(error.response.status);
            Logger.warn(error.response.headers);

            Logger.warn('Error when posting data ', error.response.status, error);
            if (error.response.status === 202) {
              resolve({});
            } else if (error.response.status === 412) {
              try {
                resetSession().then(() => {
                  if (!isRetry) {
                    this.post(actionUrl, postData, true, csrfToken).then((data) => {
                      resolve(data);
                    });
                  }
                });
              } catch (err) {
                resolve(err.responseText);
              }
            } else {
              reject(error.response);
            }
          }
        });
    });
  }

  postJSON(actionUrl, postData, getCsrfToken = undefined) {
    const sessionConfig = getReducer(appReducerName).sessionConfig;
    let csrfToken = sessionConfig.CSRFToken;
    if (getCsrfToken) {
      csrfToken = getCsrfToken;
    }
    return new Promise((resolve, reject) => {
      let url;
      // Append the csrftoken to the url
      // this should not be nessecary
      // Todo update the web service for questionaire
      if (actionUrl.indexOf('?') < 0) {
        url = actionUrl + '?CSRFToken=' + csrfToken;
      } else {
        url = actionUrl + '&CSRFToken=' + csrfToken;
      }

      // Send a POST request
      axios({
        method: 'post',
        url,
        data: postData,
        headers: { CSRFToken: csrfToken, 'content-type': 'application/json' },
      }).then(
        (response) => {
          let res = response.data;
          if (typeof res !== 'object' && res.length === 0) {
            res = {};
          }
          resolve(res);
        },
        (jqXhr) => {
          Logger.warn('Error when posting data ', jqXhr.status, jqXhr);
          if (jqXhr.status === 202) {
            resolve({});
          } else {
            reject(jqXhr);
          }
        }
      );
    });
  }
  postJSONP(actionUrl, postData) {
    return new Promise((resolve, reject) => {
      const querystring = qs.stringify(postData);

      let url;
      if (actionUrl.indexOf('?') < 0) {
        url = actionUrl + '?' + querystring;
      } else {
        url = actionUrl + '&' + querystring;
      }

      jsonp(url, null, (err, data) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(data);
        }
      });
    });
  }

  put(actionUrl, postData, getCsrfToken = undefined) {
    const sessionConfig = getReducer(appReducerName).sessionConfig;
    let csrfToken = sessionConfig.CSRFToken;
    if (getCsrfToken) {
      csrfToken = getCsrfToken;
    }
    return new Promise((resolve, reject) => {
      let url;
      // Append the csrftoken to the url
      // this should not be nessecary
      // Todo update the web service for questionaire

      if (actionUrl.indexOf('?') < 0) {
        url = actionUrl + '?' + postData + '&CSRFToken=' + csrfToken;
      } else {
        url = actionUrl + '&' + postData + '&CSRFToken=' + csrfToken;
      }

      // Send a POST request
      axios({
        method: 'put',
        url,
      }).then(
        (response) => {
          let res = response.data;
          if (typeof res !== 'object' && res.length === 0) {
            res = {};
          }
          resolve(res);
        },
        (jqXhr) => {
          Logger.warn('Error when posting data ', jqXhr.status, jqXhr);
          if (jqXhr.status === 202) {
            resolve({});
          } else {
            reject(jqXhr);
          }
        }
      );
    });
  }

  putJSON(actionUrl, putData, getCsrfToken = undefined) {
    const sessionConfig = getReducer(appReducerName).sessionConfig;
    let csrfToken = sessionConfig.CSRFToken;
    if (getCsrfToken) {
      csrfToken = getCsrfToken;
    }
    return new Promise((resolve, reject) => {
      let url;
      // Append the csrftoken to the url
      // this should not be nessecary
      // Todo update the web service for questionaire

      if (actionUrl.indexOf('?') < 0) {
        url = actionUrl + '?CSRFToken=' + csrfToken;
      } else {
        url = actionUrl + '&CSRFToken=' + csrfToken;
      }

      // Send a POST request
      axios({
        method: 'put',
        url,
        data: putData,
        headers: { CSRFToken: csrfToken, 'content-type': 'application/json' },
      }).then(
        (response) => {
          let res = response.data;
          if (typeof res !== 'object' && res.length === 0) {
            res = {};
          }
          resolve(res);
        },
        (jqXhr) => {
          Logger.warn('Error when posting data ', jqXhr.status, jqXhr);
          if (jqXhr.status === 202) {
            resolve({});
          } else {
            reject(jqXhr);
          }
        }
      );
    });
  }
  delete(actionUrl, postData) {
    return new Promise((resolve, reject) => {
      // Submit form via jQuery/AJAX
      let url;
      if (actionUrl.indexOf('?') < 0) {
        url = actionUrl + '?' + postData;
      } else {
        url = actionUrl + '&' + postData;
      }

      axios.delete(url, { postData }).then(
        (response) => {
          resolve(response.data);
        },
        (jqXhr) => {
          Logger.warn('Error when putting data ', jqXhr);
          reject(jqXhr);
        }
      );
    });
  }
}

net.contextTypes = {
  store: PropTypes.object,
};
const service = new net();

if (typeof window !== 'undefined') {
  window.tpGet = service.get;
}
export default service;
