import axios, { AxiosError, AxiosResponse } from 'axios'
import { Logger } from '../../logger'

const getMany = async (paths: string[]): Promise<AxiosResponse[] | void> => {
    const results: any = await Promise.all(
        paths.map((path: string) =>
            axios.get(path).catch((e: AxiosError) => Logger.info(`Failed to load file: ${e?.config?.url}`)),
        ),
    )
    return results
}

const getAndOpenPdf = (url: string, fileName?: string) => {
    axios
        .get(url, { responseType: 'blob' })
        .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.target = '_blank'
            if (fileName) {
                a.download = fileName
            }
            a.rel = 'noopener noreferrer'
            a.click()
        })
        .catch((error) => {
            console.error('Error opening PDF:', error)
        })
}

export { getMany, getAndOpenPdf }
