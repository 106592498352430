import net from '@spa-core-js/services/networkSvc'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { selectSessionConfig } from '../utils'
import { ActionTypes } from './constants'
import { t } from '@spa-core/locale'
import { SessionConfig } from '../app/interfaces'
import { Logger } from '@spa-core/logger'

export function* setCustomer({ payload }: any) {
    const { customerId } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/csadmin/setcustomer?customerId=${customerId}`
    try {
        yield put({
            type: ActionTypes.SET_SET_CUSTOMER_ERROR_MESSAGE,
            payload: {
                errorMessage: undefined,
            },
        })
        const result: any = yield call(() => net.get(url))
        if (result.success) {
            if (result.data && result.data.redirectURL) {
                window.location = result.data.redirectURL
            } else {
                location.reload()
            }
        } else {
            const message: string = result?.errors?.[0]?.message
            yield put({
                type: ActionTypes.SET_SET_CUSTOMER_ERROR_MESSAGE,
                payload: {
                    errorMessage: t(message),
                },
            })
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export const watchers = [takeLatest(ActionTypes.SET_CUSTOMER, setCustomer)]
