import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import * as cls from './Animation.styles';

export const AnimatedList = (p) => (
  <div className={classnames(cls['animated-list'], 'e2e-ver-animated-list')}>
    <TransitionGroup>
      {p.children.map((child) => (
        <CSSTransition unmountOnExit={true} timeout={2000} classNames="fade-msg" key={child.id}>
          {child}
        </CSSTransition>
      ))}
    </TransitionGroup>
  </div>
);
export default AnimatedList;
