import React, { useEffect, useMemo } from 'react'
import browserSvc from '@spa-core-js/services/browserSvc'
import { loadWithPrerender, Hydrate } from '../Loader/Loader'
import USPWrapper from './components/USPWrapper/USPWrapper'
import USPItem from './components/USPItem/USPItem'
import { ComponentType, NAME as contentSlotsReducerName, Position } from '@spa-core/store/content-slots/constants'
import { useDispatch, useSelector } from 'react-redux'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import {
    ContentSlotItems,
    ContentSlot,
    ContentSlotsStore,
    ContentSlotItem,
    ContentSlotItemProperties,
} from '@spa-core/store/content-slots/interfaces'
import { fetchProductContentSlot, fetchContentSlots } from '@spa-core/store/content-slots/actions'
import { ContentSlotComponentProps } from './interfaces'
import { Props as CarouselProps } from '@ui-elem/Carousel/Carousel'
import { Props as StylesBannerWrapperProps } from './components/StylesBannerWrapper/StylesBannerWrapper'
import { Props as USPWrapperProps } from './components/USPWrapper/USPWrapper'
import { Props as TitleTextAndDynamicImageWrapperProps } from './components/TitleTextAndDynamicImageWrapper/TitleTextAndDynamicImageWrapper'
import { Props as AccordionProps } from '@ui-elem/Accordion/Accordion'
import { Props as AccordionItemProps } from '@ui-elem/Accordion/AccordionItem'
import { Props as BannerWrapperProps } from './components/BannerWrapper/BannerWrapper'
import { Props as LogoListProps } from './components/LogoList/LogoList'
import { Props as ComponentsContainerProps } from './components/ComponentsContainer/ComponentsContainer'
import { Props as FindModelProps } from './components/FindModel/FindModel'
import { Props as MultiColumnTextAndImageProps } from './components/MultiColumnTextAndImage/MultiColumnTextAndImage'
import { Props as FlowImageAndTextProps } from './components/FlowImageAndText/FlowImageAndText'
import { Props as LinkedImageProps } from './components/LinkedImage/LinkedImage'
import { Props as BannerProps } from './components/Banner/Banner'
import { Props as VideoCMSProps } from './components/VideoCMS/VideoCMS'
import { Props as VideoProps } from './components/Video/Video'
import { Props as USPItemProps } from './components/USPItem/USPItem'
import { Props as RoundImgAndTextProps } from './components/RoundImgAndText/RoundImgAndText'
import { Props as FixedImageAndTextProps } from './components/FixedImageAndText/FixedImageAndText'
import { Props as TextAndButtonProps } from './components/TextAndButton/TextAndButton'
import { Props as TitleTextAndDynamicImageProps } from './components/TitleTextAndDynamicImage/TitleTextAndDynamicImage'
import { Props as RightNowIFrameProps } from './components/RightNowIFrame/RightNowIFrame'
import { Props as ProductUpsellCarouselProps } from './components/ProductUpsellCarousel/ProductUpsellCarousel'
import { Props as ProductRecommendationProps } from './components/ProductRecommendation/ProductRecommendation'
import { Props as LinkProps } from '@ui-elem/Link/Link'
import { Props as CategoryRecommendationProps } from './components/CategoryRecommendation/CategoryRecommendation'
import { Props as BrandsListProps } from './components/BrandsList/BrandsList'
import { Props as ProductPromotionProps } from './components/ProductPromotion/ProductPromotion'
import { Props as ParagraphProps } from './components/Paragraph/Paragraph'
import { Props as TreeViewProps } from './components/TreeView/TreeView'
import { generateBannerProps } from './components/Banner/utils'
import { Props as InkClubEBKListProps } from './components/InkClubEBKList/InkClubEBKList'
import { CMS_DEBUG_MODE } from '../DebugInfo/DebugInfo'

enum Style {
    CONTENT_SLOT_INFO_HEADER = 'content_slot_info_header',
}
const DEBUG_MODE: boolean = typeof location !== 'undefined' ? browserSvc.sessionGet(CMS_DEBUG_MODE) === 'true' : false

const MultiColumnTextAndImage = loadWithPrerender(
    () => import('./components/MultiColumnTextAndImage/MultiColumnTextAndImage'),
    'MultiColumnTextAndImage',
)
const Paragraph = loadWithPrerender(() => import('./components/Paragraph/Paragraph'), 'Paragraph')

const Link = loadWithPrerender(() => import('@ui-elem/Link'), 'Link')
const ProductPromotion = loadWithPrerender(() => import('./components/ProductPromotion/ProductPromotion'), 'ProductPromotion')
const Carousel = loadWithPrerender(() => import('@ui-elem/Carousel/Carousel'), 'Carousel')
const TitleTextAndDynamicImageWrapper = loadWithPrerender(
    () => import('./components/TitleTextAndDynamicImageWrapper/TitleTextAndDynamicImageWrapper'),
    'TitleTextAndDynamicImageWrapper',
)
const Accordion = loadWithPrerender(() => import('@ui-elem/Accordion/Accordion'), 'Accordion', {
    hydrate: Hydrate.IN_VIEW,
})

// const TreeView = loadWithPrerender(() => import('ui/TreeViewMod'), 'TreeView')
const StylesBannerWrapper = loadWithPrerender(
    () => import('./components/StylesBannerWrapper/StylesBannerWrapper'),
    'StylesBannerWrapper',
)
const BannerWrapper = loadWithPrerender(() => import('./components/BannerWrapper/BannerWrapper'), 'BannerWrapper', {
    timeout: 2000,
})
const ComponentsContainer = loadWithPrerender(
    () => import('./components/ComponentsContainer/ComponentsContainer'),
    'ComponentsContainer',
)
const FindModel = loadWithPrerender(() => import('./components/FindModel/FindModel'), 'FindModel')
const InkClubEBKList = loadWithPrerender(() => import('./components/InkClubEBKList/InkClubEBKList'), 'InkClubEBKList')
const LogoList = loadWithPrerender(() => import('./components/LogoList/LogoList'), 'LogoList')
const FlowImageAndText = loadWithPrerender(() => import('./components/FlowImageAndText/FlowImageAndText'), 'FlowImageAndText')
const CategoryRecommendation = loadWithPrerender(
    () => import('./components/CategoryRecommendation/CategoryRecommendation'),
    'CategoryRecommendation',
)
const ProductRecommendation = loadWithPrerender(
    () => import('./components/ProductRecommendation/ProductRecommendation'),
    'ProductRecommendation',
    {
        hydrate: Hydrate.HOVER,
    },
)
const Banner = loadWithPrerender(() => import('./components/Banner/Banner'), 'Banner')
const VideoCMS = loadWithPrerender(() => import('./components/VideoCMS/VideoCMS'), 'VideoCMS')
const Video = loadWithPrerender(() => import('./components/Video/Video'), 'Video')
const RoundImgAndText = loadWithPrerender(() => import('./components/RoundImgAndText/RoundImgAndText'), 'RoundImgAndText')
const FixedImageAndText = loadWithPrerender(() => import('./components/FixedImageAndText/FixedImageAndText'), 'FixedImageAndText')
const TextAndButton = loadWithPrerender(() => import('./components/TextAndButton/TextAndButton'), 'TextAndButton')
const TitleTextAndDynamicImage = loadWithPrerender(
    () => import('./components/TitleTextAndDynamicImage/TitleTextAndDynamicImage'),
    'TitleTextAndDynamicImage',
)
const LinkedImage = loadWithPrerender(() => import('./components/LinkedImage/LinkedImage'), 'LinkedImage')
const AccordionItem = loadWithPrerender(() => import('@ui-elem/Accordion/AccordionItem'), 'AccordionItem')
const RightNowIFrame = loadWithPrerender(() => import('./components/RightNowIFrame/RightNowIFrame'), 'RightNowIFrame')
const ProductUpsellCarousel = loadWithPrerender(
    () => import('./components/ProductUpsellCarousel/ProductUpsellCarousel'),
    'ProductUpsellCarousel',
    {
        hydrate: Hydrate.IN_VIEW,
    },
)
const CSAdminBanner = loadWithPrerender(() => import('./components/CSAdminBanner/CSAdminBanner'), 'CSAdminBanner')
const BrandsList = loadWithPrerender(() => import('./components/BrandsList/BrandsList'), 'BrandsList')
const TreeView = loadWithPrerender(() => import('./components/TreeView/TreeView'), 'TreeView')

const renderComponent = (
    contentSlotItems: ContentSlotItems | ContentSlotItem,
    position: Position,
    advancedEditFlag: boolean,
): React.ReactNode | React.ReactNode[] => {
    if (!contentSlotItems.componentType) return

    const smarteditCatalogVersion: string = browserSvc.localStorageGet('smarteditCatalogVersion')

    const recursiveRender = (
        contentSlotItems: ContentSlotItems | ContentSlotItem,
    ):
        | React.ReactNode
        | React.ReactNode[]
        | Array<React.ReactElement<AccordionItemProps>>
        | ContentSlotItem
        | ContentSlotItem[] => {
        const componentType: ComponentType = contentSlotItems.componentType
        const container: string = contentSlotItems.container
        const items: ContentSlotItem[] = contentSlotItems?.['items']
        const properties: ContentSlotItemProperties = contentSlotItems.properties
        const contentSlotComponentProps: ContentSlotComponentProps = {
            key: properties?.uid,
            ssrKey: properties?.uid,
            elementUid: properties?.elementUid,
            contentSlotCatalogVersion: smarteditCatalogVersion || null,
            componentParentType: properties?.parentType,
        }

        if (componentType) {
            if (container !== 'false') {
                switch (componentType) {
                    case ComponentType.FRAGMENT:
                        return items?.map((item: ContentSlotItem) => recursiveRender(item)) as ContentSlotItem[]
                    case ComponentType.STYLES_BANNER_WRAPPER: {
                        const stylesBannerWrapperProps: StylesBannerWrapperProps = {
                            ...contentSlotComponentProps,
                            children: items?.map((item: ContentSlotItem) => recursiveRender(item)) as React.ReactNode[],
                        }
                        return (
                            <StylesBannerWrapper
                                key={`StylesBannerWrapper${contentSlotComponentProps.elementUid}`}
                                {...stylesBannerWrapperProps}
                            />
                        )
                    }
                    case ComponentType.CAROUSEL: {
                        const carouselProps: CarouselProps = {
                            ssrKey: contentSlotComponentProps.ssrKey,
                            autoPlay: properties?.autoPlay === 'true',
                            autoPlaySpeed: contentSlotItems.properties?.autoPlaySpeed,
                            wrapperTheme: contentSlotItems.properties?.wrapperTheme,
                            children: items?.map((item: ContentSlotItem) => recursiveRender(item)) as React.ReactNode[],
                        }
                        return <Carousel key={`Carousel${contentSlotComponentProps.elementUid}`} {...carouselProps} />
                    }
                    case ComponentType.USP_WRAPPER: {
                        const uspWrapperProps: USPWrapperProps = {
                            ...contentSlotComponentProps,
                            children: items?.map((item: ContentSlotItem) => recursiveRender(item)) as React.ReactNode[],
                        }
                        return <USPWrapper key={`USPWrapper${contentSlotComponentProps.elementUid}`} {...uspWrapperProps} />
                    }
                    case ComponentType.TITLE_TEXT_AND_DYNAMIC_IMAGE_WRAPPER: {
                        const titleTextAndDynamicImageWrapperProps: TitleTextAndDynamicImageWrapperProps = {
                            ...contentSlotComponentProps,
                            width: properties?.width,
                            children: items?.map((item: ContentSlotItem) => recursiveRender(item)) as React.ReactNode[],
                        }
                        return (
                            <TitleTextAndDynamicImageWrapper
                                key={`TitleTextAndDynamicImageWrapper${contentSlotComponentProps.elementUid}`}
                                {...titleTextAndDynamicImageWrapperProps}
                            />
                        )
                    }
                    case ComponentType.ACCORDION: {
                        const accordionProps: AccordionProps = {
                            ...contentSlotComponentProps,
                            children: items?.map((item: ContentSlotItem) => recursiveRender(item)) as Array<
                                React.ReactElement<AccordionItemProps>
                            >,
                        }
                        return <Accordion key={`Accordion${contentSlotComponentProps.elementUid}`} {...accordionProps} />
                    }
                    case ComponentType.BANNER_WRAPPER: {
                        const bannerWrapperProps: BannerWrapperProps = {
                            ...contentSlotComponentProps,
                            children: items?.map((item: ContentSlotItem) => recursiveRender(item)) as React.ReactNode[],
                        }
                        return (
                            <BannerWrapper key={`BannerWrapper${contentSlotComponentProps.elementUid}`} {...bannerWrapperProps} />
                        )
                    }
                    case ComponentType.LOGO_LIST: {
                        const logoListProps: LogoListProps = {
                            ...contentSlotComponentProps,
                            contentSlotItems: items,
                            title: properties?.title,
                        }
                        return <LogoList key={`LogoList${contentSlotComponentProps.elementUid}`} {...logoListProps} />
                    }
                    case ComponentType.COMPONENTS_CONTAINER: {
                        const componentsContainerProps: ComponentsContainerProps = {
                            ...contentSlotComponentProps,
                            advancedEditFlag,
                            items,
                        }
                        return (
                            <ComponentsContainer
                                key={`ComponentsContainer${contentSlotComponentProps.elementUid}`}
                                {...componentsContainerProps}
                            />
                        )
                    }
                    case ComponentType.TREE_VIEW: {
                        const treeViewPropsProps: TreeViewProps = {
                            ssrKey: contentSlotComponentProps.ssrKey,
                            items: items?.map(({ properties }) => ({
                                uid: properties?.uid,
                                to: properties?.to,
                                linkTitle: properties?.linkTitle,
                            })),
                            content: properties?.content,
                        }
                        return <TreeView key={`TreeView${contentSlotComponentProps.ssrKey}`} {...treeViewPropsProps} />
                    }
                    default:
                        return <div>Component with children not implemented: {componentType}</div>
                }
            }
            switch (componentType) {
                case ComponentType.MULTI_COLUMN_TEXT_AND_IMAGE: {
                    const multiColumnTextAndImageProps: MultiColumnTextAndImageProps = {
                        ...contentSlotComponentProps,
                        color: properties.color,
                        mode: properties.mode,
                        imagePosition: properties.imagePosition,
                        content: properties.content,
                        textColumns: properties.textColumns,
                        imgSrc: properties.imgSrc,
                        imageWidth: properties.imageWidth,
                        imgAltText: properties.imgAltText,
                        imgSmSrc: properties.imgSmSrc,
                        imgSmAltText: properties.imgSmAltText,
                        uid: properties.uid,
                        title: properties.title,
                        backgroundColor: properties.backgroundColor,
                        imageSmPosition: properties.imageSmPosition,
                    }
                    return (
                        <MultiColumnTextAndImage
                            key={`MultiColumnTextAndImage${contentSlotComponentProps.elementUid}`}
                            {...multiColumnTextAndImageProps}
                        />
                    )
                }
                case ComponentType.FLOW_IMAGE_AND_TEXT: {
                    const flowImageAndTextProps: FlowImageAndTextProps = {
                        ...contentSlotComponentProps,
                        color: properties.color,
                        mode: properties.mode,
                        imagePosition: properties.imagePosition,
                        imageSmPosition: properties.imageSmPosition,
                        imageWidth: properties.imageWidth,
                        imgSrc: properties.imgSrc,
                        title: properties.title,
                        content: properties.content,
                        imgSmSrc: properties.imgSmSrc,
                        imgAltText: properties.imgAltText,
                        url: properties.url,
                    }
                    return (
                        <FlowImageAndText
                            key={`FlowImageAndText${contentSlotComponentProps.elementUid}`}
                            {...flowImageAndTextProps}
                        />
                    )
                }
                case ComponentType.LINKED_IMAGE: {
                    const linkedImageProps: LinkedImageProps = {
                        ...contentSlotComponentProps,
                        url: properties.url,
                        width: properties.width,
                        imageWidth: properties.imageWidth,
                        imgSrc: properties.imgSrc,
                        imgSmSrc: properties.imgSmSrc,
                        className: properties.className,
                        imgAltText: properties.imgAltText,
                        imgSmAltText: properties.imgSmAltText,
                    }
                    return <LinkedImage key={`LinkedImage${contentSlotComponentProps.elementUid}`} {...linkedImageProps} />
                }
                case ComponentType.BANNER: {
                    const bannerProps: BannerProps = generateBannerProps(properties, contentSlotComponentProps)
                    return <Banner key={`Banner${contentSlotComponentProps.elementUid}`} {...bannerProps} />
                }
                case ComponentType.VIDEO_CMS_COMPONENT: {
                    const videoCMSProps: VideoCMSProps = {
                        video: properties.video,
                        videoHeight: properties.videoHeight,
                        headerText: properties.headerText,
                        gridSpan: properties.gridSpan,
                        last: properties.last,
                        autoplay: properties.autoplay,
                        loop: properties.loop,
                        mute: properties.mute,
                        mimeType: properties.mimeType,
                        descriptionText: properties.descriptionText,
                        externalIframeVideo: properties.externalIframeVideo,
                    }
                    return <VideoCMS key={`VideoCMS${contentSlotComponentProps.elementUid}`} {...videoCMSProps} />
                }
                case ComponentType.VIDEO_COMPONENT: {
                    const videoProps: VideoProps = {
                        video: properties.video,
                        videoHeight: properties.videoHeight,
                        headerText: properties.headerText,
                        gridSpan: properties.gridSpan,
                        last: properties.last,
                        autoplay: properties.autoplay,
                        loop: properties.loop,
                        mute: properties.mute,
                        mimeType: properties.mimeType,
                        externalIframeVideo: properties.externalIframeVideo,
                        styleClasses: properties.styleClasses,
                    }
                    return <Video key={`Video${contentSlotComponentProps.elementUid}`} {...videoProps} />
                }
                case ComponentType.USP_ITEM:
                    const uspItemProps: USPItemProps = {
                        ...contentSlotComponentProps,
                        url: properties.url,
                        content: properties.content,
                    }
                    return <USPItem key={`USPItem${contentSlotComponentProps.elementUid}`} {...uspItemProps} />
                case ComponentType.ROUND_IMG_AND_TEXT: {
                    const roundImgAndTextProps: RoundImgAndTextProps = {
                        ...contentSlotComponentProps,
                        color: properties.color,
                        mode: properties.mode,
                        imagePosition: properties.imagePosition,
                        imageSmPosition: properties.imageSmPosition,
                        imgSmSrc: properties.imgSmSrc,
                        imgSmAltText: properties.imgSmAltText,
                        imgSrc: properties.imgSrc,
                        imgAltText: properties.imgAltText,
                        title: properties.title,
                        content: properties.content,
                    }
                    return (
                        <RoundImgAndText
                            key={`RoundImgAndText${contentSlotComponentProps.elementUid}`}
                            {...roundImgAndTextProps}
                        />
                    )
                }
                case ComponentType.FIXED_IMAGE_AND_TEXT: {
                    const fixedImageAndTextProps: FixedImageAndTextProps = {
                        ...contentSlotComponentProps,
                        url: properties.url,
                        color: properties.color,
                        mode: properties.mode,
                        imgSrc: properties.imgSrc,
                        imgAltText: properties.imgAltText,
                        title: properties.title,
                        content: properties.content,
                        uid: properties.uid,
                    }
                    return (
                        <FixedImageAndText
                            key={`FixedImageAndText${contentSlotComponentProps.elementUid}`}
                            {...fixedImageAndTextProps}
                        />
                    )
                }
                case ComponentType.TEXT_AND_BUTTON: {
                    const textAndButtonProps: TextAndButtonProps = {
                        color: properties.color,
                        mode: properties.mode,
                        title: properties.title,
                        url: properties.url,
                        buttonText: properties.buttonText,
                        ssrKey: contentSlotComponentProps.ssrKey,
                    }
                    return <TextAndButton key={`TextAndButton${contentSlotComponentProps.elementUid}`} {...textAndButtonProps} />
                }
                case ComponentType.PARAGRAPH: {
                    const paragraphProps: ParagraphProps = {
                        ...contentSlotComponentProps,
                        color: properties.color,
                        mode: properties.mode,
                        title: properties.title,
                        content: properties.content,
                        styleClass: properties.styleClass,
                        summaryHeight: properties.summaryHeight,
                    }
                    return <Paragraph key={`Paragraph${contentSlotComponentProps.elementUid}`} {...paragraphProps} />
                }
                case ComponentType.TITLE_TEXT_AND_DYNAMIC_IMAGE: {
                    const titleTextAndDynamicImageProps: TitleTextAndDynamicImageProps = {
                        ...contentSlotComponentProps,
                        color: properties.color,
                        mode: properties.mode,
                        content: properties.content,
                        title: properties.title,
                        imgSrc: properties.imgSrc,
                        imgAltText: properties.imgAltText,
                        imgSmSrc: properties.imgSmSrc,
                        imgSmAltText: properties.imgSmAltText,
                        imagePosition: properties.imagePosition,
                        imageSmPosition: properties.imageSmPosition,
                    }
                    return (
                        <TitleTextAndDynamicImage
                            key={`TitleTextAndDynamicImage${contentSlotComponentProps.elementUid}`}
                            {...titleTextAndDynamicImageProps}
                        />
                    )
                }
                case ComponentType.ACCORDION_ITEM: {
                    const accordionItemProps: AccordionItemProps = {
                        ...contentSlotComponentProps,
                        children: properties.content,
                        openFromStart: properties.openFromStart === 'true',
                        className: properties.className,
                        title: properties.title,
                        expandedSubTitle: properties.expandedSubTitle,
                        titleSize: properties.titleSize,
                        subTitle: properties.subTitle,
                        iconColor: properties.iconColor,
                        cleanText: true,
                    }
                    return (
                        <AccordionItem
                            key={`AccordionItem${contentSlotComponentProps.elementUid}`}
                            {...accordionItemProps}
                            cleanText={true}
                        />
                    )
                }
                case ComponentType.RIGHT_NOW_IFRAME: {
                    const rightNowIFrameProps: RightNowIFrameProps = {
                        ...contentSlotComponentProps,
                        width: properties.width,
                        height: properties.height,
                        url: properties.url,
                    }
                    return (
                        <RightNowIFrame key={`RightNowIFrame${contentSlotComponentProps.elementUid}`} {...rightNowIFrameProps} />
                    )
                }
                case ComponentType.PRODUCT_UPSELL_CAROUSEL: {
                    const productUpsellCarouselProps: ProductUpsellCarouselProps = {
                        ...contentSlotComponentProps,
                        content: properties.content,
                        customTitleContent: properties.customTitleContent,
                        headerText: properties.headerText,
                        title: properties.title,
                    }
                    return (
                        <ProductUpsellCarousel
                            key={`ProductUpsellCarousel${contentSlotComponentProps.elementUid}`}
                            {...productUpsellCarouselProps}
                        />
                    )
                }
                case ComponentType.FIND_MODEL_MOD: {
                    const findModelProps: FindModelProps = {
                        ...contentSlotComponentProps,
                        brands: properties.brands,
                        mainTitle: properties.mainTitle,
                        selectBrandTitle: properties.selectBrandTitle,
                        position,
                        brandsListForMobile: properties.brandsListForMobile,
                        brandsList: properties.brandsList,
                        showAllLink: properties.showAllLink,
                    }
                    return <FindModel key={`FindModel${contentSlotComponentProps.elementUid}`} {...findModelProps} />
                }
                case ComponentType.INKCLUB_EBK_LIST: {
                    const inkClubEBKListProps: InkClubEBKListProps = {
                        ...contentSlotComponentProps,
                        headerText: properties.headerText,
                        styleClasses: properties.styleClasses,
                        promotionData: properties.promotionData.possiblePromotions,
                    }
                    return (
                        <InkClubEBKList key={`InkClubEBKList${contentSlotComponentProps.elementUid}`} {...inkClubEBKListProps} />
                    )
                }
                case ComponentType.LINK: {
                    const linkProps: LinkProps = {
                        ...contentSlotComponentProps,
                        external: properties.external,
                        target: properties.target,
                        to: properties.to,
                        titleClassName: properties.titleClassName,
                        title: properties.title,
                        className: properties.className,
                        children: properties.content,
                        linkTitle: properties.linkTitle,
                    }
                    return <Link key={`Link${contentSlotComponentProps.elementUid}`} {...linkProps} />
                }
                case ComponentType.CS_ADMIN_BANNER: {
                    return <CSAdminBanner {...contentSlotComponentProps} key={`CAB${properties.uid}`} />
                }
                case ComponentType.PRODUCT_RECOMMENDATION: {
                    const productRecommendationProps: ProductRecommendationProps = {
                        content: properties.content,
                        ssrKey: contentSlotComponentProps.ssrKey,
                        headerText: properties.headerText,
                    }
                    return (
                        <ProductRecommendation
                            key={`ProductRecommendation${contentSlotComponentProps.elementUid}`}
                            {...productRecommendationProps}
                        />
                    )
                }
                case ComponentType.CATEGORY_RECOMMENDATION: {
                    const categoryRecommendationProps: CategoryRecommendationProps = {
                        ...contentSlotComponentProps,
                        content: properties.content,
                        headerText: properties.headerText,
                        title: properties.title,
                        buttonLink: properties.buttonLink,
                        buttonText: properties.buttonText,
                    }
                    return (
                        <CategoryRecommendation
                            key={`CategoryRecommendation${contentSlotComponentProps.elementUid}`}
                            {...categoryRecommendationProps}
                        />
                    )
                }
                case ComponentType.BRANDS_LIST: {
                    const brandsListProps: BrandsListProps = {
                        ...contentSlotComponentProps,
                        content: properties.content,
                        headerText: properties.headerText,
                        title: properties.title,
                        text: properties.text,
                        url: properties.url,
                        logo: properties.logo,
                    }
                    return <BrandsList key={`BrandsList${contentSlotComponentProps.elementUid}`} {...brandsListProps} />
                }
                case ComponentType.PRODUCT_PROMOTION: {
                    const productPromotionProps: ProductPromotionProps = {
                        ...contentSlotComponentProps,
                        productCodes: properties?.productCodes || [],
                    }
                    return (
                        <ProductPromotion
                            key={`ProductPromotion${contentSlotComponentProps.elementUid}`}
                            {...productPromotionProps}
                        />
                    )
                }
                default:
                    return (
                        <div key={contentSlotComponentProps?.elementUid}>
                            <h3>{componentType}</h3>TBD
                        </div>
                    )
            }
        }
    }
    return recursiveRender(contentSlotItems) as React.ReactNode | React.ReactNode[]
}

type ComponentState = {
    fetchingContentSlot: boolean
    fetchingContentSlots: boolean
    contentSlot: ContentSlot
    advancedEditFlag: boolean
}

type Props = {
    pageId: string
    position: Position
    positionId?: string
    productCode?: string
    category?: string
    modelCategoryCode?: string
    brand?: string
    categoryCode?: string
    removeCaching?: boolean
}

const ContentSlot: React.FC<Props> = ({
    pageId,
    position,
    positionId = '',
    productCode,
    category,
    modelCategoryCode,
    brand,
    categoryCode,
    removeCaching,
}) => {
    const contentSlotKey: string = `${position}${positionId}${productCode || ''}${category || ''}${modelCategoryCode || ''}${brand || ''}${categoryCode || ''}`
    const contentSlotsStore = ({ reducers }: Store) => reducers[contentSlotsReducerName]
    const advnceEditFlagStore = ({ modules }: Store) => modules['App']?.advnceEditFlag
    const selector = createSelector(
        [contentSlotsStore, advnceEditFlagStore],
        (contentSlots: ContentSlotsStore, advnceEditFlag: boolean): ComponentState => ({
            fetchingContentSlot: contentSlots.fetchingContentSlot?.[contentSlotKey] === true,
            fetchingContentSlots: contentSlots.fetchingContentSlots?.[pageId] === true,
            contentSlot: contentSlots?.contentSlots?.[pageId]?.[contentSlotKey],
            advancedEditFlag: advnceEditFlag,
        }),
    )
    const { fetchingContentSlot, fetchingContentSlots, contentSlot, advancedEditFlag }: ComponentState = useSelector(selector)

    const dispatch = useDispatch()
    useEffect(() => {
        const fetchSingleContentSlot: boolean =
            productCode !== undefined ||
            category !== undefined ||
            modelCategoryCode !== undefined ||
            brand !== undefined ||
            categoryCode !== undefined ||
            removeCaching !== undefined
        if (fetchSingleContentSlot && !fetchingContentSlot) {
            dispatch(
                fetchProductContentSlot({
                    productCode,
                    category,
                    modelCategoryCode,
                    brand,
                    categoryCode,
                    position,
                    pageId,
                    contentSlotKey,
                }),
            )
        } else if (!fetchingContentSlots) {
            dispatch(fetchContentSlots(pageId))
        }
    }, [pageId, productCode, category, modelCategoryCode, brand, categoryCode, position, pageId])

    const renderedComponents: React.ReactNode | React.ReactNode[] = useMemo(
        () =>
            contentSlot?.contentSlotItems?.map((contentSlotItems: ContentSlotItems) =>
                renderComponent(contentSlotItems, contentSlot.position, advancedEditFlag),
            ),
        [contentSlot?.contentSlotItems, contentSlot?.position, advancedEditFlag],
    )
    if (!contentSlot) return

    return !DEBUG_MODE ? (
        <>
            {contentSlot?.contentSlotItems?.length || advancedEditFlag ? (
                <div
                    key={contentSlot.contentSlotUuid}
                    className="smartEditComponent"
                    data-smartedit-component-type="ContentSlot"
                    data-smartedit-component-id={contentSlot.contentSlotId}
                    data-smartedit-component-uuid={contentSlot.contentSlotUuid}
                    data-smartedit-catalog-version-uuid={contentSlot.contentSlotCatalogVersion}
                >
                    {renderedComponents}
                </div>
            ) : null}
        </>
    ) : (
        <div className={'border mb-1'}>
            <div className={`px-4 py-1 ${Style.CONTENT_SLOT_INFO_HEADER}`}>
                {position} -- {pageId}
            </div>
            {contentSlot?.contentSlotItems?.map((contentSlotItems: ContentSlotItems) => (
                <div key={contentSlotItems.properties.uid}>
                    <div className={`px-4 py-1 ${Style.CONTENT_SLOT_INFO_HEADER}`}>
                        Type: {contentSlotItems.componentType} Uid: {contentSlotItems.properties.uid || 'No uid'}
                    </div>
                    {renderedComponents}
                </div>
            ))}
        </div>
    )
}

export default ContentSlot
