const deriveArea = (eventCategory) => {
  if (location.pathname.match(/cart$/)) return 'CART';
  if (location.pathname.match(/\/m\//)) return 'MODEL';
  if (location.pathname.match(/\/p\//)) return 'PRODUCT';
  if (location.pathname.match(/\/t\//)) return 'TYPE';
  if (location.pathname.match(/\/c\//)) return 'CATEGORY';
  if (location.pathname.match(/\/checkout\//)) return 'CHECKOUT';

  return eventCategory || 'OTHER';
};

export default deriveArea;
