export const NAME = 'navigation'

export const ActionTypes = {
    REDIRECT_TO: `${NAME}/REDIRECT_TO`,
    NAVIGATE_TO: `${NAME}/NAVIGATE_TO`,
    RESET: `${NAME}/RESET`,
}

export enum NavigationType {
    NAVIGATE = 'navigate',
    REDIRECT = 'redirect',
}
