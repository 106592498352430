import { Logger } from '@spa-core/logger'
import net from '@spa-core-js/services/networkSvc'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { SessionConfig } from '../app/interfaces'
import { selectSessionConfig } from '../utils'
import { ActionTypes } from './constants'
import { FetchBreadcrumbsPayload } from './interfaces'

export function* fetchBreadcrumbs({ payload }: any) {
    const { categoryCode, typeOfPage, brand, productCode, pageId }: FetchBreadcrumbsPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    let url: string = `${sessionConfig.urlPrefix}/rest/v1/breadcrumb?typeOfPage=${typeOfPage}`
    if (categoryCode) {
        url += '&categoryCode=' + categoryCode
    }
    if (brand) {
        url += '&brand=' + brand
    }
    if (productCode) {
        url += '&product=' + productCode
    }
    if (pageId) {
        url += '&pageId=' + pageId
    }
    try {
        const breadcrumbData = yield call(() => net.get(url))
        yield put({
            type: ActionTypes.FETCHED_BREADCRUMBS,
            payload: {
                breadcrumbData,
                categoryCode,
                brand,
                productCode,
                pageId,
            },
        })
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export const watchers = [takeLatest(ActionTypes.FETCH_BREADCRUMBS, fetchBreadcrumbs)]
