import React, { MouseEvent, ReactNode } from 'react'
import classnames from 'classnames'
import Icon, { Icons } from '@ui-elem/Icon/Icon'

enum Styles {
    BUTTON = 'button',
    BUTTON_RIPPLE_EFFECT = 'button_ripple_effect',
    BUTTON_BORDER_RADIUS = 'button_border_radius',
    PRIMARY_COLOR = 'primary_color',
    PRIMARY_COLOR_HOVER = 'primary_color_hover',
    SECONDARY_COLOR = 'secondary_color',
    SECONDARY_COLOR_HOVER = 'secondary_color_hover',
    TERTIARY_COLOR = 'tertiary_color',
    TERTIARY_COLOR_HOVER = 'tertiary_color_hover',
    PALE_COLOR = 'pale_color',
    PALE_COLOR_HOVER = 'pale_color_hover',
    PANEL_COLOR = 'panel_color',
    PANEL_COLOR_HOVER = 'panel_color_hover',
    BUTTON_DISABLED_COLOR = 'button_disabled_color',
    BUTTON_LOADING_COLOR = 'button_loading_color',
    BUTTON_SUBSCRIPTION_SELECTED = 'button_subscription_selected',
    BUTTON_SUBSCRIPTION_NOT_SELECTED = 'button_subscription_not_selected',
}

export enum IconAlignments {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
}

export enum ButtonColors {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    PALE = 'pale',
    PANEL = 'panel',
    NOTSELECTED = 'notSelected',
    SELECTED = 'selected',
}

type Props = {
    id?: string
    className?: string
    loading?: boolean
    uppecase?: boolean
    flat?: boolean
    fluid?: boolean
    disabled?: boolean
    onClick?: (e: MouseEvent) => void
    iconAlignment?: IconAlignments
    buttonColor?: ButtonColors
    buttonIcon?: Icons
    buttonText?: string
    buttonPadding?: string
    children?: ReactNode
    tabIndex?: number
}

const Button: React.FC<Props> = ({
    id,
    loading = false,
    iconAlignment,
    buttonColor = ButtonColors.PRIMARY,
    buttonIcon,
    buttonText,
    onClick = () => {},
    fluid = false,
    flat = false,
    uppecase = false,
    disabled = false,
    className = '',
    buttonPadding = 'px-4 py-2',
    children,
    tabIndex = 0,
}) => {
    const buttonIconElement = buttonIcon ? (
        <Icon
            icon={buttonIcon}
            className={classnames(
                iconAlignment === IconAlignments.RIGHT ? 'pl-2' : '',
                iconAlignment === IconAlignments.LEFT ? 'pr-2' : '',
            )}
        />
    ) : null

    return (
        <button
            id={id}
            className={classnames(
                Styles.BUTTON,
                buttonColor !== ButtonColors.NOTSELECTED && buttonColor !== ButtonColors.SELECTED
                    ? Styles.BUTTON_BORDER_RADIUS
                    : null,
                !disabled ? Styles.BUTTON_RIPPLE_EFFECT : null,
                className,
                buttonPadding,
                uppecase ? 'uppercase' : '',
                flat ? '' : 'shadow',
                buttonColor === ButtonColors.PRIMARY ? Styles.PRIMARY_COLOR : null,
                buttonColor === ButtonColors.PRIMARY ? Styles.PRIMARY_COLOR_HOVER : null,
                buttonColor === ButtonColors.SECONDARY ? Styles.SECONDARY_COLOR : null,
                buttonColor === ButtonColors.SECONDARY ? Styles.SECONDARY_COLOR_HOVER : null,
                buttonColor === ButtonColors.TERTIARY ? Styles.TERTIARY_COLOR : null,
                buttonColor === ButtonColors.TERTIARY ? Styles.TERTIARY_COLOR_HOVER : null,
                buttonColor === ButtonColors.PALE ? Styles.PALE_COLOR : null,
                buttonColor === ButtonColors.PALE ? Styles.PALE_COLOR_HOVER : null,
                buttonColor === ButtonColors.PANEL ? Styles.PANEL_COLOR : null,
                buttonColor === ButtonColors.PANEL ? Styles.PANEL_COLOR_HOVER : null,
                buttonColor === ButtonColors.NOTSELECTED ? Styles.BUTTON_SUBSCRIPTION_NOT_SELECTED : null,
                buttonColor === ButtonColors.SELECTED ? Styles.BUTTON_SUBSCRIPTION_SELECTED : null,
                loading ? Styles.BUTTON_LOADING_COLOR : null,
                disabled ? Styles.BUTTON_DISABLED_COLOR : null,
                fluid ? 'w-full' : '',
                'flex flex-row justify-center items-center',
            )}
            onClick={onClick}
            tabIndex={disabled ? -1 : tabIndex}
            type="button"
            disabled={disabled === true || loading === true}
        >
            {/* Text together with icon. If no icon is present, only the text will appeare. */}
            {buttonIcon && iconAlignment === IconAlignments.LEFT && !loading ? buttonIconElement : null}
            <span>{loading ? <Icon icon={Icons.Spinner} size={20} /> : buttonText}</span>
            {buttonIcon && iconAlignment === IconAlignments.RIGHT && !loading ? buttonIconElement : null}

            {/* If no text and only icon. Align the icon to center to create an icon-button */}
            {buttonIcon && iconAlignment === IconAlignments.CENTER ? buttonIconElement : null}

            {children}
        </button>
    )
}

export default Button
