export const NAME = 'contentSlots'

export const ActionTypes = {
    SET_FETCHING_CONTENT_SLOT: `${NAME}/SET_FETCHING_CONTENT_SLOT`,
    SET_FETCHING_CONTENT_SLOTS: `${NAME}/SET_FETCHING_CONTENT_SLOTS`,
    FETCH_CONTENT_SLOTS: `${NAME}/FETCH_CONTENT_SLOTS`,
    FETCHED_CONTENT_SLOTS: `${NAME}/FETCHED_CONTENT_SLOTS`,
    FETCH_PRODUCT_CONTENT_SLOT: `${NAME}/FETCH_PRODUCT_CONTENT_SLOT`,
    FETCHED_PRODUCT_CONTENT_SLOT: `${NAME}/FETCHED_PRODUCT_CONTENT_SLOT`,
    SET_FETCHED_CONTENT_SLOT_URLS: `${NAME}/SET_FETCHED_CONTENT_SLOT_URLS`,
    FETCH_COMPONENT_CONTENT_SLOT: `${NAME}/FETCH_COMPONENT_CONTENT_SLOT`,
    FETCHED_COMPONENT_CONTENT_SLOT: `${NAME}/FETCHED_COMPONENT_CONTENT_SLOT`,
    SET_FETCHING_COMPONENT_CONTENT_SLOT: `${NAME}/SET_FETCHING_COMPONENT_CONTENT_SLOT`,
    FETCH_SECTION_ID_PAGE_ID_CONTENT_SLOT: `${NAME}/FETCH_SECTION_ID_PAGE_ID_CONTENT_SLOT`,
    FETCHED_SECTION_ID_PAGE_ID_CONTENT_SLOT: `${NAME}/FETCHED_SECTION_ID_PAGE_ID_CONTENT_SLOT`,
    SET_FETCHING_CONTENT_SLOT_WITH_SECTION_ID_PAGE_ID: `${NAME}/SET_FETCHING_CONTENT_SLOT_WITH_SECTION_ID_PAGE_ID`,
}

export enum ComponentType {
    FRAGMENT = 'Fragment',
    STYLES_BANNER_WRAPPER = 'StylesBannerWrapper',
    CAROUSEL = 'Carousel',
    USP_WRAPPER = 'USPWrapper',
    TITLE_TEXT_AND_DYNAMIC_IMAGE_WRAPPER = 'TitleTextAndDynamicImageWrapper',
    ACCORDION = 'Accordion',
    BANNER_WRAPPER = 'BannerWrapper',
    LOGO_LIST = 'LogoList',
    COMPONENTS_CONTAINER = 'ComponentsContainer',
    MULTI_COLUMN_TEXT_AND_IMAGE = 'MultiColumnTextAndImage',
    FLOW_IMAGE_AND_TEXT = 'FlowImageAndText',
    LINKED_IMAGE = 'LinkedImage',
    BANNER = 'Banner',
    VIDEO_CMS_COMPONENT = 'VideoCMSComponent',
    VIDEO_COMPONENT = 'VideoComponent',
    USP_ITEM = 'USPItem',
    ROUND_IMG_AND_TEXT = 'RoundImgAndText',
    FIXED_IMAGE_AND_TEXT = 'FixedImageAndText',
    TEXT_AND_BUTTON = 'TextAndButton',
    PARAGRAPH = 'Paragraph',
    TITLE_TEXT_AND_DYNAMIC_IMAGE = 'TitleTextAndDynamicImage',
    ACCORDION_ITEM = 'AccordionItem',
    RIGHT_NOW_IFRAME = 'RightNowIFrame',
    PRODUCT_UPSELL_CAROUSEL = 'ProductUpsellCarousel',
    FIND_MODEL_MOD = 'FindModelMod',
    LINK = 'Link',
    CS_ADMIN_BANNER = 'CSAdminBanner',
    PRODUCT_RECOMMENDATION = 'ProductRecommendation',
    CATEGORY_RECOMMENDATION = 'CategoryRecommendation',
    BRANDS_LIST = 'BrandsList',
    PRODUCT_PROMOTION = 'ProductPromotion', // Possibly deprecated?
    PERSONALIZED_PRODUCT = 'PersonalizedProduct',
    TREE_VIEW = 'TreeView',
    INKCLUB_EBK_LIST = 'InkclubEBKList',
    IMAGE_AND_TEXT_BANNER_COMPONENT = 'ImageAndTextBannerComponent',
}

export enum Position {
    PRODUCT_RECOMMENDATION = 'ProductRecommendation',
    TOP_BANNER_AREA = 'TopBannerArea',
    BOTTOM_BANNER_AREA = 'BottomBannerArea',
    EXTRA_INFO_CART = 'ExtraInfo-Cart',
    TOP_CONTENT = 'TopContent',
    MIDDLE_CONTENT = 'MiddleContent',
    UPSELL = 'Upsell',
    SECTION_1 = 'Section1',
    BOTTOM_CONTENT = 'BottomContent',
    MAIN_CONTENT = 'MainContent',
    CONTENT_BANNER_AREA = 'ContentBannerArea',
    ADDRESS_LOOKUP_INFO_BOX = 'AddresLookUpInfoBox',
    RIGHTS_OF_REGRET = 'RightsOfRegret',
    TERMS_AND_CONDITIONS = 'TermsAndConditions',
    PAYPAL_PAYMENT_VALIDATION = 'paypalPaymentValidation',
    BANK_PAYMENT_VALIDATION = 'bankPaymentValidation',
    CREDIT_CARD_PAYMENT_VALIDATION = 'creditCardPaymentValidation',
    CART_SUMMARY_BOTTOM_AREA = 'CartSummaryBottomArea',
    RIGHT_FOOTER = 'RightFooter',
    BOTTOM_FOOTER = 'BottomFooter',
    NAV_MENU_SUB_SECTION = 'NavMenuSubSection',
    USP = 'USP',
    BRAND_LOGO_LIST = 'BrandLogoList',
    CATEGORY_CONTENT = 'CategoryContent',
    HEADER_MODEL = 'Header-Model',
    MODEL_TYPE_DESCRIPTION = 'ModelTypeDescription',
    MESSAGE_CONTENT = 'MessageContent',
    PRINTER_MODEL_RECOMMENDATION = 'PrinterModelRecommendation',
    VACUUM_MODEL_RECOMMENDATION = 'VaccumModelRecommendation',
    IMAGE_ORIGINAL = 'Image-Original',
    IMAGE_RECOMMENDED = 'Image-Recommended',
    IMAGE_BUDGET = 'Image-Budget',
    IMAGE_OPTIMUM = 'Image-Optimum',
    IMAGE_OTHER = 'Image-Other',
    IMAGE_PAPER = 'Image-Paper',
    HEADER_ORIGINAL = 'Header-Original',
    HEADER_RECOMMENDED = 'Header-Recommended',
    HEADER_BUDGET = 'Header-Budget',
    HEADER_OPTIMUM = 'Header-Optimum',
    HEADER_OTHER = 'Header-Other',
    DESCRIPTION_ORIGINAL = 'Description-Original',
    DESCRIPTION_RECOMMENDED = 'Description-Recommended',
    DESCRIPTION_BUDGET = 'Description-Budget',
    DESCRIPTION_OPTIMUM = 'Description-Optimum',
    DESCRIPTION_OTHER = 'Description-Other',
    TOPMOST_AREA_CART_PAGE = 'TopmostAreaCartPage',
    QLIRO_PLACE_ORDER_IN_PROGRESS_SECTION = 'QliroPlaceOrderInProgressSection',
    QLIRO_DOWN_SECTION = 'QliroDownSection',
    PERSONALIZED_BANNER = 'PersonalizedBanner',
    FAQ_SECTION = 'FAQSection',
    CONTACT_US_SECTION_1 = 'ContactUsSection1',
    CONTACT_US_SECTION_2 = 'ContactUsSection2',
    SECTION_2A = 'Section2A',
    CS_ADMIN = 'CSAdmin',
    CAMPAIGN_BILLBOARD = 'CampaignBillboard',
    TOP_LEFT_AREA = 'TopLeftArea',
    BILLBOARD = 'Billboard',
    TOP_RIGHT_AREA = 'TopRightArea',
    WELCOME = 'Welcome',
    HIGHLIGHTS = 'Highlights',
    CHECKLIST = 'Checklist',
    PUFF_HEADER = 'PuffHeader',
    CRM_FOCUSED_INBOX_WEB = 'CRMFocusedInboxWeb',
}
