import { ActionTypes } from './constants'
import { HistoricalLowPrice, HistoricalLowPriceStore } from './interfaces'
import { Action } from '../interfaces'

export const INITIAL_STATE: HistoricalLowPriceStore = {
    historicalLowPrices: {},
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): HistoricalLowPriceStore => {
    switch (type) {
        case ActionTypes.FETCHED_HISTORICAL_LOW_PRICE: {
            const { historicalLowPrice, productCode } = payload
            const newHistoricalLowPrice: HistoricalLowPrice = historicalLowPrice
            return {
                ...state,
                historicalLowPrices: {
                    ...state.historicalLowPrices,
                    [productCode]: newHistoricalLowPrice,
                },
            }
        }
        default:
            return state
    }
}
