import React, { useState } from 'react'
import { t } from '@spa-core/locale'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {}

const FooterProviders: React.FC<Props> = () => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[sessionReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)
    const rootPath: string = 'images/footer'

    const [showPaymentImage, setShowPaymentImage] = useState<boolean>(true)
    const [showDeliveryImage, setShowDeliveryImage] = useState<boolean>(true)

    return (
        <div className="flex flex-col md:flex-row gap-1 justify-center font-head e2e-ver-footer-payment-options">
            <div className={classNames(showPaymentImage ? '' : 'hidden', 'p-6 e2e-ver-footer-payment-provider')}>
                <div className="ml-1">{t('phrase.payment')}</div>
                <img
                    alt="Payment provider"
                    src={`${sessionConfig.themeResourcePath}/${rootPath}/payment/${sessionConfig.currentLanguageIsocode.toLowerCase()}_${sessionConfig.b2bMode ? 'b2b' : 'b2c'}.webp`}
                    onError={() => setShowPaymentImage(false)}
                    className="mt-3"
                />
            </div>
            <div className={classNames(showDeliveryImage ? '' : 'hidden', 'p-6 e2e-ver-footer-delivery-provider')}>
                <span className="ml-1">{t('phrase.delivery')}</span>
                <img
                    alt="Delivery provider"
                    src={`${sessionConfig.themeResourcePath}/${rootPath}/delivery/${sessionConfig.currentLanguageIsocode.toLowerCase()}_${sessionConfig.b2bMode ? 'b2b' : 'b2c'}.webp`}
                    onError={() => setShowDeliveryImage(false)}
                    className="mt-3"
                />
            </div>
        </div>
    )
}

export default FooterProviders
