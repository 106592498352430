/**
 * Wrapper around js-cookie for faster cookie handling
 */
import cookie from 'js-cookie';

const cache = {};

const set = (k, v, o) => {
  cookie.set(k, v, o);
  cache[k] = v;
};

const get = (k, o) => {
  if (cache[k]) return cache[k];
  return cookie.get(k, o);
};

const remove = (k) => {
  if (cache[k]) {
    delete cache[k];
  }
  return cookie.remove(k);
};

export default { set, get, remove };
