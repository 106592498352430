import { Icons } from '../constants'
import {
    FaAngleRight,
    FaSpinner,
    FaCheck,
    FaAngleDown,
    FaRegUser,
    FaUser,
    FaRegCompass,
    FaRegSquareCheck,
    FaRegSquare,
    FaSliders,
    FaShare,
    FaAngleLeft,
    FaEnvelope,
    FaBars,
    FaBullhorn,
    FaCaretUp,
    FaCaretDown,
    FaAngleUp,
    FaRecycle,
    FaUserLock,
    FaTruck,
    FaAddressCard,
    FaRegCreditCard,
    FaCartArrowDown,
    FaRegClock,
    FaPiggyBank,
    FaTriangleExclamation,
    FaPlus,
    FaMinus,
    FaCircleCheck,
    FaClock,
    FaRegCircleDot,
    FaRegCirclePause,
    FaRegCirclePlay,
    FaRegCircleStop,
    FaGlobe,
    FaPhone,
    FaHouse,
    FaUnlockKeyhole,
    FaDoorOpen,
    FaRegHeart,
    FaPencil,
    FaCircle,
    FaCirclePlus,
    FaCircleMinus,
} from 'react-icons/fa6'
import {
    FaRegSadTear,
    FaTimes,
    FaHome,
    FaAddressBook,
    FaEdit,
    FaSignOutAlt,
    FaSearch,
    FaCalendarAlt,
    FaHistory,
    FaMapMarkerAlt,
    FaTrash,
    FaTimesCircle,
    FaRegCircle,
    FaShoppingBasket,
    FaSyncAlt,
    FaRegCommentAlt,
    FaReply,
    FaUserCircle,
    FaQuoteRight,
} from 'react-icons/fa'
import { MdOutlineNumbers } from 'react-icons/md'
import { IoCartOutline, IoCart } from 'react-icons/io5'
import { GiVacuumCleaner } from 'react-icons/gi'
import { PiPrinterFill } from 'react-icons/pi'
import { HiOutlineMagnifyingGlassPlus } from 'react-icons/hi2'
import { IconStyles } from '../interfaces/IconStyles'
import React from 'react'
import { CiCircleInfo } from 'react-icons/ci'
import { GoDot, GoDotFill } from 'react-icons/go'
import { RiShoppingBasket2Fill, RiShoppingBasket2Line } from 'react-icons/ri'
import { LuAirVent } from 'react-icons/lu'

export const getDefaultIcon = (iconName: Icons, styles: IconStyles): React.ReactNode => {
    switch (iconName) {
        case Icons.AngleRight:
            return <FaAngleRight style={styles} title="Angel icon pointing right" />
        case Icons.AngleLeft:
            return <FaAngleLeft style={styles} title="Angel icon pointing left" />
        case Icons.AngleDown:
            return <FaAngleDown style={styles} title="Angel icon pointing down" />
        case Icons.AngleUp:
            return <FaAngleUp style={styles} title="Angel icon pointing up" />
        case Icons.SadTear:
            return <FaRegSadTear style={styles} title="Sad emoji with tear in eye icon" />
        case Icons.Spinner:
            return <FaSpinner style={styles} className={'animate-spin'} title="Spinner icon" />
        case Icons.Times:
            return <FaTimes style={styles} title="Close icon" />
        case Icons.Home:
            return <FaHome style={styles} title="Home icon" />
        case Icons.AddressBook:
            return <FaAddressBook style={styles} title="Address book icon" />
        case Icons.Edit:
            return <FaEdit style={styles} title="Edit icon" />
        case Icons.Check:
            return <FaCheck style={styles} title="Check icon" />
        case Icons.CartOutline:
            return <IoCartOutline style={styles} title="Outlined cart icon" />
        case Icons.Cart:
            return <IoCart style={styles} title="Filled cart icon" />
        case Icons.RegUser:
            return <FaRegUser style={styles} title="Login user icon" />
        case Icons.User:
            return <FaUser style={styles} title="User icon" />
        case Icons.RegCompass:
            return <FaRegCompass style={styles} title="Compass icon" />
        case Icons.RegSquareCheck:
            return <FaRegSquareCheck style={styles} title="Square icon with checkmark" />
        case Icons.RegSquare:
            return <FaRegSquare style={styles} title="Square icon without checkmark" />
        case Icons.Sliders:
            return <FaSliders style={styles} title="Slider icon" />
        case Icons.Share:
            return <FaShare style={styles} title="Share icon" />
        case Icons.SignOutAlt:
            return <FaSignOutAlt style={styles} title="Signout icon" />
        case Icons.Envelope:
            return <FaEnvelope style={styles} title="Envelope icon" />
        case Icons.Bars:
            return <FaBars style={styles} title="Menu bars icon" />
        case Icons.Search:
            return <FaSearch style={styles} title="Search icon" />
        case Icons.Bullhorn:
            return <FaBullhorn style={styles} title="Bullhorn icon" />
        case Icons.CaretUp:
            return <FaCaretUp style={styles} title="Caret icon pointing up" />
        case Icons.CaretDown:
            return <FaCaretDown style={styles} title="Caret icon pointing down" />
        case Icons.Recycle:
            return <FaRecycle style={styles} title="Recycle icon" />
        case Icons.UserLock:
            return <FaUserLock style={styles} title="User lock icon" />
        case Icons.Truck:
            return <FaTruck style={styles} title="Truck icon" />
        case Icons.AddressCard:
            return <FaAddressCard style={styles} title="Address card icon" />
        case Icons.CalendarAlt:
            return <FaCalendarAlt style={styles} title="Calender icon" />
        case Icons.RegCreditCard:
            return <FaRegCreditCard style={styles} title="Cerdit card icon" />
        case Icons.CartArrowDown:
            return <FaCartArrowDown style={styles} title="Caret icon pointing down" />
        case Icons.History:
            return <FaHistory style={styles} title="History icon" />
        case Icons.VacuumCleaner:
            return <GiVacuumCleaner style={styles} title="Vacuum cleaner icon" />
        case Icons.PrinterFill:
            return <PiPrinterFill style={styles} title="Filled printer icon" />
        case Icons.AirVent:
            return <LuAirVent style={styles} title="Air vent icon" />
        case Icons.RegClock:
            return <FaRegClock style={styles} title="Clock icon" />
        case Icons.MapMarkerAlt:
            return <FaMapMarkerAlt style={styles} title="Map marker icon" />
        case Icons.PiggyBank:
            return <FaPiggyBank style={styles} title="Piggy bank icon" />
        case Icons.MagnifyingGlassPlus:
            return <HiOutlineMagnifyingGlassPlus style={styles} title="Magnifying glas icon" />
        case Icons.TriangleExclamation:
            return <FaTriangleExclamation style={styles} title="Triangel with exclamation mark icon" />
        case Icons.Trash:
            return <FaTrash style={styles} title="Trash can icon" />
        case Icons.Plus:
            return <FaPlus style={styles} title="Plus symbole icon" />
        case Icons.Minus:
            return <FaMinus style={styles} title="Minus symbol icon" />
        case Icons.CircleCheck:
            return <FaCircleCheck style={styles} title="Circel with checkmark icon" />
        case Icons.TimesCircle:
            return <FaTimesCircle style={styles} title="Circle with times symbole" />
        case Icons.Clock:
            return <FaClock style={styles} title="Clock icon" />
        case Icons.RegCircleDot:
            return <FaRegCircleDot style={styles} title="Checked radio button, circle with dot inside" />
        case Icons.RegCircle:
            return <FaRegCircle style={styles} title="Unchecked radio button, circle without dot inside" />
        case Icons.ShoppingBasket:
            return <FaShoppingBasket style={styles} title="Shopping basket icon" />
        case Icons.RegCirclePause:
            return <FaRegCirclePause style={styles} title="Circel icon with pause symbole" />
        case Icons.RegCirclePlay:
            return <FaRegCirclePlay style={styles} title="Circel icon with play symbole" />
        case Icons.RegCircleStop:
            return <FaRegCircleStop style={styles} title="Circel icon with stop symbole" />
        case Icons.SyncAlt:
            return <FaSyncAlt style={styles} title="Syncronisation icon" />
        case Icons.Globe:
            return <FaGlobe style={styles} title="Glode icon" />
        case Icons.Phone:
            return <FaPhone style={styles} title="Phone icon" />
        case Icons.House:
            return <FaHouse style={styles} title="House icon" />
        case Icons.UserCircle:
            return <FaUserCircle style={styles} title="Circle icon with user symbole inside" />
        case Icons.RegCommentAlt:
            return <FaRegCommentAlt style={styles} title="Register comment icon" />
        case Icons.UnlockKeyhole:
            return <FaUnlockKeyhole style={styles} title="Unlock keyhole icon" />
        case Icons.DoorOpen:
            return <FaDoorOpen style={styles} title="Open door icon" />
        case Icons.RegHeart:
            return <FaRegHeart style={styles} title="Heart icon" />
        case Icons.Pencil:
            return <FaPencil style={styles} title="Pencil icon" />
        case Icons.Reply:
            return <FaReply style={styles} title="Reply icon" />
        case Icons.QuoteRight:
            return <FaQuoteRight style={styles} title="Right quote icon" />
        case Icons.CircleInfo:
            return <CiCircleInfo style={styles} title="Circle info circle" />
        case Icons.Dot:
            return <GoDot style={styles} title="Dot icon" />
        case Icons.DotFill:
            return <GoDotFill style={styles} title="Filled dot icon" />
        case Icons.Circle:
            return <FaCircle style={styles} title="Circle icon" />
        case Icons.OutlineNumbers:
            return <MdOutlineNumbers style={styles} title="Outline numbers icon" />
        case Icons.CirclePlus:
            return <FaCirclePlus style={styles} title="Circle icon with plus symbole" />
        case Icons.CircleMinus:
            return <FaCircleMinus style={styles} title="Circle icon with minus symbole" />
        default:
            return <div />
    }
}

export const getDammsugarpasarIcon = (iconName: Icons, styles: IconStyles): React.ReactNode => {
    switch (iconName) {
        case Icons.Cart:
            return <RiShoppingBasket2Fill style={styles} title="Filled shopping basket icon" />
        case Icons.CartOutline:
            return <RiShoppingBasket2Line style={styles} title="Empty shopping basket icon" />
        default:
            return getDefaultIcon(iconName, styles)
    }
}

export const getLamportillalltIcon = (iconName: Icons, styles: IconStyles): React.ReactNode => {
    switch (iconName) {
        case Icons.Cart:
            return <IoCartOutline style={styles} title="Shopping basket icon" />
        case Icons.CartOutline:
            return <IoCartOutline style={styles} title="Shopping basket icon" />
        default:
            return getDefaultIcon(iconName, styles)
    }
}
