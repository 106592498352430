import { ActionTypes, CustomerFeedbackPostStatus } from './constants'
import { CustomerFeedback, CustomerFeedbackStore } from './interfaces'
import { Action } from '../interfaces'

export const INITIAL_STATE: CustomerFeedbackStore = {
    customerFeedback: [],
    postCustomerFeedbackStatus: CustomerFeedbackPostStatus.WAITING,
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): CustomerFeedbackStore => {
    switch (type) {
        case ActionTypes.FETCHED_CUSTOMER_FEEDBACK: {
            const { customerFeedback } = payload
            const newCustomerFeedback: CustomerFeedback[] = customerFeedback.filter(
                ({ orderCode }) =>
                    !state.customerFeedback.find(({ orderCode: orderCodeInState }) => orderCode === orderCodeInState),
            )
            return {
                ...state,
                customerFeedback: [...state.customerFeedback, ...newCustomerFeedback],
            }
        }
        case ActionTypes.POSTED_CUSTOMER_FEEDBACK:
            return {
                ...state,
                postCustomerFeedbackStatus: payload.postCustomerFeedbackStatus,
            }
        default:
            return state
    }
}
