import { Product, SlimProduct } from '../../store/products/interfaces'
import { TrackingQueues, MAX_TRACKING_QUEUE_LENGTH } from '../constants'
import { TrackViewedItemPayload, TrackingProduct } from '../interfaces'
import { Logger } from '@spa-core/logger'

export const parseCategoryProductsForTracking = (
    b2bStore: boolean,
    products: any[],
    subCategoryProducts: any[],
): TrackingProduct[] => {
    const items: TrackingProduct[] = []
    if (subCategoryProducts?.length) {
        items.push(
            ...subCategoryProducts.map((subCatProduct: SlimProduct) => ({
                item_id: subCatProduct.code,
                item_name: subCatProduct.name,
                currency: subCatProduct.currency,
                b2bStore,
                price: subCatProduct.price,
            })),
        )
    } else {
        items.push(
            ...products.map((product) => ({
                item_id: product.code,
                item_name: product.name,
                currency: product.currency,
                price: product.price,
                b2bStore,
                discount: product.discountedPrice ? product.price - product.discountedPrice : 0,
            })),
        )
    }
    const uniqueItems: TrackingProduct[] = items.reduce((accumulator: TrackingProduct[], item: TrackingProduct) => {
        if (accumulator.find(({ item_id }) => item_id === item.item_id)) { // eslint-disable-line
            return accumulator
        }
        return [...accumulator, item]
    }, [])
    return uniqueItems
}

export const parseViewedItemForTracking = (product: Product, b2bStore: boolean): TrackViewedItemPayload => {
    const productPrice = product.price
    const discountedPrice = product.discountedPrice ? parseFloat(product.discountedPrice.toString()) : 0
    const discount = discountedPrice ? productPrice - discountedPrice : 0
    const trackingPayload: TrackViewedItemPayload = {
        currency: product.currency,
        discount,
        value: productPrice - discount,
        items: [
            {
                item_id: product.code,
                item_name: product.name,
                price: productPrice,
                b2bStore,
                quantity: 1,
                discount,
            },
        ],
    }
    return trackingPayload
}

export const getTrackingQueue = (trackingQueue: TrackingQueues): any[] => {
    const trackingQueueFromStorage: string = sessionStorage.getItem(trackingQueue)
    let trackingQueueList: any[]
    try {
        trackingQueueList = trackingQueueFromStorage ? JSON.parse(trackingQueueFromStorage) : []
    } catch (_error: any) {
        /**
         * Error setting trackingQueue
         */
        trackingQueueList = []
    }
    return trackingQueueList
}

export const addToTrackingQueue =
    (trackingQueue: TrackingQueues) =>
    (trackingObject: any): Promise<void> => {
        Logger.info('Add to tracking queue', trackingQueue, trackingObject)
        const trackingQueueList: any[] = getTrackingQueue(trackingQueue)
        trackingQueueList.push(trackingObject)
        sessionStorage.setItem(trackingQueue, JSON.stringify(trackingQueueList.slice(-MAX_TRACKING_QUEUE_LENGTH)))
        return Promise.resolve()
    }
