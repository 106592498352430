import { getReducer, registerModule as regModule } from '@spa-core-js/redux/reducer'
import { EnhancedStore, configureStore } from '@reduxjs/toolkit'
import analytics from '@spa-core/tracking/middleware/utils-middleware'
import matomoMiddleware from '@spa-core/tracking/middleware/matomo-middleware'
import tagManagerMiddleware from '@spa-core/tracking/middleware/tag-manager-middleware'
import thirdPartiesMiddleware from '@spa-core/middleware/third-parties-middleware'
import { Logger } from '@spa-core/logger'
import { thunk } from 'redux-thunk'
import { watchers, reducers } from '@spa-core/store'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import { all } from 'redux-saga/effects'
import { merge } from 'ts-deepmerge'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: Function
    }
}

let win: any = {}
if (typeof window !== 'undefined') {
    win = window
}

/**
 * Init state with sessionConfig loaded from win.sessionConf
 */
let preloadedState: any = {
    reducers: {
        app: {
            sessionConfig: win?.sessionConf,
        },
    },
}

const init = () => {
    // Check if running on server side
    if (typeof win === 'object' && win.__INITIAL_STATE) {
        const windowInitialState: any = win.__INITIAL_STATE
        preloadedState = {
            modules: windowInitialState.modules || {},
            reducers: windowInitialState.reducers || {},
        }

        /**
         * Clear sessionConfig loaded from win.__INITIAL_STATE and replace with win.sessionConf
         */
        try {
            preloadedState.reducers.app.sessionConfig = win?.sessionConf
        } catch (e) {
            /**
             * sessionConfig not present in win.__INITIAL_STATE
             */
        }

        /**
         * Get initial state for each reducer
         */
        const reducersInitialStates: any = {}
        const reducerNames: string[] = Object.keys(reducers)
        reducerNames.forEach((reducerName: string) => {
            reducersInitialStates[reducerName] = reducers[reducerName](undefined, {})
        })

        /**
         * Remove any reducer from win.__INITIAL_STATE.reducers that is not
         * present in @spa-core/store
         */
        const preloadedStateReducerNames: string[] = Object.keys(preloadedState.reducers)
        preloadedStateReducerNames.forEach((preloadedReducerName: string) => {
            if (!reducersInitialStates[preloadedReducerName]) {
                delete preloadedState.reducers[preloadedReducerName]
            }
        })

        /**
         * Hydrate the initialState from all reducers with data from win.__INITIAL_STATE.reducers
         */
        const mergedInitialReducerStates: any = merge.withOptions(
            { mergeArrays: true },
            reducersInitialStates,
            preloadedState.reducers || {},
        )
        preloadedState.reducers = mergedInitialReducerStates
    }
}

let initialized: boolean = false
let store: EnhancedStore

// hook up your middlewares here
let dynLoading: boolean = true

function* rootSaga() {
    yield all(watchers)
}

export const newStore = (noDynLoading, callback = (arg: any) => {}) => {
    if (!initialized) {
        init()
        initialized = true
    }

    Logger.debug('noDynloading', noDynLoading, preloadedState)
    if (noDynLoading) {
        dynLoading = false
    }

    const sagaMiddleware: SagaMiddleware = createSagaMiddleware()
    const middlewares: any = [thunk, analytics, matomoMiddleware, thirdPartiesMiddleware, tagManagerMiddleware, sagaMiddleware]
    if (window['LOG_ROCKET_ID']) {
        middlewares.push(window['LogRocket'].reduxMiddleware())
    }

    const devTools = typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? true : false

    try {
        store = configureStore({
            reducer: getReducer(preloadedState),
            middleware: () => middlewares,
            devTools,
            preloadedState,
        })
        sagaMiddleware.run(rootSaga)
    } catch (err) {
        store = configureStore({
            reducer: getReducer(preloadedState),
            middleware: () => middlewares,
            devTools,
            preloadedState,
        })
    }

    win.store = store
    callback(store)
    return store
}

export const getStore = (): EnhancedStore => store
export const getState = (): any => store.getState()
export const setStore = (_store: EnhancedStore): void => {
    store = _store
}

const localStore: any = {}

/* USeed by navigation svc, candadet for removal */
export const getFromLocalStore = (key): any => localStore[key]
export const setInLocalStore = (key, val): any => {
    localStore[key] = val
}

export default getStore

/**
 * Flag keeping track of if new module has been loaded
 */
let pendingModules: boolean = false

/**
 * Used by module loader to signal that a new module has been loaded. This information is relevent when
 * deciding whether or not to replace the reducers.
 */
export const newModuleLoaded = (): void => {
    pendingModules = true
}

/**
 * Used by module loader
 */
export const registerModule = (name, _module) => {
    regModule(name, _module)
    newModuleLoaded()
}

/**
 * Called at every triggered action. If there are any modules that have been loaded but has not yet have
 * had their reducers applied do it at this point. There were differnt ways to do this but this is the
 * solution that finally worked in arobust way.
 */
export const triggerReducerReplacement = (type) => {
    if (pendingModules && store && dynLoading) {
        Logger.debug('replacing reducer (REPRED) triggered by', type)
        store.replaceReducer(getReducer())
        pendingModules = false
    }
}
