import { ActionTypes, NewsletterSubscriptionStatus, RequestPasswordStatus } from './constants'
import { Action } from '../interfaces'
import { AppStore, SessionConfig, SetConfigPayload, CustomerTypeWarningDialog } from './interfaces'

const getWindowSessionConfig = (): SessionConfig => {
    const documentSessionConfig: any = window?.['sessionConf'] || {}
    Object.keys(documentSessionConfig).forEach((key: string) => {
        const value: any = documentSessionConfig[key]
        if (value === 'true') {
            documentSessionConfig[key] = true
        }
        if (value === 'false') {
            documentSessionConfig[key] = false
        }
    })
    return documentSessionConfig
}

export const INITIAL_STATE: AppStore = {
    promotions: {},
    viewedProductDetails: {},
    productInformationExpanded: true,
    productSpecificationExpanded: true,
    purchaseQuantity: {},
    previousIdentifiedGoogleAnalyticsLabel: undefined,
    sessionConfig: getWindowSessionConfig(),
    updatingSessionConfig: false,
    customerTypeWarningDialog: {
        showDialog: false,
        messageText: '',
        messageTitle: '',
        popupTitle: '',
        cancelText: '',
        logoutText: '',
        targetCountryUrl: '',
    },
    collapsablePanelsExpanded: {},
    personalizedDeliveryOffers: {
        city: '',
        transporters: [],
        hasError: false,
    },
    accountProfile: {
        accessCode: '',
        b2BCustomer: false,
        birthDate: '',
        careOf: '',
        cellphone: '',
        companyContactPerson: '',
        companyName: '',
        companyType: '',
        country: '',
        customerId: '',
        email: '',
        firstName: '',
        invoiceEmail: '',
        lastName: '',
        line1: '',
        line2: '',
        organizationNumber: '',
        personalIdentityNumber: '',
        phone: '',
        postcode: '',
        separateInvoiceEmail: false,
        showBirthDate: false,
        townCity: '',
    },
    subscriptionStatus: NewsletterSubscriptionStatus.NOT_SUBSCRIBED,
    requestPasswordStatus: {
        status: RequestPasswordStatus.NO_REQUEST_PASSWORD,
        errors: [],
        success: false,
    },
    loginErrorMessage: undefined,
    loggingIn: false,
    refreshingSitemap: false,
    orderHistory: undefined,
    fetchingOrderHistory: false,
    orders: {},
    fetchingOrderCode: {},
    fetchingPersonalizedDeliveryOffers: false,
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): AppStore => {
    switch (type) {
        case ActionTypes.FETCHED_PROMOTIONS: {
            return {
                ...state,
                promotions: payload.promotions,
            }
        }
        case ActionTypes.SET_LOGGING_IN:
            return {
                ...state,
                loggingIn: payload.loggingIn,
            }
        case ActionTypes.SET_LOG_IN_ERROR_MESSAGE:
            return {
                ...state,
                loginErrorMessage: payload.loginErrorMessage,
            }
        case ActionTypes.SET_CONFIG: {
            const { sessionConfig, googleAnalyticsLabel }: SetConfigPayload = payload
            const toSet: any = {
                ...state.sessionConfig,
                ...sessionConfig,
            }
            const previousIdentifiedGoogleAnalyticsLabel: number =
                googleAnalyticsLabel || state.previousIdentifiedGoogleAnalyticsLabel
            return {
                ...state,
                sessionConfig: toSet,
                previousIdentifiedGoogleAnalyticsLabel,
            }
        }
        case ActionTypes.SET_UPDATING_SESSION_CONFIG:
            return {
                ...state,
                updatingSessionConfig: payload.value,
            }
        case ActionTypes.REFRESHING_SITEMAP:
            return {
                ...state,
                refreshingSitemap: payload.refreshing,
            }
        case ActionTypes.PRODUCT_DETAILS_VIEWED:
            return {
                ...state,
                viewedProductDetails: {
                    ...state.viewedProductDetails,
                    [payload.productCode]: true,
                },
            }
        case ActionTypes.PRODUCT_INFORMATION_EXPANDED:
            return {
                ...state,
                productInformationExpanded: payload.expanded,
            }
        case ActionTypes.FETCHED_ORDER:
            const { order } = payload
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [order.code]: order,
                },
            }
        case ActionTypes.SET_FETCHING_ORDER:
            return {
                ...state,
                fetchingOrderCode: {
                    ...state.fetchingOrderCode,
                    [payload.orderCode]: payload.fetching,
                },
            }
        case ActionTypes.FETCHED_ORDER_HISTORY:
            return {
                ...state,
                orderHistory: payload.orderHistory,
            }
        case ActionTypes.SET_FETCHING_ORDER_HISTORY:
            return {
                ...state,
                fetchingOrderHistory: payload.fetching,
            }
        case ActionTypes.PRODUCT_SPECIFICATION_EXPANDED:
            return {
                ...state,
                productSpecificationExpanded: payload.expanded,
            }
        case ActionTypes.SET_PURCHASE_QUANTITY:
            return {
                ...state,
                purchaseQuantity: {
                    ...state.purchaseQuantity,
                    [payload.productCode]: payload.quantity,
                },
            }
        case ActionTypes.SET_CUSTOMER_TYPE_CHANGE_WARNING: {
            const {
                showDialog,
                messageText,
                messageTitle,
                popupTitle,
                cancelText,
                logoutText,
                targetCountryUrl,
            }: CustomerTypeWarningDialog = payload

            return {
                ...state,
                customerTypeWarningDialog: {
                    showDialog,
                    messageText,
                    messageTitle,
                    popupTitle,
                    cancelText,
                    logoutText,
                    targetCountryUrl,
                },
            }
        }
        case ActionTypes.SET_COLLAPSABLE_PANEL_EXPANDED: {
            const { collapsablePanelId, expanded } = payload
            return {
                ...state,
                collapsablePanelsExpanded: {
                    ...state.collapsablePanelsExpanded,
                    [collapsablePanelId]: expanded,
                },
            }
        }
        case ActionTypes.SET_FETCHING_PERSONALIZED_DELIVERY_OPTIONS:
            const { fetching } = payload
            return {
                ...state,
                fetchingPersonalizedDeliveryOffers: fetching,
            }
        case ActionTypes.FETCHED_PERSONALIZED_DELIVERY_OPTIONS:
            const { city, transporters } = payload
            if (city || !state?.personalizedDeliveryOffers?.transporters?.length) {
                return {
                    ...state,
                    personalizedDeliveryOffers: {
                        city,
                        transporters,
                        hasError: false,
                    },
                }
            } else {
                return {
                    ...state,
                    personalizedDeliveryOffers: {
                        ...state.personalizedDeliveryOffers,
                        hasError: true,
                    },
                }
            }
        case ActionTypes.FETCHED_ACCOUNT_PROFILE:
            return {
                ...state,
                accountProfile: payload,
            }
        case ActionTypes.SUBSCRIBE_TO_NEWSLETTER_RESULT:
            return {
                ...state,
                subscriptionStatus: payload.subscriptionStatus,
            }
        case ActionTypes.RESET_SUBSCRIBE_TO_NEWSLETTER_FORM:
            return {
                ...state,
                subscriptionStatus: INITIAL_STATE.subscriptionStatus,
            }
        case ActionTypes.REQUEST_PASSWORD_RESULT:
            return {
                ...state,
                requestPasswordStatus: {
                    errors: payload.errors,
                    success: payload.success,
                    status: payload.requestPasswordStatus,
                },
            }
        case ActionTypes.RESET_REQUEST_PASSWORD_FORM:
            return {
                ...state,
                requestPasswordStatus: INITIAL_STATE.requestPasswordStatus,
            }
        default:
            return state
    }
}
