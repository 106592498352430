const COOKIEBOT_SCRIPT_ID: string = 'Cookiebot'
const DIRECTIVE_COOKIE_MANAGEMENT_PLATFORM: string = 'DIRECTIVE_CMP'

enum CookiebotCallback {
    ON_ACCEPT = 'CookiebotCallback_OnAccept',
    ON_DECLINE = 'CookiebotCallback_OnDecline',
    ON_LOAD = 'CookiebotCallback_OnLoad',
}

export interface CookiebotConsent {
    marketing: boolean
    method: 'implied' | 'explicit'
    necessary: boolean
    preferences: boolean
    stamp: string
    statistics: boolean
}

interface CookieBotConstructor {
    onLoadedCallback?: Function
    onConsentChangeCallback?: (consent: CookiebotConsent) => void
}

export class CookiebotUtil {
    constructor(init: CookieBotConstructor = undefined) {
        this.onLoadedCallback = init?.onLoadedCallback
        this.onConsentChangeCallback = init?.onConsentChangeCallback
    }

    private firstCall: boolean = true
    private onLoadedCallback: Function
    private onConsentChangeCallback: (consent: CookiebotConsent) => void

    private updateConsentSettings(): void {
        const consent: CookiebotConsent = window?.[COOKIEBOT_SCRIPT_ID]?.consent
        if (!consent) return
        this.onConsentChangeCallback?.(consent)
    }

    private getConsentFromCookiebotInstance(): CookiebotConsent {
        const consent: CookiebotConsent = window?.[COOKIEBOT_SCRIPT_ID]?.consent
        if (consent && !this.firstCall) return consent
        if (this.firstCall) {
            this.firstCall = false
            this.updateConsentSettings()
            this.setCookiebotCallbacks()
            return consent
        }
    }

    private setCookiebotCallbacks() {
        window[CookiebotCallback.ON_ACCEPT] = () => this.updateConsentSettings()
        window[CookiebotCallback.ON_DECLINE] = () => this.updateConsentSettings()
        window[CookiebotCallback.ON_LOAD] = () => {
            this.onLoadedCallback?.()
        }
    }

    get hasStatisticsConsent(): boolean {
        if (__DEV__) return true
        if (!window) return false
        if (window?.[DIRECTIVE_COOKIE_MANAGEMENT_PLATFORM] === COOKIEBOT_SCRIPT_ID) {
            const consent: CookiebotConsent = this.getConsentFromCookiebotInstance()
            return consent?.statistics === true
        }
        return false
    }

    get cookiebotIsLoaded(): boolean {
        return window?.[COOKIEBOT_SCRIPT_ID]?.consent !== undefined
    }
}
