export const NAME: string = 'PageTitle'

export type PageTitleTheme = {
    bg?: string
    fg?: string
    showLogoutIcon?: boolean
    combineSections?: boolean
    noBorder?: boolean
    bottomBorderColor?: string
}
