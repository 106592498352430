import React, { ChangeEvent, MouseEventHandler } from 'react'
import classnames from 'classnames'
import Icon, { Icons } from '@ui-elem/Icon/Icon'

interface Props {
    label: string
    className?: string
    name: string
    value: string
    option: string
    disabled?: boolean
    onClick?: Function
    onChange?: (e: ChangeEvent<HTMLInputElement>) => any
}

const Select = ({ label, className = '', name, value, option, disabled = false, onClick = () => {} }: Props) => {
    let clickHandler: MouseEventHandler<HTMLInputElement>
    let changeHandler: (e: ChangeEvent<HTMLInputElement>) => any

    if (disabled) {
        clickHandler = () => {}
    } else {
        clickHandler = () => onClick(option)
        changeHandler = () => onClick(option)
    }

    return (
        <div
            className={classnames(
                'e2e-ver-radio flex flex-row cursor-pointer items-center',
                disabled ? 'cursor-not-allowed text-slate-300' : '',
            )}
            onClick={clickHandler}
        >
            <input
                type={'radio'}
                name={name}
                className="appearance-none flex items-center"
                checked={option === value}
                onClick={clickHandler}
                onChange={changeHandler}
            />
            {option === value ? (
                <Icon className="flex m-y-auto pr-1" icon={Icons.RegCircleDot} />
            ) : (
                <Icon className="flex m-y-auto pr-1" icon={Icons.RegCircle} />
            )}
            <label className={className}>{label}</label>
        </div>
    )
}

export default Select
