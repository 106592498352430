export const NAME = 'customerFeedback'

export const DEFAULT_CUSTOMER_FEEDBACK_LIMIT: number = 25

export const ActionTypes = {
    FETCH_CUSTOMER_FEEDBACK: `${NAME}/FETCH_CUSTOMER_FEEDBACK`,
    FETCHED_CUSTOMER_FEEDBACK: `${NAME}/FETCHED_CUSTOMER_FEEDBACK`,
    POST_CUSTOMER_FEEDBACK: `${NAME}/POST_CUSTOMER_FEEDBACK`,
    POSTED_CUSTOMER_FEEDBACK: `${NAME}/POSTED_CUSTOMER_FEEDBACK`,
}

export enum CustomerFeedbackPostStatus {
    REQUESTED = 'REQUESTED',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
    WAITING = 'WAITING',
}
