export const NAME: string = 'Footer'

export type FooterTheme = {
    showNewsLetter?: boolean
    showSocialMediaLinks?: boolean
    showSocialMediaInCountry?: CountryCode[]
    reverseContentOrder?: boolean
}

export enum CountryCode {
    SE = 'se',
}
