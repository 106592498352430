import { CurrencyIsoLocales, Locales as LocalesInterface, PriceFormats as PriceFormatsInterface } from '../interfaces'

export enum PriceFormat {
    LONG = 'priceFormatLong',
    SHORT = 'priceFormatShort',
    DECIMAL = 'priceFormatDecimal',
    NO_CURRENCY = 'priceFormatNoCurrency',
}

export enum Locales {
    DA = 'da',
    SV = 'sv',
    NO = 'no',
    DE = 'de',
    DE_AT = 'de_AT',
    DE_CH = 'de_CH',
    FR_CH = 'fr_CH',
    FR = 'fr',
    FR_BE = 'fr_BE',
    NL_BE = 'nl_BE',
    NL = 'nl',
    FI = 'fi',
    SV_FI = 'sv_FI',
    IT = 'it',
    ES = 'es',
    EN_IE = 'en_IE',
    EN = 'en',
}

export const IsoLocales: LocalesInterface = {
    [Locales.DA]: 'da-DK',
    [Locales.SV]: 'se-SV',
    [Locales.NO]: 'nb-NO',
    [Locales.DE]: 'de-DE',
    [Locales.DE_AT]: 'de-AT',
    [Locales.DE_CH]: 'de-CH',
    [Locales.FR_CH]: 'fr-CH',
    [Locales.FR]: 'fr-FR',
    [Locales.FR_BE]: 'fr-BE',
    [Locales.NL_BE]: 'nl-BE',
    [Locales.NL]: 'nl-NL',
    [Locales.FI]: 'fi-FI',
    [Locales.SV_FI]: 'sv-FI',
    [Locales.IT]: 'it-IT',
    [Locales.ES]: 'es-ES',
    [Locales.EN_IE]: 'en-IE',
    [Locales.EN]: 'en-GB',
}

/**
 * Map in case we want to use another countrys currency format.
 * Sweden uses Norway's for instance so we can display:
 * 1 234,56 kr instead of the swedish standard 1,234.56 kr
 */
export const CurrencyIsoLocalesMap: CurrencyIsoLocales = {
    [Locales.SV]: 'nb-NO',
}

export const CurrencyCodes: LocalesInterface = {
    [Locales.DA]: 'DKK',
    [Locales.SV]: 'SEK',
    [Locales.NO]: 'NOK',
    [Locales.DE]: 'EUR',
    [Locales.DE_AT]: 'EUR',
    [Locales.DE_CH]: 'CHF',
    [Locales.FR_CH]: 'CHF',
    [Locales.FR]: 'EUR',
    [Locales.FR_BE]: 'EUR',
    [Locales.NL_BE]: 'EUR',
    [Locales.NL]: 'EUR',
    [Locales.FI]: 'EUR',
    [Locales.SV_FI]: 'EUR',
    [Locales.IT]: 'EUR',
    [Locales.ES]: 'EUR',
    [Locales.EN_IE]: 'EUR',
    [Locales.EN]: 'STG',
}

export const PriceFormats: PriceFormatsInterface = {
    [Locales.DA]: {
        [PriceFormat.LONG]: '{val}',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val}',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.SV]: {
        [PriceFormat.LONG]: '{val} kr',
        [PriceFormat.SHORT]: '{val}:-',
        [PriceFormat.DECIMAL]: '{val} kr',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.NO]: {
        [PriceFormat.LONG]: '{val},-',
        [PriceFormat.SHORT]: '{val},-',
        [PriceFormat.DECIMAL]: '{val} kr',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.DE]: {
        [PriceFormat.LONG]: '{val} €',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val} €',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.DE_AT]: {
        [PriceFormat.LONG]: '{val} €',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val} €',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.DE_CH]: {
        [PriceFormat.LONG]: '{val} CHF',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val} CHF',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.FR_CH]: {
        [PriceFormat.LONG]: '{val} CHF',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val} CHF',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.FR]: {
        [PriceFormat.LONG]: '{val} €',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val} €',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.FR_BE]: {
        [PriceFormat.LONG]: '{val} €',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val} €',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.NL_BE]: {
        [PriceFormat.LONG]: '{val} €',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val} €',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.NL]: {
        [PriceFormat.LONG]: '€ {val}',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val}',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.FI]: {
        [PriceFormat.LONG]: '{val}€',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val}€',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.SV_FI]: {
        [PriceFormat.LONG]: '{val}€',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val}€',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.IT]: {
        [PriceFormat.LONG]: 'EUR {val}',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val}',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.ES]: {
        [PriceFormat.LONG]: 'EUR {val}',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val}',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.EN_IE]: {
        [PriceFormat.LONG]: '€{val}',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val}',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
    [Locales.EN]: {
        [PriceFormat.LONG]: '£{val}',
        [PriceFormat.SHORT]: '{val}',
        [PriceFormat.DECIMAL]: '{val}',
        [PriceFormat.NO_CURRENCY]: '{val}',
    },
}
