import browserSvc from 'score/browserSvc';

const isSmarteditContainerCompPresent = document.getElementsByTagName('smartedit-component');

export const isSmartEditContainerPresent = () => {
  return isSmarteditContainerCompPresent && isSmarteditContainerCompPresent.length > 0;
};

export const updateCMSTicketIdInSessionStorage = () => {
  if (location.search) {
    const queryString = new URLSearchParams(location.search);
    const ticketId = queryString.get('cmsTicketId');
    if (ticketId) {
      sessionStorage.setItem('cmsTicketId', ticketId);
    }
  }
};

export const updatePageContextWithLanguage = (lang) => {
  const catalogDescriptor = JSON.parse(browserSvc.localStorageGet('smarteditCatalogDescriptor') || null);
  const smarteditPreviewUrl = browserSvc.localStorageGet('smarteditPreviewUrl');
  if (
    smarteditPreviewUrl &&
    catalogDescriptor &&
    catalogDescriptor.catalogId &&
    catalogDescriptor.catalogVersion &&
    catalogDescriptor.siteId
  ) {
    let pkPart1 = Math.random().toFixed(14).split('.')[1];
    let pkPart2 = Math.random().toFixed(16).split('.')[1];
    const event = {
      pk: '' + pkPart1 + '.' + pkPart2 + '',
      gatewayId: 'experienceService',
      eventId: 'loadExperience',
      data: {
        arguments: [
          {
            siteId: catalogDescriptor.siteId,
            catalogId: catalogDescriptor.catalogId,
            catalogVersion: catalogDescriptor.catalogVersion,
            language: lang,
          },
        ],
      },
      triggeredBy: 'SPA-Frontend',
    };
    parent.postMessage(event, smarteditPreviewUrl);
  }
  sessionStorage.setItem('fireLanguageChangeEvent', false);
};

export const getCmsTicketId = () => {
  return sessionStorage.getItem('cmsTicketId');
};

export default isSmartEditContainerPresent;
