import {
    IcBrowserLogger,
    LogLevel,
    LogMethod,
    IcBrowserLoggerLoggingMethods,
    LogTarget,
} from '@ic/ic-common/dist/browser-logger/browser-logger'
import browserSvc from '@spa-core-js/services/browserSvc'
import net from '@spa-core-js/services/networkSvc'

interface LoggingMethods extends IcBrowserLoggerLoggingMethods {
    logUrl: (id: string, value: string, id2?: string, value2?: string) => void
}

interface LogMap {
    [levelOrMethod: number | string]: LogMethod
}

interface FrontEndReportPayload {
    id2: string
    jsessionid: string
    hasessionid: string
    logtype: LogMethod
    logmessage: string
    stacktrace: string
}

export const LogLevelToMethodMap: LogMap = {
    [LogLevel.NONE]: LogMethod.NONE,
    [LogLevel.ERROR]: LogMethod.ERROR,
    [LogLevel.WARN]: LogMethod.WARN,
    [LogLevel.INFO]: LogMethod.INFO,
    [LogLevel.TRACE]: LogMethod.TRACE,
    [LogLevel.LOG]: LogMethod.LOG,
    [LogLevel.DEBUG]: LogMethod.DEBUG,
}

export interface LoggerConfig {
    urlPrefix?: string
    contextPath?: string
    siteName?: string
    csrfToken?: string
}

export interface LogLevels {
    consoleLogLevel: LogLevel
    httpLogLevel: LogLevel
}

const CONSOLE_LOG_LEVEL_KEY: string = 'console-log-level'
const HTTP_LOG_LEVEL_KEY: string = 'http-log-level'
const VALID_HTTP_LOGGING_TYPES: string[] = ['number', 'string']

class LoggerClass extends IcBrowserLogger {
    private _contextPath: string = window?.['sessionConf']?.contextPath
    private _siteName: string = window?.['sessionConf']?.siteName

    constructor() {
        super()
        const consoleLogLevel: LogLevel = browserSvc.cookieGet(CONSOLE_LOG_LEVEL_KEY)
            ? parseInt(browserSvc.cookieGet(CONSOLE_LOG_LEVEL_KEY))
            : LogLevel.ERROR
        const httpLogLevel: LogLevel = browserSvc.cookieGet(HTTP_LOG_LEVEL_KEY)
            ? parseInt(browserSvc.cookieGet(HTTP_LOG_LEVEL_KEY))
            : LogLevel.ERROR
        this.consoleLogLevel = consoleLogLevel
        this.httpLogLevel = httpLogLevel
    }

    public logUrl(id: string, value: string, id2: string = undefined, value2: string = undefined): void {
        const ID2: string = browserSvc.cookieGet('ID2')
        const JSESSIONID: string = browserSvc.cookieGet('JSESSIONID')
        /**
         * Do not change to another gif like log.gif,
         * this makes this request vulnerable to the adblockers
         */
        const url: string = `${
            this._contextPath
        }/_ui/dist/images/lightbox-blank.gif?${id}=${value}&JSESSIONID=${JSESSIONID}&ID2=${ID2}&siteId=${this._siteName}${
            id2 ? '&' + id2 + '=' + value2 : ''
        }`
        net.get(url).catch(() => {
            /**
             * Pixel doesn't exist
             */
        })
    }

    private getHttpLoggingPayload(logLevel: LogLevel, args: any[]): any {
        const validArgs: any[] = []
        let logmessage: string = ''
        args?.forEach((arg: any) => {
            if (arg?.message) {
                logmessage = arg.message
            }
            if (VALID_HTTP_LOGGING_TYPES.includes(typeof arg)) {
                validArgs.push(arg)
            }
        })
        const payload: FrontEndReportPayload = {
            id2: browserSvc.cookieGet('ID2'),
            jsessionid: browserSvc.cookieGet('JSESSIONID'),
            hasessionid: browserSvc.cookieGet('hasessionid'),
            logtype: LogLevelToMethodMap[logLevel],
            logmessage,
            stacktrace: JSON.stringify(validArgs),
        }
        return {
            [LogTarget.HTTP]: payload,
        }
    }

    public override error(...args: any) {
        super.error(this.getHttpLoggingPayload(LogLevel.ERROR, args), ...args)
    }

    public override warn(...args: any) {
        super.warn(this.getHttpLoggingPayload(LogLevel.WARN, args), ...args)
    }

    public override info(...args: any) {
        super.info(this.getHttpLoggingPayload(LogLevel.INFO, args), ...args)
    }

    public override trace(...args: any) {
        super.trace(this.getHttpLoggingPayload(LogLevel.TRACE, args), ...args)
    }

    public override log(...args: any) {
        super.log(this.getHttpLoggingPayload(LogLevel.LOG, args), ...args)
    }

    public override debug(...args: any) {
        super.debug(this.getHttpLoggingPayload(LogLevel.DEBUG, args), ...args)
    }

    public getLogLevels(): LogLevels {
        return {
            consoleLogLevel: this.consoleLogLevel,
            httpLogLevel: this.httpLogLevel,
        }
    }

    public configLogger(config: LoggerConfig) {
        const { urlPrefix, contextPath, siteName, csrfToken } = config
        if (contextPath) {
            this._contextPath = contextPath
        }
        if (contextPath) {
            this._siteName = siteName
        }
        if (urlPrefix && csrfToken) {
            this.httpLogging = {
                host: `${urlPrefix}/rest/v1/feReporting/report`,
                headers: {
                    CSRFToken: csrfToken,
                },
            }
        }
    }
}

const loggerInstance: LoggerClass = new LoggerClass()
export const Logger: LoggingMethods = loggerInstance
export const setConsoleLogLevel = (logLevel: LogLevel) => {
    browserSvc.cookieSet(CONSOLE_LOG_LEVEL_KEY, logLevel)
    loggerInstance.consoleLogLevel = logLevel
}
export const setHttpLogLevel = (logLevel: LogLevel) => {
    browserSvc.cookieSet(HTTP_LOG_LEVEL_KEY, logLevel)
    loggerInstance.httpLogLevel = logLevel
}
export const getLogLevels = (): LogLevels => loggerInstance.getLogLevels()
export const configLogger = (config: LoggerConfig) => loggerInstance.configLogger(config)
export { LogLevel, LogTarget, LogMethod }
