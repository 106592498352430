import { Action } from '../interfaces'
import { ActionTypes } from './constants'
import { SaveSearchResultPayload, SearchStore } from './interfaces'

export const INITIAL_STATE: SearchStore = {
    latestSearchText: undefined,
    modelSearchResults: [],
    productSearchResultProductCodes: [],
    loading: false,
    currentPage: 0,
    pagination: undefined,
    filters: undefined,
    searched: false,
    autocompleteProducts: [],
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): SearchStore => {
    switch (type) {
        case ActionTypes.SET_LOADING:
            return {
                ...state,
                loading: payload.loading,
            }
        case ActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                latestSearchText: undefined,
                modelSearchResults: [],
                productSearchResultProductCodes: [],
                searched: false,
            }
        case ActionTypes.ADD_TO_SEARCH_RESULTS: {
            const { modelSearchResults, productSearchResultProductCodes, pagination, filters }: SaveSearchResultPayload = payload
            return {
                ...state,
                modelSearchResults: modelSearchResults || [],
                productSearchResultProductCodes: [
                    ...state.productSearchResultProductCodes,
                    ...(productSearchResultProductCodes || []),
                ],
                pagination,
                filters,
                searched: true,
            }
        }
        case ActionTypes.SAVE_SEARCH_RESULT: {
            const {
                modelSearchResults,
                productSearchResultProductCodes,
                pagination,
                filters,
                searchText,
            }: SaveSearchResultPayload = payload
            return {
                ...state,
                latestSearchText: searchText,
                modelSearchResults: modelSearchResults || [],
                productSearchResultProductCodes: productSearchResultProductCodes || [],
                pagination,
                filters,
                searched: true,
            }
        }
        case ActionTypes.SAVE_AUTOCOMPLETE_SEARCH_RESULT:
            return {
                ...state,
                autocompleteProducts: payload.autocompleteProducts,
            }
        case ActionTypes.CLEAR_AUTOCOMPLETE_SEARCH:
            return {
                ...state,
                autocompleteProducts: [],
            }
        default:
            return state
    }
}
