export const NAME: string = 'CartTable'

export type CartTableTheme = {
    headerColor?: string
    showPerPiecePriceTitle?: boolean
    showProductDetails?: boolean
    showCartPriceTitle?: boolean
    showPerKronaInCartTable?: boolean
    showProductUnit?: boolean
}
