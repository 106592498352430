import { takeLatest, put, call, select } from 'redux-saga/effects'
import net from '@spa-core-js/services/networkSvc'
import { ActionTypes } from './constants'
import { SessionConfig } from '../app/interfaces'
import { selectSessionConfig } from '../utils'
import { Logger } from '@spa-core/logger'

export function* fetchHistoricalLowPrice({ payload }: any) {
    const { productCode } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = sessionConfig.urlPrefix + `/price/lowest/${productCode}`
    try {
        const results = yield call(() => net.get(url, { cache: 'none ' }))
        yield put({
            type: ActionTypes.FETCHED_HISTORICAL_LOW_PRICE,
            payload: {
                historicalLowPrice: results,
                productCode,
            },
        })
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export const watchers = [takeLatest(ActionTypes.FETCH_HISTORICAL_LOW_PRICE, fetchHistoricalLowPrice)]
