import { AxiosResponse } from 'axios'
import { getMany } from '../../services/net'

export const preloadExternalJs = async (siteName: string): Promise<AxiosResponse[] | void> => {
    const EXCLUDE_JS_FILES_WILDCARDS: string[] = [`${siteName.toLowerCase()}-app/bundle.js`]
    const scriptElements: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName('script')
    const preloadJsFilePaths: string[] = []
    for (let i: number = 0; i < scriptElements.length; i++) {
        if (
            scriptElements[i]?.src?.match(/.*\.js/) &&
            !EXCLUDE_JS_FILES_WILDCARDS.some((wildcard: string) => scriptElements[i]?.src?.includes(wildcard))
        ) {
            preloadJsFilePaths.push(scriptElements[i]?.src)
        }
    }
    return getMany(preloadJsFilePaths)
}
