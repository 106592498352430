export const NAME = 'search'

export const ActionTypes = {
    SEARCH: `${NAME}/SEARCH`,
    SAVE_SEARCH_RESULT: `${NAME}/SAVE_SEARCH_RESULT`,
    AUTOCOMPLETE_SEARCH: `${NAME}/AUTOCOMPLETE_SEARCH`,
    SAVE_AUTOCOMPLETE_SEARCH_RESULT: `${NAME}/SAVE_AUTOCOMPLETE_SEARCH_RESULT`,
    CLEAR_AUTOCOMPLETE_SEARCH: `${NAME}/CLEAR_AUTOCOMPLETE_SEARCH`,
    ADD_TO_SEARCH_RESULTS: `${NAME}/ADD_TO_SEARCH_RESULTS`,
    CLEAR_SEARCH: `${NAME}/CLEAR_SEARCH`,
    SET_LOADING: `${NAME}/SET_LOADING`,
}

export enum SearchSortCode {
    POPULAR = 'popular',
    NAME_ASCENDING = 'name-asc',
    NAME_DESCEDING = 'name-desc',
    PRICE_ASCENDING = 'price-asc',
    PRICE_DESCENDING = 'price-desc',
}
