import { ActionTypes } from './constants'
import { ContentSlot, ContentSlotsStore, FetchedSectionIdPageIdContentSlot } from './interfaces'
import { Action } from '../interfaces'

export const INITIAL_STATE: ContentSlotsStore = {
    fetchingContentSlot: {},
    fetchingContentSlots: {},
    contentSlots: {},
    fetchedContentSlotUrls: {},
    componentContentSlots: {},
    fetchingComponentContentSlots: {},
    sectionIdPageIdContentSlots: {},
    fetchingSectionIdPageIdContentSlots: {},
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): ContentSlotsStore => {
    switch (type) {
        case ActionTypes.FETCHED_COMPONENT_CONTENT_SLOT: {
            const { componentUid, componentContentSlot } = payload
            return {
                ...state,
                componentContentSlots: {
                    ...state.componentContentSlots,
                    [componentUid]: componentContentSlot,
                },
            }
        }
        case ActionTypes.SET_FETCHING_COMPONENT_CONTENT_SLOT: {
            const { componentUid, fetching } = payload
            return {
                ...state,
                fetchingComponentContentSlots: {
                    ...state.fetchingComponentContentSlots,
                    [componentUid]: fetching,
                },
            }
        }
        case ActionTypes.FETCHED_SECTION_ID_PAGE_ID_CONTENT_SLOT: {
            const { sectionIdPageId, sectionIdPageIdContentSlot }: FetchedSectionIdPageIdContentSlot = payload
            return {
                ...state,
                sectionIdPageIdContentSlots: {
                    ...state.sectionIdPageIdContentSlots,
                    [sectionIdPageId]: sectionIdPageIdContentSlot,
                },
            }
        }
        case ActionTypes.SET_FETCHING_CONTENT_SLOT_WITH_SECTION_ID_PAGE_ID: {
            const { sectionIdPageId, fetching } = payload
            return {
                ...state,
                fetchingSectionIdPageIdContentSlots: {
                    ...state.fetchingComponentContentSlots,
                    [sectionIdPageId]: fetching,
                },
            }
        }
        case ActionTypes.FETCHED_PRODUCT_CONTENT_SLOT: {
            const { pageId, contentSlot, contentSlotKey } = payload
            return {
                ...state,
                contentSlots: {
                    ...state.contentSlots,
                    [pageId]: {
                        ...state.contentSlots?.[pageId],
                        [contentSlotKey]: contentSlot,
                    },
                },
            }
        }
        case ActionTypes.FETCHED_CONTENT_SLOTS: {
            const { pageId, contentSlots } = payload
            const contentSlotsForPageId: {
                [position: string]: ContentSlot
            } = state.contentSlots[pageId] || {}
            contentSlots.forEach((contentSlot: ContentSlot) => {
                contentSlotsForPageId[contentSlot?.position] = contentSlot
            })
            return {
                ...state,
                contentSlots: {
                    ...state.contentSlots,
                    [pageId]: contentSlotsForPageId,
                },
            }
        }
        case ActionTypes.SET_FETCHED_CONTENT_SLOT_URLS: {
            return {
                ...state,
                fetchedContentSlotUrls: {
                    ...state.fetchedContentSlotUrls,
                    [payload.url]: true,
                },
            }
        }
        case ActionTypes.SET_FETCHING_CONTENT_SLOT: {
            const { pageIdAndPosition, fetching } = payload
            return {
                ...state,
                fetchingContentSlot: {
                    ...state.fetchingContentSlot,
                    [pageIdAndPosition]: fetching,
                },
            }
        }
        case ActionTypes.SET_FETCHING_CONTENT_SLOTS: {
            const { pageId, fetching } = payload
            return {
                ...state,
                fetchingContentSlots: {
                    ...state.fetchingContentSlots,
                    [pageId]: fetching,
                },
            }
        }
        default:
            return state
    }
}
