import siteTheme from '@ic-site-theme';
import * as mixins from '@spa-core-js/mixins';
import { merge } from 'ts-deepmerge';
import defaultTheme from './default-theme';
import componentThemeOverrides from '@ic-site-theme-overrides';
import legacyComponentOverrides from '@ic-site-theme-overrides-legacy';
const mergedDefaultTheme = merge.withOptions({ mergeArrays: false }, defaultTheme, siteTheme);
export default mergedDefaultTheme;
/**
 * @deprecated Will be removed, migrate to getComponentTheme
 */
export const getTheme = (componentName, componentProps = undefined) => {
    var _a;
    const overrides = (componentThemeOverrides === null || componentThemeOverrides === void 0 ? void 0 : componentThemeOverrides[componentName]) || ((_a = legacyComponentOverrides === null || legacyComponentOverrides === void 0 ? void 0 : legacyComponentOverrides[componentName]) === null || _a === void 0 ? void 0 : _a.call(legacyComponentOverrides, mergedDefaultTheme)) || {};
    return merge.withOptions({ mergeArrays: false }, mergedDefaultTheme, componentProps || {}, overrides);
};
const themeFactoryGenerator = (componentName, defaultComponentTheme) => {
    var _a;
    const mergedComponentTheme = merge.withOptions({ mergeArrays: false }, defaultComponentTheme || {}, (componentThemeOverrides === null || componentThemeOverrides === void 0 ? void 0 : componentThemeOverrides[componentName]) || ((_a = legacyComponentOverrides[componentName]) === null || _a === void 0 ? void 0 : _a.call(legacyComponentOverrides, mergedDefaultTheme)) || {});
    const generatedTheme = merge.withOptions({ mergeArrays: false }, mergedComponentTheme, mergedDefaultTheme);
    mixins.setTheme(generatedTheme);
    return () => generatedTheme;
};
/**
 * @deprecated Will be removed, migrate to getComponentTheme
 */
export const getThemeFactory = (componentName, componentProps) => themeFactoryGenerator(componentName, componentProps);
export const getComponentTheme = (componentName = undefined, defaultComponentTheme = undefined) => themeFactoryGenerator(componentName, defaultComponentTheme)();
