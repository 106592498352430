import cloneDeep from 'fast-clone'
import sortedUniqBy from 'lodash/sortedUniqBy'
import { ActionTypes, LOGOUT_SUCCESS_MESSAGE_ID } from './constants'
import { AddMessage, AddMessages, GlobalMessagesStore, Message } from './interfaces'
import { Action } from '../interfaces'

export const INITIAL_STATE: GlobalMessagesStore = {
    messages: [],
}

const createMessage = (payload: AddMessage, currentMessageIds: (number | string)[]): Message => {
    const { title, message: payloadMessage, level, id, displaySeconds, statusCode }: AddMessage = payload
    const messageId: number | string = id || new Date().getTime()
    const existingMessage: boolean = currentMessageIds.find((messageId) => messageId === payload.id) !== undefined
    if (!existingMessage) {
        const message: Message = {
            id: messageId,
            title,
            message: payloadMessage,
            level,
            statusCode,
            displaySeconds,
        }
        return message
    }
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): GlobalMessagesStore => {
    switch (type) {
        case ActionTypes.ADD_MESSAGE: {
            const message: Message = createMessage(
                payload,
                state.messages.map(({ id }) => id),
            )
            if (message) {
                let messagesFromState: Message[] = cloneDeep(state.messages)
                messagesFromState.push(message)
                messagesFromState = sortedUniqBy(messagesFromState, 'id')

                return {
                    ...state,
                    messages: messagesFromState,
                }
            }
            return state
        }
        case ActionTypes.ADD_MESSAGES: {
            const { messages }: AddMessages = payload
            const messageIds: (number | string)[] = [...state.messages.map(({ id }) => id), ...messages.map(({ id }) => id)]
            const newMessages: Message[] = messages.map((messagePayload: AddMessage) => createMessage(messagePayload, messageIds))
            if (newMessages.length) {
                let messagesFromState: Message[] = cloneDeep(state.messages)
                messagesFromState.push(...newMessages)
                messagesFromState = sortedUniqBy(messagesFromState, 'id')

                return {
                    ...state,
                    messages: messagesFromState,
                }
            }
            return state
        }
        case 'PAGE_VIEW': {
            /**
             * Clear messages (except logout message) when switching page
             */
            const messages: Message[] = state.messages.filter(({ id }) => id === LOGOUT_SUCCESS_MESSAGE_ID)
            return {
                ...state,
                messages,
            }
        }
        case ActionTypes.REMOVE_MESSAGE: {
            const messagesFromState: Message[] = cloneDeep(state.messages)
            const messages: Message[] = messagesFromState.filter(({ id: messageId }) => messageId !== payload.id)
            return {
                ...state,
                messages,
            }
        }
        case ActionTypes.REMOVE_ALL_MESSAGES:
            return {
                ...state,
                messages: [],
            }
        default:
            return state
    }
}
