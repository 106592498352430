import React, { useEffect, useState } from 'react'
import SlideInOut from '@ui-elem-js/SlideInOut'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import classnames from 'classnames'
import { Store } from '@spa-core/store'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { setCollapsablePanelExpanded } from '@spa-core/store/app/actions'
import classNames from 'classnames'

type ComponentState = {
    collapsablePanelsExpanded: {
        [collapsablePanelId: string]: boolean
    }
}

type Props = {
    title: string | React.ReactNode
    id?: string
    centerTitle?: boolean
    expanded?: boolean
    subTitle?: React.ReactNode
    expandedsubTitle?: string | React.ReactNode
    rowTitle?: boolean
    rowTitleFluid?: boolean
    cursiveTitle?: boolean
    imagePath?: string
    miscInfo?: React.ReactNode
    children?: React.ReactNode
    onCollapse?: () => void
    onExpand?: () => void
    className?: string
}

export const CollapsablePanel: React.FC<Props> = ({
    expanded: expandedProp,
    id,
    subTitle,
    expandedsubTitle,
    imagePath,
    title,
    miscInfo = null,
    centerTitle,
    cursiveTitle = false,
    rowTitle = false,
    rowTitleFluid = false,
    children = <></>,
    onCollapse,
    onExpand,
    className,
}) => {
    const [collapsablePanelId] = useState<string>(id || `${Date.now()}_${Math.floor(Math.random() * 1000)}`)
    const collapsablePanelsExpandedStore = ({ reducers }: Store) => reducers[appReducerName].collapsablePanelsExpanded
    const selector = createSelector(
        [collapsablePanelsExpandedStore],
        (collapsablePanelsExpanded): ComponentState => ({
            collapsablePanelsExpanded,
        }),
    )
    const { collapsablePanelsExpanded }: ComponentState = useSelector(selector)

    const expanded: boolean = collapsablePanelsExpanded?.[collapsablePanelId] === true
    const dispatch = useDispatch()

    useEffect(() => {
        if (!collapsablePanelsExpanded?.[collapsablePanelId] && expandedProp !== undefined) {
            dispatch(setCollapsablePanelExpanded(collapsablePanelId, expandedProp))
        }
    }, [expandedProp])

    return (
        <>
            <div
                className={classNames('flex justify-between items-center py-2 px-1 cursor-pointer md:mb-2', className)}
                onClick={() => {
                    expanded ? onCollapse?.() : onExpand?.()
                    dispatch(setCollapsablePanelExpanded(collapsablePanelId, !expanded))
                }}
            >
                {subTitle ? (
                    <div
                        className={`${rowTitle ? 'flex-row' : ''} ${rowTitleFluid ? 'flex-col md:flex-row md:mr-2 md:w-full md:justify-between' : ''} ${!rowTitle && !rowTitleFluid ? 'flec-col' : ''}flex h-full md:items-center `}
                    >
                        {imagePath ? <img src={imagePath} /> : null}
                        <h2 className="my-0 font-bold text-base md:text-lg md:ml-2">{title}</h2>
                        {miscInfo}
                        <span className="ml-1">{expanded && expandedsubTitle ? expandedsubTitle : subTitle}</span>
                    </div>
                ) : (
                    <>
                        <div className={centerTitle ? 'w-full' : 'flex'}>
                            {imagePath ? (
                                <div className="pr-2">
                                    <img src={imagePath} />
                                </div>
                            ) : null}
                            <div className="panel-title">
                                <h2 className={classnames('my-0 md:font-bold md:text-lg', centerTitle ? 'text-center' : '')}>
                                    {title}
                                </h2>
                            </div>
                        </div>
                        {miscInfo}
                    </>
                )}
                <Icon size={22} rotate90={expanded} icon={Icons.AngleRight} />
            </div>
            <SlideInOut duration={300} isOpened={expanded}>
                <div key="fixed">{children}</div>
            </SlideInOut>
        </>
    )
}
