import { ActionTypes } from './constants'
import { AddMessage, AddMessages, RestCallFailed } from './interfaces'

export const addMessage = (payload: AddMessage) => ({
    type: ActionTypes.ADD_MESSAGE,
    payload,
})

export const addMessages = (payload: AddMessages) => ({
    type: ActionTypes.ADD_MESSAGES,
    payload,
})

export const removeMessage = (id: string | number) => ({
    type: ActionTypes.REMOVE_MESSAGE,
    payload: { id },
})

export const addRestCallFailed = (payload: RestCallFailed) => ({
    type: ActionTypes.ADD_REST_CALL_FAILED_MESSAGE,
    payload,
})

export const removeAllMessages = () => ({
    type: ActionTypes.REMOVE_ALL_MESSAGES,
})

export const fetchMessageFromServer = (messageKey: string) => ({
    type: ActionTypes.FETCH_MESSAGE_FROM_SERVER,
    payload: { messageKey },
})

export const fetchAllMessageFromServer = () => ({
    type: ActionTypes.FETCH_ALL_MESSAGES_FROM_SERVER,
})
