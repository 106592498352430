import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Button from '@ui-elem/Button/Button';
import Icon from '@ui-elem/Icon/Icon';
import { t } from '@spa-core/locale';
import { getThemeFactory } from '@ic-theme';
import ReactDOM from 'react-dom';
import * as cls from './Modal.styles';
import classNames from 'classnames';

const th = getThemeFactory('Modal', {});

const Basic = (p) => (
  <div className={cls['ic-ui-modal-basic']}>
    <div
      className={
        (cls['ic-ui-modal-close-top'], cls[`icon-${p.closeIconSize}`], p.closeIconPos === 'symmetric' ? cls.symmetric : '')
      }
      onClick={p.onClose}
    >
      <Icon icon={p.closeIcon} size={24} className={'mt-2 modal_close_icon_color'} />
    </div>
    {p.children}
    <div className={cls['ic-ui-modal-close-bottom']}>
      <Button fluid={true} onClick={p.onClose} buttonText={t('popup.close')} />
    </div>
  </div>
);

const Dialog = (p) => (
  <div
    className={classNames(
      cls['ic-ui-modal-dialog'],
      'ic-ui-modal-dialog',
      `ic-ui-modal-size-${p.size}`,
      'col-pale',
      cls[`ic-ui-modal-size-${p.size}`],
      p.scrollbar ? cls['ic-ui-modal-scoll'] : ''
    )}
    style={{ width: p.size === 'custom' ? p.width : '', borderRadius: `${p.radius || 0}rem` }}
    onClick={(e) => {
      e.stopPropagation();
      // p.onClose();
    }}
  >
    <div
      className={classNames(
        cls['ic-ui-modal-close-top'],
        cls[`icon-${p.closeIconSize}`],
        p.closeIconPos === 'symmetric' ? cls.symmetric : ''
      )}
      onClick={
        p.productCode
          ? () => {
              p.onClose(p.productCode);
            }
          : p.onClose
      }
    >
      <Icon icon={p.closeIcon} size={24} className={'mt-2 modal_close_icon_color'} />
    </div>
    {p.children}
  </div>
);
const modalRoot = document.querySelector('body');

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.className = 'ic-ui-modal-overlay2';
  }

  /**
   * propTypes
   * @property {string} src
   * @property {string} centered
   * @property {oneOf} iconAlignment
   */
  static get propTypes() {
    return {
      className: PropTypes.string,
      mode: PropTypes.oneOf(['basic', 'dialog']),
      children: PropTypes.node,
      open: PropTypes.bool,
      scrolbar: PropTypes.bool,
      onClose: PropTypes.func,
      size: PropTypes.oneOf(['extra-small', 'small', 'medium', 'large', 'custom']),
      padding: PropTypes.oneOf(['none', 'half', 'default', 'double']),
      scrollbar: PropTypes.bool,
      closeIconSize: PropTypes.oneOf(['inherit', 'small', 'medium', 'large']),
      closeIcon: PropTypes.string,
      closeIconColor: PropTypes.string,
      radius: PropTypes.string,
      width: PropTypes.string,
    };
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  Content() {}

  render() {
    const theme = th();
    let paddingClass;
    switch (this.props.padding) {
      case 'none':
        paddingClass = '';
        break;
      case 'half':
        paddingClass = 'ic-half-padding';
        break;
      case 'double':
        paddingClass = 'ic-double-padding';
        break;
      default:
        paddingClass = 'ic-padding';
        break;
    }

    if (this.props.open) {
      return ReactDOM.createPortal(
        <div className={classNames(cls.modal, 'ic-ui-modal', this.props.className, paddingClass)}>
          {this.props.mode === 'basic' ? <Basic {...this.props} /> : <Dialog {...this.props} />}
          <div
            className={classNames(cls['ic-ui-modal-overlay'], 'ic-ui-modal-overlay')}
            style={{ opacity: theme.opacity ? theme.opacity : '0.5' }}
          />
        </div>,
        this.el
      );
    }

    return <Fragment key="dialogClosed" />;
  }
}

Modal.Header = (p) => <div className={classNames('ic-ui-modal-header')}>{p.children}</div>;
Modal.Content = (p) => <div className={classNames(cls['ic-ui-modal-message'], 'ic-ui-modal-message')}>{p.children}</div>;
Modal.Action = (p) => <div className={classNames(cls['ic-ui-modal-action'], 'flex ic-halign-end ic-padding')}>{p.children}</div>;

Modal.defaultProps = {
  mode: 'dialog',
  open: true,
  size: 'medium',
  scrollbar: false,
  paddaing: 'default',
  closeIconSize: 'inherit',
  closeIcon: 'times',
  closeIconColor: 'secondary-inverted',
  radius: '0',
};
