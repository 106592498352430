import 'regenerator-runtime/runtime';
import { produce } from 'immer';

/* eslint-disable */
const getReducer = (actionList, initialState, name) => (state = initialState, action) => {

  const method = actionList[action.type];
  if (action.type === name + '__RESET__') {
    return initialState;
  }
  if (method) {
    const nextState = produce(state, draftState => {
      method(draftState, action, state);
    });
    return nextState;
  }

  return state;
};
const moduleToReducer = module => {
  return getReducer(module.mutations, module.state, module.name);
}

export default moduleToReducer;
