import React from 'react'
import { Helmet } from 'react-helmet'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { getReducer } from '@spa-core/legacy-adapter/utils'
import { NAME as categoriesReducerName } from '@spa-core/store/categories/constants'
import { NAME as productsReducerName } from '@spa-core/store/products/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { BrandsListCategory, Category, ModelsListCategory } from '@spa-core/store/categories/interfaces'
import { useSelector } from 'react-redux'
import { Product, ProductShelf } from '@spa-core/store/products/interfaces'

type Props = {
    title: string
    subTitle: string
    metaTitle: string
    url: string
    metaRobots: string
    keywords: string
    metaDescription: string
}

type ComponentState = {
    brandsListCategory: BrandsListCategory
    modelListCatgegory: ModelsListCategory
    product: Product
    productShelf: ProductShelf
    productCategory: Category
}

const Head: React.FC<Props> = ({
    title: titleProp,
    metaTitle,
    subTitle: subTitleProp,
    url,
    keywords,
    metaRobots,
    metaDescription,
}) => {
    const title: string = titleProp || metaTitle
    const subTitle: string = title ? `${title} | ${subTitleProp}` : subTitleProp
    const canonicalUrl: string = getCanonicalUrl(url)
    const robots: string = metaRobots || 'index, follow'

    return (
        <Helmet>
            <title>{subTitle}</title>
            <meta name="keywords" content={keywords} />
            <meta name="description" content={metaDescription} />
            <meta name="title" content={metaTitle} />
            <link rel="canonical" href={canonicalUrl} />
            <meta name="robots" content={robots} />
        </Helmet>
    )
}

const valueSelect = (prio1: string, prio2: string, prio3: string = undefined, prio4: string = undefined): string =>
    prio1 || prio2 || prio3 || prio4 || ''

const getCanonicalUrl = (url: string): string => {
    let canonicalUrl: string = (url || location.href.replace(/#.*/, ''))?.replace(/\?.*/, '')
    canonicalUrl = decodeURIComponent(canonicalUrl)
    return canonicalUrl.replace(/\+/g, 'plus')
}
const checkIfCanonicalUrlContainsPage = (pages: string, url: string): boolean => {
    if (!pages || !url) return false
    const pagesArray = pages.split(',').map((value) => value.trim())
    return pagesArray.some((value) => url.includes(value))
}

type MetaDataProps = {
    metadata?: any
    title?: string
    metaTitle?: string
    metaDescription?: string
    appendSiteName?: boolean
    url?: string
    seoData?: any
}

export const Metadata = ({
    title: titleProp,
    metaTitle,
    metaDescription: metaDescriptionProp,
    appendSiteName,
    url,
    seoData: seoDataProp,
    metadata = {},
}: MetaDataProps): React.ReactNode => {
    const sessionConfig = getReducer(appReducerName).sessionConfig
    const brandsListCategoriesStore = ({ reducers }: Store) => reducers[categoriesReducerName].brandsListCategories
    const modelsListCategoriesStore = ({ reducers }: Store) => reducers[categoriesReducerName].modelsListCategories
    const productsStore = ({ reducers }: Store) => reducers[productsReducerName].products
    const productsShelfStore = ({ reducers }: Store) => reducers[productsReducerName].productShelfs
    const productCategoriesStore = ({ reducers }: Store) => reducers[categoriesReducerName].categories
    const selector = createSelector(
        [brandsListCategoriesStore, modelsListCategoriesStore, productsStore, productsShelfStore, productCategoriesStore],
        (brandsListCategories, modelsListCategories, products, productShelfs, productCategories): ComponentState => ({
            brandsListCategory: brandsListCategories?.[Object.keys(brandsListCategories)[0]],
            modelListCatgegory: modelsListCategories?.[Object.keys(modelsListCategories)[0]],
            product: products?.[Object.keys(products)[0]],
            productShelf: productShelfs?.[Object.keys(productShelfs)[0]],
            productCategory: productCategories?.[Object.keys(productCategories)[0]],
        }),
    )
    const { brandsListCategory, modelListCatgegory, product, productShelf, productCategory }: ComponentState =
        useSelector(selector)
    let title: string = valueSelect(titleProp, metadata.metaTitle, metadata.title, metaTitle)
    if (title?.includes('undefined')) {
        title = ''
    }
    const metaDescription: string = valueSelect(metaDescriptionProp, metadata.metaDescription)
    if (appendSiteName) {
        /**
         * TODO:
         * Can this be smarter?
         * No site specific code should be in spa-ec
         */
        const siteName = sessionConfig.siteName === 'dammsugarpasar' ? 'dammsugarpasar.nu' : sessionConfig.siteName
        if (!title?.endsWith(`| ${siteName}`)) {
            title = `${title} | ${siteName}`
        }
    }
    let canonicalUrl: string = getCanonicalUrl(url)
    const checkIfUrlContainsPage: boolean =
        checkIfCanonicalUrlContainsPage(sessionConfig.b2bTrimCanonicalUrlPages, canonicalUrl) ||
        (sessionConfig.b2bStore && sessionConfig.pageType === 'HomePage')
    if (
        brandsListCategory?.trimCanonicalUrl ||
        modelListCatgegory?.trimCanonicalUrl ||
        product?.trimCanonicalUrl ||
        productShelf?.trimCanonicalUrl ||
        productCategory?.trimCanonicalUrl ||
        checkIfUrlContainsPage
    ) {
        canonicalUrl = canonicalUrl.replace('/b2b', '')
    }
    const robots: string = metadata.metaRobots ? metadata.metaRobots : 'index, follow'

    let seoData: string = ''
    if (seoDataProp) {
        try {
            seoData = JSON.stringify(seoData)
        } catch {}
    }

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="keywords" content={metadata.keywords} />
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={canonicalUrl} />
            <meta name="robots" content={robots} />
            {seoData ? (
                <script className="structured-data-list" type="application/ld+json">
                    {seoData}
                </script>
            ) : null}
        </Helmet>
    )
}

export default Head
