import React, { ChangeEvent, FocusEvent, KeyboardEvent, useState, useRef } from 'react'
import classnames from 'classnames'
import Icon, { Icons } from '@ui-elem/Icon/Icon'

const setHasValue = (value: string | boolean): boolean =>
    typeof value !== 'undefined' && value !== null && typeof value === 'string' && value.length > 0

export enum TextInputType {
    PASSWORD = 'password',
    TEXT = 'text',
}

export enum TextInputIconPosition {
    LEFT = 'left',
    RIGHT = 'right',
}

export enum TextInputAutocomplete {
    ON = 'on',
    OFF = 'off',
    NAME = 'name',
    HONORIFIC_PREFIX = 'honorific-prefix',
    GIVEN_NAME = 'given-name',
    ADDITIONAL_NAME = 'additional-name',
    FAMILY_NAME = 'family-name',
    HONORIFIC_SUFFIX = 'honorific-suffix',
    NICKNAME = 'nickname',
    EMAIL = 'email',
    USERNAME = 'username',
    NEW_PASSWORD = 'new-password',
    CURRENT_PASSWORD = 'current-password',
    ONE_TIME_CODE = 'one-time-code',
    ORGANIZATION_TITLE = 'organization-title',
    ORGANIZATION = 'organization',
    STREES_ADDRESS = 'street-address',
    SHIPPING = 'shipping',
    BILLING = 'billing',
    ADDRESS_LINE1 = 'address-line1',
    ADDRESS_LINE2 = 'address-line2',
    ADDRESS_LINE3 = 'address-line3',
    ADDRESS_LEVEL1 = 'address-level1',
    ADDRESS_LEVEL2 = 'address-level2',
    ADDRESS_LEVEL3 = 'address-level3',
    ADDRESS_LEVEL4 = 'address-level4',
    COUNTRY = 'country',
    COUNTRY_NAME = 'country-name',
    POSTAL_CODE = 'postal-code',
    CC_NAME = 'cc-name',
    CC_GIVEN_NAME = 'cc-given-name',
    CC_ADDITIONAL_NAME = 'cc-additional-name',
    CC_FAMILY_NAME = 'cc-family-name',
    CC_NUMBER = 'cc-number',
    CC_EXP = 'cc-exp',
    CC_EXP_MONTH = 'cc-exp-month',
    CC_EXP_YEAR = 'cc-exp-year',
    CC_CSC = 'cc-csc',
    CC_TYPE = 'cc-type',
    TRANSACTION_CURRENCY = 'transaction-currency',
    TRANSACTION_AMOUNT = 'transaction-amount',
    LANGUAGE = 'language',
    BDAY = 'bday',
    BDAY_DAY = 'bday-day',
    BDAY_MONTH = 'bday-month',
    BDAY_YEAR = 'bday-year',
    SEX = 'sex',
    TEL = 'tel',
    TEL_COUNTRY_CODE = 'tel-country-code',
    TEL_NATIONAL = 'tel-national',
    TEL_AREA_CODE = 'tel-area-code',
    TEL_LOCAL = 'tel-local',
    TEL_EXTENSION = 'tel-extension',
    IMPP = 'impp',
    URL = 'url',
    PHOTO = 'photo',
    WEBAUTHN = 'webauthn',
}

enum TextInputStatus {
    SUCCESS = 'success',
    ERROR = 'error',
    DISABLED = 'disabled',
}

enum Styles {
    TEXT_INPUT_ICON_COLOR = 'text_input_icon_color',
    TEXT_INPUT_CONFIG = 'text_input_config',
    TEXT_INPUT_SUCCESS_COLOR = 'text_input_success_color',
    TEXT_INPUT_ERROR_COLOR = 'text_input_error_color',
    TEXT_INPUT_DISABLED_COLOR = 'text_input_disabled_color',
}

type Props = {
    id: string
    name: string
    type?: TextInputType
    disabled?: boolean
    showBorders?: boolean
    icon?: Icons
    placeholder?: string
    errorMessage?: string
    successMessage?: string
    value?: string | boolean
    className?: string
    inputClassName?: string
    placeholderClassName?: string
    iconPosition?: TextInputIconPosition
    iconSize?: number
    formSubmitted?: boolean
    validateAfterSubmit?: boolean
    autocomplete?: TextInputAutocomplete | TextInputAutocomplete[]
    maxLength?: number
    required?: boolean
    tabIndex?: number
    onChange?: (e: ChangeEvent<HTMLInputElement>) => any
    onFocus?: (e: FocusEvent<HTMLInputElement>) => any
    onBlur?: (e: FocusEvent<HTMLInputElement>) => any
    onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void
    onEnterKeyUp?: () => void
}

const TextInput: React.FC<Props> = ({
    id,
    name,
    placeholder = '',
    showBorders = true,
    icon,
    successMessage,
    errorMessage,
    disabled,
    iconSize,
    onChange,
    onFocus,
    onBlur,
    onKeyUp,
    onEnterKeyUp,
    formSubmitted = false,
    validateAfterSubmit = true,
    iconPosition = TextInputIconPosition.LEFT,
    autocomplete,
    required,
    inputClassName,
    placeholderClassName,
    type = TextInputType.TEXT,
    className = '',
    value = '',
    maxLength = 524288, // default input maxLength
    tabIndex,
}) => {
    const [hasFocus, setHasFocus] = useState<boolean>(false)
    const hasValue = setHasValue(value) || hasFocus ? 'has-val' : ''
    const inputReference = useRef(null)

    let status: TextInputStatus
    const requiredAsterisk: string = required ? '*' : ''
    let label: string = `${placeholder}${requiredAsterisk}`
    if (((validateAfterSubmit && formSubmitted) || !validateAfterSubmit) && successMessage && setHasValue(value)) {
        status = TextInputStatus.SUCCESS
        label = successMessage
        icon = Icons.Check
    } else if (((validateAfterSubmit && formSubmitted) || !validateAfterSubmit) && errorMessage) {
        status = TextInputStatus.ERROR
        label = errorMessage
    }

    if (disabled) {
        status = TextInputStatus.DISABLED
    }

    let autocompleteValue: string = TextInputAutocomplete.ON
    if (autocomplete && (autocomplete as TextInputAutocomplete) in TextInputAutocomplete) {
        autocompleteValue = `${autocomplete}`
    } else if (autocomplete) {
        autocompleteValue = Array(autocomplete).join(' ')
    }
    const valueString: string = value ? `${value}` : ''
    const leftIconPadding: string = icon && iconPosition === TextInputIconPosition.LEFT ? 'pl-[42px]' : ''
    const rightIconPadding: string = icon && iconPosition === TextInputIconPosition.RIGHT ? 'pl-[14px]' : ''

    return (
        <div
            className={classnames(
                className,
                'relative flex',
                status ? `e2e-ver-${status}` : '',
                status === TextInputStatus.ERROR ? Styles.TEXT_INPUT_ERROR_COLOR : '',
                status === TextInputStatus.SUCCESS ? Styles.TEXT_INPUT_SUCCESS_COLOR : '',
                status === TextInputStatus.DISABLED ? Styles.TEXT_INPUT_DISABLED_COLOR : '',
            )}
        >
            {icon && iconPosition === TextInputIconPosition.LEFT ? (
                <Icon
                    onClick={() => {
                        setHasFocus(true)
                        inputReference.current.focus()
                    }}
                    className={classnames(
                        Styles.TEXT_INPUT_ICON_COLOR,
                        'inline-block absolute top-4 left-4 z-10',
                        status ? `e2e-ver-${status}` : '',
                    )}
                    icon={icon}
                    size={iconSize}
                />
            ) : null}
            <input
                ref={inputReference}
                id={id}
                name={name}
                data-testid={id}
                tabIndex={tabIndex}
                className={classnames(
                    inputClassName,
                    showBorders && hasFocus && !disabled ? 'border border-primary-fg shadow-sm shadow-primary-fg' : '',
                    showBorders && !hasFocus && !disabled ? 'border border-neutral-300' : '',
                    'w-full transform transition-all h-12 rounded-none leading-none outline-none focus:outline-none text-black',
                    leftIconPadding,
                    rightIconPadding,
                    !icon ? 'pl-[14px]' : '',
                )}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange?.(e)}
                onFocus={(e: FocusEvent<HTMLInputElement>) => {
                    setHasFocus(true)
                    onFocus?.(e)
                }}
                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                    onBlur?.(e)
                    setHasFocus(false)
                }}
                onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e?.key === 'Enter' && onEnterKeyUp) {
                        onEnterKeyUp()
                    }
                    onKeyUp?.(e)
                }}
                disabled={disabled}
                type={type}
                maxLength={maxLength}
                autoComplete={autocompleteValue}
                value={valueString}
            />
            <span
                className={classnames(
                    placeholderClassName || '',
                    hasFocus || hasValue ? '-top-0.5 my-1 text-xs font-light' : 'top-3 text-base',
                    disabled ? 'text-neutral-400' : 'text-neutral-300',
                    'transform transition-all absolute h-fit flex items-center e2e-ver-placeholder',
                    leftIconPadding,
                    rightIconPadding,
                    !icon ? 'pl-[14px]' : '',
                )}
                onClick={() => {
                    setHasFocus(true)
                    inputReference.current.focus()
                }}
            >
                {label}
            </span>
            {icon && iconPosition === TextInputIconPosition.RIGHT ? (
                <Icon
                    onClick={() => {
                        setHasFocus(true)
                        inputReference.current.focus()
                    }}
                    className={classnames(
                        Styles.TEXT_INPUT_ICON_COLOR,
                        'inline-block absolute top-4 right-4 z-10',
                        status ? `e2e-ver-${status}` : '',
                    )}
                    icon={icon}
                    size={iconSize}
                />
            ) : null}
        </div>
    )
}

export default TextInput
