let theme = {};
export const setTheme = (_theme) => {
  theme = _theme;
};

const getWidthFromEnum = (_width) => {
  let width;
  switch (_width) {
    case 'half':
      width = theme.space / 2 + 'rem';
      break;
    case 'double':
      width = theme.space * 2 + 'rem';
      break;
    default:
      width = theme.space + 'rem';
  }

  return width;
};

export const padding = (width, pos) => `
  ${pos.indexOf('t') >= 0 ? 'padding-top: ' + getWidthFromEnum(width) + ';' : ''}
  ${pos.indexOf('b') >= 0 ? 'padding-bottom: ' + getWidthFromEnum(width) + ';' : ''}
  ${pos.indexOf('l') >= 0 ? 'padding-left: ' + getWidthFromEnum(width) + ';' : ''}
  ${pos.indexOf('r') >= 0 ? 'padding-right: ' + getWidthFromEnum(width) + ';' : ''}
  `;

export const margin = (width, pos) => `
  ${pos.indexOf('t') >= 0 ? 'margin-top: ' + getWidthFromEnum(width) + ';' : ''}
  ${pos.indexOf('b') >= 0 ? 'margin-bottom: ' + getWidthFromEnum(width) + ';' : ''}
  ${pos.indexOf('l') >= 0 ? 'margin-left: ' + getWidthFromEnum(width) + ';' : ''}
  ${pos.indexOf('r') >= 0 ? 'margin-right: ' + getWidthFromEnum(width) + ';' : ''}
  `;

export const col = (_color, mode, th) => {
  let color = _color;
  if (!_color || !th.col[_color]) {
    color = 'primary';
  }
  const base = `
  color: ${th.col[color].alt};
  background: ${th.col[color].fg};
  border: ${'0.1rem  solid'};
  ${th.col[color].fg.indexOf('gradient') >= 0 ? `border-image: ${th.col[color].fg}` : `border-color: ${th.col[color].fg}`};
  ${color === 'error' ? 'border: 0.1rem solid ' + th.col[color].alt + ';' : ''}
  ${color === 'error' ? `color: ${th.col[color].alt};` : ''}
  `;

  switch (mode) {
    case 'skeleton':
      return `
      color: ${th.col[color].fg};
      background-color: ${th.col.vars.mainBg};
      border: 0.1rem solid ${th.col[color].fg};
    `;
    case 'inverted':
      return `${base}
      color: ${th.col[color].fg};
      background-color:${th.col[color].alt};
      border: 0.1rem solid ${th.col[color].alt};
      ${color === 'error' ? 'border: 0.1rem solid ' + th.col[color].fg + ';' : ''}
      ${color === 'error' ? `color: ${th.col[color].fg};` : ''}

    `;
    default:
  }

  return base;
};

const genSpaceClass = (type, space, spaceVal) => {
  let prefix;

  switch (space) {
    case 'half':
      prefix = 'ic-half-';
      break;
    case 'regular':
      prefix = 'ic-';
      break;
    case 'double':
      prefix = 'ic-double-';
      break;
    default:
      prefix = 'ic-';
      break;
  }

  return `
  .${prefix}${type}, .${prefix}${type}-t, .${prefix}${type}-top, .${prefix}${type}-tb, .${prefix}${type}-tl, .${prefix}${type}-tr {
    ${type}-top: ${spaceVal}rem !important;
  }

  .${prefix}${type}, .${prefix}${type}-r, .${prefix}${type}-br, .${prefix}${type}-lr, .${prefix}${type}-tr {
    ${type}-right: ${spaceVal}rem !important;
  }

  .${prefix}${type}, .${prefix}${type}-b, .${prefix}${type}-bottom, .${prefix}${type}-tb, .${prefix}${type}-bl, .${prefix}${type}-br {
    ${type}-bottom: ${spaceVal}rem !important;
  }

  .${prefix}${type}, .${prefix}${type}-l, .${prefix}${type}-bl, .${prefix}${type}-lr, .${prefix}${type}-tl {
    ${type}-left: ${spaceVal}rem !important;
  }
  `;
};

export const marginClasses = (th) => `
  ${genSpaceClass('padding', 'half', th.space / 2)}
  ${genSpaceClass('padding', '', th.space)}
  ${genSpaceClass('padding', 'double', th.space * 2)}
  ${genSpaceClass('margin', 'half', th.space / 2)}
  ${genSpaceClass('margin', '', th.space)}
  ${genSpaceClass('margin', 'double', th.space * 2)}
`;

export const colorClasses = () => {
  return '';
};
