export const cartEntriesToItem = (entries) => {
  const items = [];
  entries.forEach((entry) => {
    // test
    const newItem = {
      item_id: entry.product.code,
      item_name: entry.product.name,
      currency: entry.product.price.currencyIso,
      quantity: entry.quantity,
      price: entry.basePrice ? entry.basePrice.value : entry.product.price.value,
    };
    if (entry.basePrice && entry.buyPrice && entry.buyPrice.value !== entry.basePrice.value) {
      newItem.discount = (entry.basePrice ? entry.basePrice.value : entry.product.price.value) - entry.buyPrice.value;
    }
    items.push(newItem);
  });
  return items;
};

export default cartEntriesToItem;
