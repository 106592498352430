import { takeLatest, put, call, select } from 'redux-saga/effects'
import net from '@spa-core-js/services/networkSvc'
import { ActionTypes, CustomerFeedbackPostStatus } from './constants'
import { SessionConfig } from '../app/interfaces'
import { selectSessionConfig } from '../utils'
import { Logger } from '@spa-core/logger'

export function* fetchCustomerFeedback({ payload }: any) {
    const { limit } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = sessionConfig.urlPrefix + `/rest/v1/order/testimonial/all?pageSize=${limit}&currentPage=0`
    try {
        const { results } = yield call(() => net.get(url, { cache: 'none' }))
        yield put({
            type: ActionTypes.FETCHED_CUSTOMER_FEEDBACK,
            payload: {
                customerFeedback: results,
            },
        })
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* postCustomerFeedback({ payload }: any) {
    const { orderCode, testimonialText } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/users/account/order/testimonial`
    const postData = `orderCode=${orderCode}&testimonialText=${testimonialText}`
    try {
        const result = yield call(() => net.post(url, postData))
        if (result) {
            yield put({
                type: ActionTypes.POSTED_CUSTOMER_FEEDBACK,
                payload: {
                    postCustomerFeedbackStatus: CustomerFeedbackPostStatus.SUCCESS,
                },
            })
        }
    } catch (e: any) {
        yield put({
            type: ActionTypes.POSTED_CUSTOMER_FEEDBACK,
            payload: {
                postCustomerFeedbackStatus: CustomerFeedbackPostStatus.FAIL,
            },
        })
        Logger.error({ message: e.message }, e.code, e.status, url, postData)
    }
}

export const watchers = [
    takeLatest(ActionTypes.FETCH_CUSTOMER_FEEDBACK, fetchCustomerFeedback),
    takeLatest(ActionTypes.POST_CUSTOMER_FEEDBACK, postCustomerFeedback),
]
