import net from 'score/networkSvc';

const affiliateReport = (url) => {
  const encodedUrl = btoa(url);
  net.get(`/rest/v1/s2sreporting?url=${encodedUrl}`);
};

if (!window.icUtil) {
  window.icUtil = {};
}

window.icUtil.affiliateReport = affiliateReport;
