let cnt = 0;
const cleanKey = (key) => {
  if (typeof key === 'undefined') {
    cnt++;
    return 'undefined_' + cnt;
  }
  if (key === null) {
    cnt++;
    return 'null_' + cnt;
  }
  const _key = key.replace(/&/g, '_and_');
  if (_key.match(/^\d/)) {
    return '_' + _key;
  }
  return _key;
};

export default cleanKey;
