import { PayloadAction } from '@reduxjs/toolkit'
import net from '@spa-core-js/services/networkSvc'
import { Logger } from '@spa-core/logger'
import { buffers } from 'redux-saga'
import { actionChannel, call, delay, put, select, take, takeLatest } from 'redux-saga/effects'
import { SessionConfig } from '../app/interfaces'
import { selectSessionConfig } from '../utils'
import { ActionTypes } from './constants'
import {
    AdyenAddNewCardPaymentSessionResponse,
    StoredToken,
    SubscriptionDataResponse,
    SubscriptionPaymentOption,
    UpdatePaymentForSubscriptionPayload,
} from './interfaces'

export function* getStoredCardsForTheUser() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/users/current/account/storedtoken`
    try {
        const fetchStoredTokenCardsResponse: StoredToken[] = yield call(() => net.get(url))
        if (fetchStoredTokenCardsResponse) {
            yield put({
                type: ActionTypes.FETCHED_STORED_CARDS,
                payload: fetchStoredTokenCardsResponse,
            })
        }
    } catch (e: any) {
        yield put({
            type: ActionTypes.TOGGLE_ADYEN_PAYMENT_SESSION_LOAD_FAILED,
            payload: true,
        })
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchSubscriptionPaymentOptionsForUser() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/users/current/account/subscriptions/paymentOptions`
    try {
        const fetchStoredTokenCardsResponse: SubscriptionPaymentOption[] = yield call(() => net.get(url))
        if (fetchStoredTokenCardsResponse) {
            yield put({
                type: ActionTypes.FETCHED_SUBSCRIPTION_PAYMENT_OPTIONS,
                payload: fetchStoredTokenCardsResponse,
            })
        }
    } catch (e: any) {
        yield put({
            type: ActionTypes.TOGGLE_ADYEN_PAYMENT_SESSION_LOAD_FAILED,
            payload: true,
        })
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* updatePaymentOptionForSubscription(action: PayloadAction<UpdatePaymentForSubscriptionPayload>) {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/users/current/account/subscriptions/${action.payload.subscriptionCode}/paymentOptions/${action.payload.paymentCode}`
    try {
        const response: SubscriptionDataResponse = yield call(() => net.put(url))
        if (response) {
            const result = yield call(() =>
                net.get(`${sessionConfig.urlPrefix}/rest/v1/users/current/account/subscriptions`, { cache: 'none' }),
            )
            yield put({ type: 'SUBLM_UPDATE_SUBS', data: result })
            yield put({
                type: ActionTypes.UPDATE_PAYMENT_OPTION_FOR_SUBS_SUCCESS,
                payload: { subscriptionCode: action.payload.subscriptionCode },
            })
        }
    } catch (e: any) {
        yield put({
            type: ActionTypes.UPDATE_PAYMENT_OPTION_FOR_SUBS_FAILED,
        })
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* getStoredCardsForTheUserOnCancel(action: PayloadAction<boolean>) {
    const triggerRefetchCards = action.payload
    if (triggerRefetchCards) {
        // Wait for 1 sec to fetch the stored tokens for the user on cancelling of the add card popup
        yield delay(1000)
        yield put({ type: ActionTypes.ACCOUNT_FETCH_STORED_CARDS_REQUESTED })
        // Adding two more retries with silent fetch
        yield delay(2000)
        yield call(getStoredCardsForTheUser)
        yield delay(3000)
        yield call(getStoredCardsForTheUser)
    }
}

export function* getAdyenAddNewCardPaymentSession(_action: PayloadAction<void>) {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/users/current/account/storedtoken/adyen/new`
    try {
        const adyenPaymentSessionResponse: AdyenAddNewCardPaymentSessionResponse = yield call(() => net.get(url))
        if (adyenPaymentSessionResponse && adyenPaymentSessionResponse.success && adyenPaymentSessionResponse.data) {
            yield put({
                type: ActionTypes.FETCHED_ADYEN_PAYMENT_SESSION,
                payload: adyenPaymentSessionResponse.data,
            })
        }
    } catch (e: any) {
        yield put({
            type: ActionTypes.TOGGLE_ADYEN_PAYMENT_SESSION_LOAD_FAILED,
            payload: true,
        })
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

/**
 * Added a request channel to make sure only one requests is used for creating the session at the backend
 */
export function* watchAdyenAddNewCardPaymentSessionInitRequests() {
    const requestChannel = yield actionChannel(ActionTypes.FETCH_ADYEN_ADD_NEW_CARD_PAYMENT_SESSION, buffers.none())

    while (true) {
        const action = yield take(requestChannel)
        yield call(getAdyenAddNewCardPaymentSession, action)
    }
}

export const watchers = [
    takeLatest(ActionTypes.ACCOUNT_FETCH_STORED_CARDS_REQUESTED, getStoredCardsForTheUser),
    takeLatest(ActionTypes.UPDATE_PAYMENT_OPTION_FOR_SUBS_REQUESTED, updatePaymentOptionForSubscription),
    takeLatest(ActionTypes.ACCOUNT_FETCH_SUBS_PAYMENT_OPTIONS_REQUESTED, fetchSubscriptionPaymentOptionsForUser),
    takeLatest(ActionTypes.CANCEL_ADD_CARD, getStoredCardsForTheUserOnCancel),
    watchAdyenAddNewCardPaymentSessionInitRequests(),
]
