import React, { useRef } from 'react'
import { useInViewport } from 'react-in-viewport'

interface Props {
    children?: React.ReactNode
    loadComponent: () => void
}

const InViewport = ({ children, loadComponent }: Props): React.ReactNode => {
    const myRef = useRef()
    useInViewport(myRef, {}, {}, { onEnterViewport: loadComponent })
    return <section ref={myRef}>{children}</section>
}

export default InViewport
