import { ActionTypes } from './constants'
import {
    AddToCart,
    AddSubscriptionToCartPayload,
    SetCartDataPayload,
    FetchCartPayload,
    UpdateCartEntryPayload,
    ReplaceProductPayload,
} from './interfaces'

export const addToCart = (payload: AddToCart) => ({
    type: ActionTypes.ADD_TO_CART,
    payload,
})

export const fetchCart = (payload: FetchCartPayload = {}) => ({
    type: ActionTypes.FETCH_CART,
    payload,
})

export const updateCartEntry = (payload: UpdateCartEntryPayload) => ({
    type: ActionTypes.UPDATE_CART_ENTRY,
    payload,
})

export const fetchRecommendedCart = () => ({
    type: ActionTypes.FETCH_RECOMMENDED_CART,
})

export const setCartDataInStore = (payload: SetCartDataPayload) => ({
    type: ActionTypes.SET_CART_DATA_IN_STORE,
    payload,
})

export const addSubscriptionProductToCart = (payload: AddSubscriptionToCartPayload) => ({
    type: ActionTypes.ADD_SUBSCRIPTION_PRODUCT_TO_CART,
    payload,
})

export const restoreAbandonedCart = () => ({
    type: ActionTypes.RESTORE_ABANDONED_CART,
})

export const rejectAbandonedCart = () => ({
    type: ActionTypes.REJECT_ABANDONED_CART,
})

export const resetSubscriptionInterval = () => ({
    type: ActionTypes.RESET_SUBSCRIPTION_INTERVAL,
})

export const setSubscriptionInterval = (subscriptionIntervalCode: string) => ({
    type: ActionTypes.SET_SUBSCRIPTION_INTERVAL,
    payload: {
        subscriptionIntervalCode,
    },
})

export const setDeleteCartRowEntry = (entryNumber: number, deleting: boolean) => ({
    type: ActionTypes.SET_DELETE_CART_ROW_ENTRY,
    payload: {
        entryNumber,
        deleting,
    },
})

export const replaceProduct = (payload: ReplaceProductPayload) => ({
    type: ActionTypes.REPLACE_PRODUCT,
    payload,
})

export const undoReplaceProduct = (payload: ReplaceProductPayload) => ({
    type: ActionTypes.UNDO_REPLACE_PRODUCT,
    payload,
})

export const replaceAllProducts = () => ({
    type: ActionTypes.REPLACE_ALL_PRODUCTS,
})

export const applyCampaign = (campaignCode: any) => ({
    type: ActionTypes.APPLY_CAMPAIGN,
    payload: {
        campaignCode,
    },
})

export const fetchSubscriptionOptions = () => ({
    type: ActionTypes.FETCH_SUBSCRIPTION_OPTIONS,
})
