import React from 'react'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { t } from '@spa-core/locale'

type ComponentState = {
    sessionConfig: SessionConfig
}

const SocailMediaLinks = (): React.ReactNode => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[sessionReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)
    const rootPath: string = 'images/socialmedia'
    const socialMediaUrl: string = sessionConfig.baseUrl + sessionConfig.urlPrefix + '/socialmedia'

    return (
        <div className="flex flex-col w-full items-center">
            {t('phrase.follow.us')}
            <div className="flex flex-row gap-2">
                <a className="size-8" href={socialMediaUrl}>
                    <img alt="Facebook Footer Link" src={`${sessionConfig.themeResourcePath}/${rootPath}/facebook.svg`} />
                </a>
                <a className="size-8" href={socialMediaUrl}>
                    <img alt="Instagram Footer Link" src={`${sessionConfig.themeResourcePath}/${rootPath}/instagram.svg`} />
                </a>
                <a className="size-8" href={socialMediaUrl}>
                    <img alt="Youtube Footer Link" src={`${sessionConfig.themeResourcePath}/${rootPath}/youtube.svg`} />
                </a>
            </div>
        </div>
    )
}

export default SocailMediaLinks
