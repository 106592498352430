import siteTheme from '@ic-site-theme'
import * as mixins from '@spa-core-js/mixins'
import { merge } from 'ts-deepmerge'
import defaultTheme from './default-theme'
import componentThemeOverrides from '@ic-site-theme-overrides'
import legacyComponentOverrides from '@ic-site-theme-overrides-legacy'
import { Theme } from './interfaces'

const mergedDefaultTheme: Theme = merge.withOptions({ mergeArrays: false }, defaultTheme, siteTheme)
export default mergedDefaultTheme

/**
 * @deprecated Will be removed, migrate to getComponentTheme
 */
export const getTheme = (componentName, componentProps = undefined) => {
    const overrides: any =
        componentThemeOverrides?.[componentName] || legacyComponentOverrides?.[componentName]?.(mergedDefaultTheme) || {}
    return merge.withOptions({ mergeArrays: false }, mergedDefaultTheme, componentProps || {}, overrides)
}

const themeFactoryGenerator = <ComponentThemeInterface>(
    componentName: string,
    defaultComponentTheme: ComponentThemeInterface,
): (() => ComponentThemeInterface) => {
    const mergedComponentTheme: ComponentThemeInterface = merge.withOptions(
        { mergeArrays: false },
        defaultComponentTheme || {},
        componentThemeOverrides?.[componentName] || legacyComponentOverrides[componentName]?.(mergedDefaultTheme) || {},
    )
    const generatedTheme: any = merge.withOptions({ mergeArrays: false }, mergedComponentTheme, mergedDefaultTheme)

    mixins.setTheme(generatedTheme)

    return () => generatedTheme
}
/**
 * @deprecated Will be removed, migrate to getComponentTheme
 */
export const getThemeFactory = <ComponentThemeInterface>(componentName: string, componentProps: ComponentThemeInterface) =>
    themeFactoryGenerator<ComponentThemeInterface>(componentName, componentProps)

export const getComponentTheme = <ComponentThemeInterface>(
    componentName: string = undefined,
    defaultComponentTheme: ComponentThemeInterface = undefined,
) => themeFactoryGenerator<ComponentThemeInterface>(componentName, defaultComponentTheme)()

export { Theme } from './interfaces'
