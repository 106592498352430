import { ErrorTypes, SpaError } from '@spa-ec/components/Error/Error';
import React from 'react';
import { Route } from 'react-router-dom';
import browserSvc from 'score/browserSvc';

let cnt = 0;
const getKey = () => {
  cnt++;
  return 'route_key_' + cnt;
};
const debug = false;
const getRoute = (item, path, isLoggedIn, Component, isMobile, campaignPageId, type) => {
  return (
    <Route
      key={getKey()}
      exact={item.exact}
      path={path}
      render={(props) => (
        <>
          {debug ? (
            <h2>
              Route: {path} {Component.displayName} {type}
            </h2>
          ) : null}
          <Component
            {...props}
            metadata={{
              title: item.title,
              metaTitle: item.metaTitle,
              description: item.description,
              keywords: item.keywords,
              metaDescription: item.metaDescription,
              metaRobots: item.metaRobots,
            }}
            metaRobots={item.metaRobots}
            isLoggedIn={isLoggedIn}
            isMobile={isMobile}
            pageId={item.pageId}
            ssrKey={item.pageId}
            campaignPageId={campaignPageId}
            showBreadCrumbsOnPage={item.showBreadCrumbsOnPage}
            template={item.template}
          />
        </>
      )}
    />
  );
};

const checkSecurityLevel = (type, isLoggedIn) => {
  if (isLoggedIn) {
    return type;
  }
  if (type === 'ACCOUNT' || type === 'LOGIN') {
    return 'LOGIN';
  }
  return type;
};

const RouteForItem = (routes, isLoggedIn, getComponentByType, isMobile, campaignPageId) => {
  const jsxRoutes = [];

  let smarteditPageId = null;
  let smarteditURL = browserSvc.localStorageGet('smarteditHistory');
  const smarteditCatalogVersion = browserSvc.localStorageGet('smarteditCatalogVersion');
  const smarteditPreviewUrl = browserSvc.localStorageGet('smarteditPreviewUrl');
  const isSmarteditContainerCompPresent = document.getElementsByTagName('smarteditcontainer-component');
  const cmsTicketId = sessionStorage.getItem('cmsTicketId');

  routes.forEach(([, item]) => {
    let type = checkSecurityLevel(item.type, isLoggedIn);
    let Component = getComponentByType(type);
    if (item?.url?.indexOf('notFound') > 0) {
      Component = class ThrowError extends React.Component {
        constructor() {
          throw new SpaError({ errorType: ErrorTypes.NOT_FOUND });
        }
      };
    } else if (item?.url?.indexOf('inactivecampaign') > 0) {
      Component = class ThrowError extends React.Component {
        constructor() {
          throw new SpaError({ errorType: ErrorTypes.INACTIVE_CAMPAIGN_PAGE });
        }
      };
    } else {
      Component = getComponentByType(type);
    }

    if ((isSmarteditContainerCompPresent && isSmarteditContainerCompPresent.length > 0) || cmsTicketId !== null) {
      if (
        (window.location.href.indexOf('/c/') > 0 && item.url.indexOf('/c/') > 0 && item.pageId !== undefined) ||
        (window.location.href.indexOf('/p/') > 0 && item.url.indexOf('/p/') > 0 && item.pageId !== undefined) ||
        (window.location.href.indexOf('/m/') > 0 && item.url.indexOf('/m/') > 0 && item.pageId !== undefined) ||
        (window.location.href.indexOf('/t/') > 0 && item.url.indexOf('/t/') > 0 && item.pageId !== undefined)
      ) {
        smarteditPageId = item.pageId;
        smarteditURL = window.location.href;
        if (item.url.indexOf('/c/') > 0) {
          smarteditURL = '/c/' + smarteditURL.split('/c/')[1];
        } else if (item.url.indexOf('/p/') > 0) {
          smarteditURL = '/p/' + smarteditURL.split('/p/')[1];
        } else if (item.url.indexOf('/m/') > 0) {
          smarteditURL = '/m/' + smarteditURL.split('/m/')[1];
        } else if (item.url.indexOf('/t/') > 0) {
          smarteditURL = '/t/' + smarteditURL.split('/t/')[1];
        }
        browserSvc.localStorageSet('smarteditHistory', smarteditURL);
      } else if (window.location.href.indexOf(item.url) > 0 && item.pageId !== undefined && item.url !== '/') {
        smarteditPageId = item.pageId;
        if (smarteditURL != null) {
          browserSvc.localStorageSet('smarteditHistory', item.url);
        }
      }
    }
    jsxRoutes.push(getRoute(item, decodeURI(item.url), isLoggedIn, Component, isMobile, campaignPageId, type));
  });

  if (
    (isSmarteditContainerCompPresent && isSmarteditContainerCompPresent.length > 0) ||
    !(cmsTicketId === '' || cmsTicketId === null)
  ) {
    // setting the smartedit page id before the event is triggered. To maintain the url history
    localStorage.setItem('smarteditHistoryPageID', smarteditPageId);
    let pkPart1 = Math.random().toFixed(14).split('.')[1];
    let pkPart2 = Math.random().toFixed(16).split('.')[1];
    const event = {
      pk: '' + pkPart1 + '.' + pkPart2 + '',
      gatewayId: 'experienceService',
      eventId: 'updateExperiencePageContext',
      data: { arguments: [smarteditCatalogVersion, smarteditPageId] },
      triggeredBy: 'SPA-Frontend',
    };
    parent.postMessage(event, smarteditPreviewUrl);
  }
  return jsxRoutes;
};

export default RouteForItem;
