export const isStdUrl = (url) => {
  if (url.indexOf('/c/') >= 0) return true;
  if (url.indexOf('/p/') >= 0) return true;
  if (url.indexOf('/t/') >= 0) return true;

  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (url.indexOf('/m/') >= 0) return true; // NOSONAR
  return false;
};

export const isCampaign = () =>
  (!location.pathname.match(/^(\/b2b)?\/\w\w\/\w\w/) &&
    !location.pathname.match('/login') &&
    !location.pathname.match('/my-account') &&
    // Urls in the form ".../c/...", ".../m/...", ".../t/..." or ".../p/..." are not allowed to be campaigns
    !isStdUrl(location.pathname)) ||
  location.hash.match(/#c#.+/) !== null;

export const getCampaignIdPath = () => {
  let path = location.pathname;
  if (path.match(/^\/b2b.*/)) {
    path = path.substr(4);
  }
  let pos = path.indexOf('/');

  // Remove the first slash
  path = path.substr(pos + 1, path.length);

  // remove string to next slash
  pos = path.indexOf('/');
  if (pos >= 0) {
    path = path.substr(0, pos);
  }
  // remove string from a querystring
  pos = path.indexOf('?');
  if (pos >= 0) {
    path = path.substr(0, pos);
  }
  // remove string from a #
  pos = path.indexOf('#');
  if (pos >= 0) {
    path = path.substr(0, pos);
  }

  return path;
};
const getCampaignIdHash = () => {
  let hash = location.hash;
  let pos = hash.indexOf('#c#');

  if (pos < 0) return;

  // Remove the initial #c#
  hash = hash.substr(pos + 3, hash.length);

  // remove string to next slash
  pos = hash.indexOf('#');
  if (pos >= 0) {
    hash = hash.substring(0, pos);
  }
  return hash;
};

export const getCampaignId = () => {
  // If this is not a campaign, return undefined
  if (!isCampaign()) return undefined;

  if (location.pathname.match(/^(\/b2b)?\/$/)) return getCampaignIdHash();

  if (!location.pathname.match(/^(\/b2b)?\/\w\w\/\w\w/)) return getCampaignIdPath();
  return getCampaignIdHash();
};
