export const NAME = 'global-messages'

export const ActionTypes = {
    ADD_MESSAGE: `${NAME}/ADD_MESSAGE`,
    ADD_MESSAGES: `${NAME}/ADD_MESSAGES`,
    ADD_REST_CALL_FAILED_MESSAGE: `${NAME}/ADD_REST_CALL_FAILED_MESSAGE`,
    REMOVE_MESSAGE: `${NAME}/REMOVE_MESSAGE`,
    REMOVE_ALL_MESSAGES: `${NAME}/REMOVE_ALL_MESSAGES`,
    FETCH_MESSAGE_FROM_SERVER: `${NAME}/FETCH_MESSAGE_FROM_SERVER`,
    FETCH_ALL_MESSAGES_FROM_SERVER: `${NAME}/FETCH_ALL_MESSAGES_FROM_SERVER`,
}

export const LOGOUT_SUCCESS_MESSAGE_ID: string = 'logout.success'

export enum MessageLevels {
    ERROR = 'error',
    INFO = 'info',
}
