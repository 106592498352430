import React from 'react'
import classnames from 'classnames'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { useSelector } from 'react-redux'

const collectUrls = (nodes, urlBag) => {
    if (nodes && nodes.length > 0) {
        nodes.filter((n) => n.url && n.url.startsWith('/')).map((n) => urlBag.push([n.url, n.title]))
        nodes.forEach((n) => collectUrls(n.nodes, urlBag))
    }
}

const filterUniqueUrls = (urls) => {
    return urls.filter((value, index, self) => self.findIndex((e) => e[0] === value[0]) === index)
}

const prepareLinksForSEO = (nodes, urlPrefix) => {
    const urls = []
    collectUrls(nodes, urls)
    const fArr = filterUniqueUrls(urls).map((u) => {
        if (u[0].startsWith(urlPrefix)) {
            return u
        }
        return [`${urlPrefix}${u[0]}`, u[1]]
    })
    return fArr
}

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    nodes: any[]
}

const SEOLinksToCategoryPages: React.FC<Props> = (p) => {
    /**
     * TODO:
     * Find out what p.nodes actually contains and type all variables in this file
     */
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    if (!sessionConfig.showMinifiedNavlinksForSEO) {
        return null
    }

    if (sessionConfig?.urlPrefix && p && p.nodes && p.nodes.length > 0) {
        const urls = prepareLinksForSEO(p.nodes, sessionConfig.urlPrefix)
        return (
            // Adding class to disable the display of seo urls for users for normal screens size.
            // Making it visbile only for screen width of 250px or less as in that case our screen
            // elements are already disaligned in order to follow Google webmaster guidelines given
            // at https://developers.google.com/search/docs/advanced/guidelines/webmaster-guidelines
            <div className={classnames('w-full max-[270px]:block hidden')}>
                {urls.map((u) => (
                    // Adding a dot so that it is clickable for search engines
                    <a key={u[0]} href={u[0]}>
                        {u[1]}
                    </a>
                ))}
            </div>
        )
    }
    return null
}

export default React.memo(SEOLinksToCategoryPages, (prevP, nextP) => prevP.nodes && prevP.nodes.length === nextP.nodes.length)
