import { NAME as categoriesReducerName } from '@spa-core/store/categories/constants'
import { reducer as categoriesReducer } from '@spa-core/store/categories/reducer'
import { watchers as categoriesWatchers } from '@spa-core/store/categories/sagas'

import { NAME as navigationReducerName } from '@spa-core/store/navigation/constants'
import { reducer as navigationReducer } from '@spa-core/store/navigation/reducer'

import { NAME as productsReducerName } from '@spa-core/store/products/constants'
import { reducer as productsReducer } from '@spa-core/store/products/reducer'
import { watchers as productsWatchers } from '@spa-core/store/products/sagas'

import { NAME as breadcrumbsReducerName } from '@spa-core/store/breadcrumbs/constants'
import { reducer as breadcrumbsReducer } from '@spa-core/store/breadcrumbs/reducer'
import { watchers as breadcrumbsWatchers } from '@spa-core/store/breadcrumbs/sagas'

import { NAME as globalMessagesReducerName } from '@spa-core/store/global-messages/constants'
import { reducer as globalMessagesReducer } from '@spa-core/store/global-messages/reducer'
import { watchers as globalMessagesWatchers } from '@spa-core/store/global-messages/sagas'

import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { reducer as appReducer } from '@spa-core/store/app/reducer'
import { watchers as appWatchers } from '@spa-core/store/app/sagas'

import { NAME as cartReducerName } from '@spa-core/store/cart/constants'
import { reducer as cartReducer } from '@spa-core/store/cart/reducer'
import { watchers as cartWatchers } from '@spa-core/store/cart/sagas'

import { NAME as customerFeedbackReducerName } from '@spa-core/store/customer-feedback/constants'
import { reducer as customerFeedbackReducer } from '@spa-core/store/customer-feedback/reducer'
import { watchers as customerFeedbackWatchers } from '@spa-core/store/customer-feedback/sagas'

import { NAME as historicalLowPriceReducerName } from '@spa-core/store/historical-low-price/constants'
import { reducer as historicalLowPriceReducer } from '@spa-core/store/historical-low-price/reducer'
import { watchers as historicalLowPriceWatchers } from '@spa-core/store/historical-low-price/sagas'

import { NAME as searchReducerName } from '@spa-core/store/search/constants'
import { reducer as searchReducer } from '@spa-core/store/search/reducer'
import { watchers as searchWatchers } from '@spa-core/store/search/sagas'

import { NAME as campaignReducerName } from '@spa-core/store/campaign/constants'
import { reducer as campaignReducer } from '@spa-core/store/campaign/reducer'
import { watchers as campaignWatchers } from '@spa-core/store/campaign/sagas'

import { NAME as checkoutReducerName } from '@spa-core/store/checkout/constants'
import { reducer as checkoutReducer } from '@spa-core/store/checkout/reducer'
import { watchers as checkoutWatchers } from '@spa-core/store/checkout/sagas'

import { NAME as contentSlotsReducerName } from '@spa-core/store/content-slots/constants'
import { reducer as contentSlotsReducer } from '@spa-core/store/content-slots/reducer'
import { watchers as contentSlotsWatchers } from '@spa-core/store/content-slots/sagas'

import { NAME as csAdminReducerName } from '@spa-core/store/cs-admin/constants'
import { reducer as csAdminReducer } from '@spa-core/store/cs-admin/reducer'
import { watchers as csAdminWatchers } from '@spa-core/store/cs-admin/sagas'

import { NAME as accountReducerName } from '@spa-core/store/account/constants'
import { reducer as accountReducer } from '@spa-core/store/account/reducer'
import { watchers as accountWatchers } from '@spa-core/store/account/sagas'

export const reducers = {
    [categoriesReducerName]: categoriesReducer,
    [productsReducerName]: productsReducer,
    [breadcrumbsReducerName]: breadcrumbsReducer,
    [globalMessagesReducerName]: globalMessagesReducer,
    [cartReducerName]: cartReducer,
    [customerFeedbackReducerName]: customerFeedbackReducer,
    [historicalLowPriceReducerName]: historicalLowPriceReducer,
    [searchReducerName]: searchReducer,
    [navigationReducerName]: navigationReducer,
    [campaignReducerName]: campaignReducer,
    [appReducerName]: appReducer,
    [checkoutReducerName]: checkoutReducer,
    [contentSlotsReducerName]: contentSlotsReducer,
    [csAdminReducerName]: csAdminReducer,
    [accountReducerName]: accountReducer,
}

export const watchers = [
    ...categoriesWatchers,
    ...productsWatchers,
    ...breadcrumbsWatchers,
    ...globalMessagesWatchers,
    ...cartWatchers,
    ...customerFeedbackWatchers,
    ...historicalLowPriceWatchers,
    ...searchWatchers,
    ...campaignWatchers,
    ...appWatchers,
    ...checkoutWatchers,
    ...contentSlotsWatchers,
    ...csAdminWatchers,
    ...accountWatchers,
]

export { Store } from './interfaces'
