import cloneDeep from 'fast-clone'
import { Action } from '../interfaces'
import { ActionTypes, CheckoutFormKey, Modals } from './constants'
import {
    CheckoutFormData,
    CheckoutStore,
    DisabledFields,
    FetchedCheckoutSettingsResult,
    FetchedOrderConfirmationPayload,
    FetchedPickupPoints,
    SaveCheckoutFormDataPayload,
    SetDisabledFieldsPayload,
    UpdateCheckoutSettingsPayload,
} from './interfaces'

export const INITIAL_STATE: CheckoutStore = {
    orderConfirmations: {},
    settings: undefined,
    deliveryModes: [],
    paymentModes: [],
    addresses: [],
    pickupPoints: {},
    deliveryModeErrorMessage: undefined,
    adyenPaymentSessionRecords: {},
    placingOrder: false,
    checkoutLocked: false,
    adyenPaymentSessionInitializationFailed: false,
    checkoutFormData: {
        [CheckoutFormKey.REMEMBER_ME]: { value: true },
        [CheckoutFormKey.ADDRESS_ID]: { value: null },
        [CheckoutFormKey.ACCOUND_TYPE]: { value: null },
        [CheckoutFormKey.COMPANY_TYPE]: { value: null },
        [CheckoutFormKey.SMS_NOTIFICATION]: { value: false },
        [CheckoutFormKey.FIRST_NAME]: { value: null },
        [CheckoutFormKey.LAST_NAME]: { value: null },
        [CheckoutFormKey.CAREOF]: { value: null },
        [CheckoutFormKey.LINE1]: { value: null },
        [CheckoutFormKey.LINE2]: { value: null },
        [CheckoutFormKey.TOWN_CITY]: { value: null },
        [CheckoutFormKey.POSTCODE]: { value: null },
        [CheckoutFormKey.COUNTRY_ISO]: { value: null },
        [CheckoutFormKey.COUNTRY]: { value: null },
        [CheckoutFormKey.ACCESS_CODE]: { value: null },
        [CheckoutFormKey.PHONE]: { value: null },
        [CheckoutFormKey.CELLPHONE]: { value: null },
        [CheckoutFormKey.EMAIL]: { value: null },
        [CheckoutFormKey.PERSONAL_IDENTITY_NUMBER]: { value: null },
        [CheckoutFormKey.BIRTH_DATE]: { value: null },
        [CheckoutFormKey.OTHER]: { value: null },
        [CheckoutFormKey.PICKUP_POINT_ID]: { value: null },
        [CheckoutFormKey.PICKUP_POINT_NAME]: { value: null },
        [CheckoutFormKey.COMPANY_NAME]: { value: null },
        [CheckoutFormKey.COMPANY_CONTACT_PERSON]: { value: null },
        [CheckoutFormKey.ORGANIZATION_NUMBER]: { value: null },
        [CheckoutFormKey.DIFFERENT_DELIVERY_ADDRESS]: { value: false },
        [CheckoutFormKey.SHIPPING_ADDRESS_FIRSTNAME]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_SURNAME]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_CAREOF]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_COMPANY_NAME]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_COMPANY_CONTACT_PERSON]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_LINE1]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_LINE2]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_POSTCODE]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_CITY]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_COUNTRY]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_ACCESS_CODE]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_CELLPHONE]: { value: null },
        [CheckoutFormKey.SHIPPING_ADDRESS_EMAIL]: { value: null },
        [CheckoutFormKey.PAYMENT_METHOD]: { value: null },
        [CheckoutFormKey.SHIPMENT_METHOD]: { value: null },
        [CheckoutFormKey.SECURITY_CODE]: { value: null },
        [CheckoutFormKey.TERMS_CHECK]: { value: false },
        [CheckoutFormKey.RIGHTS_OF_REGRETS_CHECK]: { value: false },
        [CheckoutFormKey.GDPR]: { value: false },
        [CheckoutFormKey.VOUCHER_CODE]: { value: null },
        [CheckoutFormKey.PERSONAL_NUMBER_LOOKED_UP]: { value: false },
        [CheckoutFormKey.POSTAL_CODE_RELOAD]: { value: false },
        [CheckoutFormKey.INVOICE_EMAIL]: { value: null },
    },
    hasFetchedCheckoutFormData: false,
    addressLookupLoading: false,
    didAddressLookup: false,
    selectedPaymentMode: undefined,
    disabledFields: {},
    fetchingPickupPoints: false,
    addressLookupError: false,
    openModals: {
        [Modals.PAYMENT_ERROR]: false,
        [Modals.PRIVACY_POLICY]: false,
        [Modals.PURCHASE_TERMS]: false,
        [Modals.RIGHTS_OF_REGRETS]: false,
        [Modals.TERMS_AND_CONDITIONS]: false,
    },
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): CheckoutStore => {
    switch (type) {
        case ActionTypes.FETCHED_ORDER_CONFIRMATION: {
            const { orderConfirmation, upsellCompleted, requestOrderId }: FetchedOrderConfirmationPayload = payload
            return {
                ...state,
                orderConfirmations: {
                    ...state.orderConfirmations,
                    [requestOrderId]: {
                        ...orderConfirmation,
                        upsellCompleted: upsellCompleted === true,
                    },
                },
            }
        }
        case ActionTypes.FETCHED_CHECKOUT_SETTINGS: {
            const { settings, addresses, paymentModes }: FetchedCheckoutSettingsResult = payload
            const checkoutFormData: CheckoutFormData = state.checkoutFormData
            Object.keys(settings.checkoutForm).forEach((key: CheckoutFormKey) => {
                checkoutFormData[key] = {
                    value: settings.checkoutForm[key],
                    valid: true,
                    updated: new Date().toISOString(),
                }
            })
            return {
                ...state,
                settings,
                addresses,
                paymentModes,
                checkoutFormData,
                hasFetchedCheckoutFormData: true,
            }
        }
        case ActionTypes.FETCHED_DELIVERY_MODES: {
            const { deliveryModes } = payload
            return {
                ...state,
                deliveryModes,
            }
        }
        case ActionTypes.TOGGLE_OPEN_MODAL: {
            const { modal } = payload
            return {
                ...state,
                openModals: {
                    ...state.openModals,
                    [modal]: !state.openModals[modal],
                },
            }
        }
        case ActionTypes.SET_FETCHING_PICKUP_POINTS: {
            const { fetchingPickupPoints } = payload
            return {
                ...state,
                fetchingPickupPoints,
            }
        }
        case ActionTypes.SET_DISABLED_FIELDS: {
            const { fields, disabled }: SetDisabledFieldsPayload = payload
            const disabledFields: DisabledFields = {}
            fields.forEach((fieldKey: CheckoutFormKey) => {
                disabledFields[fieldKey] = disabled
            })
            return {
                ...state,
                disabledFields: {
                    ...state.disabledFields,
                    ...disabledFields,
                },
            }
        }
        case ActionTypes.UPDATE_CHECKOUT_FORM_FIELD: {
            const { errorMessage, value, valid, key }: SaveCheckoutFormDataPayload = payload
            const checkoutFormData: CheckoutFormData = cloneDeep(state.checkoutFormData)
            const updated: string = new Date().toISOString()
            if (valid) {
                checkoutFormData[key] = { value, valid, updated }
            } else {
                checkoutFormData[key] = { ...checkoutFormData[key], errorMessage, valid, updated }
            }
            return {
                ...state,
                checkoutFormData,
            }
        }
        case ActionTypes.RESET_CHECKOUT_FORM_DATA:
            return {
                ...state,
                checkoutFormData: INITIAL_STATE.checkoutFormData,
            }
        case ActionTypes.UPDATE_CHECKOUT_SETTINGS: {
            const { key, value }: UpdateCheckoutSettingsPayload = payload
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [key]: value,
                },
            }
        }
        case ActionTypes.UPDATE_CHECKOUT_FORM:
            return {
                ...state,
                checkoutFormData: payload.checkoutFormData,
            }
        case ActionTypes.CLEAR_CHECKOUT_FORM_ERROR_MESSAGE:
            const checkoutFormData: CheckoutFormData = state.checkoutFormData
            delete checkoutFormData?.[CheckoutFormKey.CHECKOUT_FORM]
            return {
                ...state,
                checkoutFormData,
            }
        case ActionTypes.SET_PLACING_ORDER:
            return {
                ...state,
                placingOrder: payload.placingOrder,
            }
        case ActionTypes.SET_SELECTED_PAYMENT_MODE:
            return {
                ...state,
                selectedPaymentMode: payload.paymentMode,
            }
        case ActionTypes.SET_DID_ADDRESS_LOOKUP:
            return {
                ...state,
                didAddressLookup: payload.didAddressLookup,
            }
        case ActionTypes.SET_DO_ADDRESS_LOOKUP_LOADING:
            return {
                ...state,
                addressLookupLoading: payload.loading,
            }
        case ActionTypes.SET_DO_ADDRESS_LOOKUP_ERROR:
            return {
                ...state,
                addressLookupError: payload.errored,
            }
        case ActionTypes.SET_DELIVERY_MODE_ERROR_MESSAGE:
            return {
                ...state,
                deliveryModeErrorMessage: payload.deliveryModeErrorMessage,
            }
        case ActionTypes.FETCHED_PICKUP_POINTS: {
            const { postalCode, deliveryProductId, pickupPoints }: FetchedPickupPoints = payload
            const postalCodeNoWhiteSpace: string = postalCode?.replace(/\s/g, '')
            return {
                ...state,
                pickupPoints: {
                    [postalCodeNoWhiteSpace]: {
                        ...state.pickupPoints?.[postalCodeNoWhiteSpace],
                        [deliveryProductId]: pickupPoints?.length > 0 ? pickupPoints : [],
                    },
                },
            }
        }
        case ActionTypes.FETCHED_ADYEN_PAYMENT_SESSION: {
            state.adyenPaymentSessionRecords[payload.orderId] = payload
            return {
                ...state,
                adyenPaymentSessionRecords: {
                    ...state.adyenPaymentSessionRecords,
                    [payload.orderId]: payload,
                },
            }
        }
        case ActionTypes.TOGGLE_ADYEN_PAYMENT_SESSION_LOAD_FAILED: {
            return {
                ...state,
                adyenPaymentSessionInitializationFailed: payload,
            }
        }
        case ActionTypes.SET_PLACED_ORDER_ID: {
            return {
                ...state,
                orderConfirmationCode: payload.placedOrderCode,
                orderConfirmationRedirectURL: payload.placedOrderRedirectUrl,
            }
        }
        case ActionTypes.SET_CHECKOUT_LOCKED: {
            return {
                ...state,
                checkoutLocked: payload,
            }
        }
        default:
            return state
    }
}
