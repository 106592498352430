import { put, call, select, takeEvery } from 'redux-saga/effects'
import net from '@spa-core-js/services/networkSvc'
import { ActionTypes, ComponentType } from './constants'
import { SessionConfig } from '../app/interfaces'
import { selectSessionConfig } from '../utils'
import { isPuppeteer } from '@spa-ec/components/Loader/utils'
import { setFetchingContentSlot, setFetchingContentSlots } from './actions'
import {
    ContentSlot,
    ContentSlotItem,
    ContentSlotsResult,
    FetchContentSlotByTypeAndUidPayload,
    FetchProductContentSlotPayload,
    ComponentContentSlot,
    FetchContentSlotWithSectionIdAndPageIdPayload,
    SectionIdPageIdContentSlot,
} from './interfaces'
import { NAME as contentSlotsReducerName } from './constants'
import { Store } from '../interfaces'
import { fetchProductsInBatch } from '../products/actions'
import { Logger } from '@spa-core/logger'

export function* fetchContentSlots({ payload }: any) {
    const { pageId } = payload
    const fetchingContentSlots: boolean = yield select(
        (state: Store) => state?.reducers?.[contentSlotsReducerName]?.fetchingContentSlots?.[pageId] === true,
    )
    if (fetchingContentSlots) return
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v2/page/contentSlots?pageId=${pageId}${isPuppeteer ? '&from_scod=true' : ''}`
    const urlAlreadyFetched: boolean = yield select(
        (state: Store) => state?.reducers?.[contentSlotsReducerName]?.fetchedContentSlotUrls?.[url] === true,
    )
    if (urlAlreadyFetched) return
    try {
        yield put(setFetchingContentSlots(pageId, true))
        const result: ContentSlotsResult = yield call(() => net.get(url))
        if (result) {
            yield put({
                type: ActionTypes.SET_FETCHED_CONTENT_SLOT_URLS,
                payload: {
                    url,
                },
            })
            const propductCodes: string[] = []
            result.contentSlots?.forEach((contentSlot: ContentSlot) =>
                contentSlot?.contentSlotItems?.forEach((contentSlotItem: ContentSlotItem) => {
                    if (
                        contentSlotItem.componentType === ComponentType.PRODUCT_RECOMMENDATION ||
                        contentSlotItem.componentType === ComponentType.PRODUCT_UPSELL_CAROUSEL
                    ) {
                        propductCodes.push(...JSON.parse(contentSlotItem?.properties?.content))
                    }
                }),
            )
            if (propductCodes.length) {
                yield put(fetchProductsInBatch(propductCodes, true))
            }
            yield put({
                type: ActionTypes.FETCHED_CONTENT_SLOTS,
                payload: {
                    pageId,
                    contentSlots: result.contentSlots,
                },
            })
        }
        yield put(setFetchingContentSlots(pageId, false))
    } catch (e: any) {
        yield put(setFetchingContentSlots(pageId, false))
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchProductContentSlot({ payload }: any) {
    const {
        productCode,
        category,
        modelCategoryCode,
        brand,
        categoryCode,
        pageId,
        position,
        contentSlotKey,
    }: FetchProductContentSlotPayload = payload
    const fetchingContentSlot: boolean = yield select(
        (state: Store) => state?.reducers?.[contentSlotsReducerName]?.fetchingContentSlot?.[contentSlotKey] === true,
    )
    if (fetchingContentSlot) return
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    let url: string = `${sessionConfig.urlPrefix}/rest/v2/contentSlot?position=${position}&pageId=${pageId}`
    if (productCode) {
        url = `${url}&product=${productCode}`
    }
    if (category) {
        url = `${url}&category=${category}`
    }
    if (modelCategoryCode) {
        url = `${url}&shelfCategory=${modelCategoryCode}`
    }
    if (brand && categoryCode) {
        url = `${url}&brand=${brand}&typeCategory=${categoryCode}`
    } else if (categoryCode) {
        url = `${url}&typeCategory=${categoryCode}`
    }
    const urlAlreadyFetched: boolean = yield select(
        (state: Store) => state?.reducers?.[contentSlotsReducerName]?.fetchedContentSlotUrls?.[url] === true,
    )
    if (urlAlreadyFetched) return
    try {
        yield put(setFetchingContentSlot(contentSlotKey, true))
        const result: ContentSlot = yield call(() => net.get(url))
        if (result) {
            yield put({
                type: ActionTypes.SET_FETCHED_CONTENT_SLOT_URLS,
                payload: {
                    url,
                },
            })
            result.position = position

            const propductCodes: string[] = []
            result.contentSlotItems?.forEach((contentSlotItem: ContentSlotItem) => {
                if (
                    contentSlotItem.componentType === ComponentType.PRODUCT_RECOMMENDATION ||
                    contentSlotItem.componentType === ComponentType.PRODUCT_UPSELL_CAROUSEL
                ) {
                    propductCodes.push(...JSON.parse(contentSlotItem?.properties?.content))
                }
            })
            if (propductCodes.length) {
                yield put(fetchProductsInBatch(propductCodes, true))
            }

            yield put({
                type: ActionTypes.FETCHED_PRODUCT_CONTENT_SLOT,
                payload: {
                    pageId,
                    contentSlotKey,
                    contentSlot: result,
                },
            })
        }
        yield put(setFetchingContentSlot(contentSlotKey, false))
    } catch (e: any) {
        yield put(setFetchingContentSlot(contentSlotKey, false))
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchComponentContentSlot({ payload }: any) {
    const { type, componentUid }: FetchContentSlotByTypeAndUidPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/react/${type}Controller?componentUid=${componentUid}`
    try {
        yield put({
            type: ActionTypes.SET_FETCHING_COMPONENT_CONTENT_SLOT,
            payload: {
                componentUid,
                fetching: true,
            },
        })
        const componentContentSlot: ComponentContentSlot = yield call(() => net.get(url))
        if (componentContentSlot) {
            yield put({
                type: ActionTypes.FETCHED_COMPONENT_CONTENT_SLOT,
                payload: {
                    componentUid,
                    componentContentSlot,
                },
            })
        }

        yield put({
            type: ActionTypes.SET_FETCHING_COMPONENT_CONTENT_SLOT,
            payload: {
                componentUid,
                fetching: false,
            },
        })
    } catch (e: any) {
        yield put({
            type: ActionTypes.SET_FETCHING_COMPONENT_CONTENT_SLOT,
            payload: {
                componentUid,
                fetching: false,
            },
        })
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchContentSlotWithSectionIdAndPageId({ payload }: any) {
    const { sectionId, pageId, ctxCategory }: FetchContentSlotWithSectionIdAndPageIdPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    let url: string = `${sessionConfig.urlPrefix}/components?site=${sessionConfig.currentSiteCountryId}&urlPrefix=${encodeURIComponent(sessionConfig.urlPrefix)}&sectionId=${sectionId}&pageId=${pageId}`
    if (ctxCategory) {
        url += `&ctxCategory=${ctxCategory}`
    }

    const sectionIdPageId: string = `${sectionId}${pageId}`
    try {
        yield put({
            type: ActionTypes.SET_FETCHING_CONTENT_SLOT_WITH_SECTION_ID_PAGE_ID,
            payload: {
                sectionIdPageId,
                fetching: true,
            },
        })
        const sectionIdPageIdContentSlot: SectionIdPageIdContentSlot[] = yield call(() => net.get(url))

        if (sectionIdPageIdContentSlot?.length) {
            yield put({
                type: ActionTypes.FETCHED_SECTION_ID_PAGE_ID_CONTENT_SLOT,
                payload: {
                    sectionIdPageId,
                    sectionIdPageIdContentSlot,
                },
            })
        }

        yield put({
            type: ActionTypes.SET_FETCHING_CONTENT_SLOT_WITH_SECTION_ID_PAGE_ID,
            payload: {
                sectionIdPageId,
                fetching: false,
            },
        })
    } catch (e: any) {
        yield put({
            type: ActionTypes.SET_FETCHING_CONTENT_SLOT_WITH_SECTION_ID_PAGE_ID,
            payload: {
                sectionIdPageId,
                fetching: false,
            },
        })
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export const watchers = [
    takeEvery(ActionTypes.FETCH_CONTENT_SLOTS, fetchContentSlots),
    takeEvery(ActionTypes.FETCH_PRODUCT_CONTENT_SLOT, fetchProductContentSlot),
    takeEvery(ActionTypes.FETCH_COMPONENT_CONTENT_SLOT, fetchComponentContentSlot),
    takeEvery(ActionTypes.FETCH_SECTION_ID_PAGE_ID_CONTENT_SLOT, fetchContentSlotWithSectionIdAndPageId),
]
