import { CheckoutFormKey, CheckoutSettingsKey } from '@spa-core/store/checkout/constants'
import { FieldInfo } from '../interfaces'
import { Icons } from '@ui-elem/Icon/Icon'
import { TextInputAutocomplete } from '@ui-elem/TextInput/TextInput'

export const AddressLookupFields: CheckoutFormKey[] = [
    CheckoutFormKey.COMPANY_NAME,
    CheckoutFormKey.FIRST_NAME,
    CheckoutFormKey.LAST_NAME,
    CheckoutFormKey.LINE1,
    CheckoutFormKey.LINE2,
    CheckoutFormKey.POSTCODE,
    CheckoutFormKey.TOWN_CITY,
    CheckoutFormKey.COUNTRY,
]

export const CompanyFieldList: FieldInfo[] = [
    {
        key: CheckoutFormKey.COMPANY_NAME,
        placeholder: 'registration.corporate.companyName',
        icon: Icons.House,
        autofill: TextInputAutocomplete.ORGANIZATION,
    },
    {
        key: CheckoutFormKey.COMPANY_CONTACT_PERSON,
        placeholder: 'registration.corporate.contactPerson',
        icon: Icons.User,
    },
    {
        key: CheckoutFormKey.PURCHASE_REFERENCE_FOR_ORDER,
        placeholder: 'order.purchaseOrderReference',
        icon: Icons.OutlineNumbers,
        validateOnChange: false,
        maxLength: 40,
    },
    {
        key: CheckoutFormKey.LINE1,
        placeholder: 'address.line1',
        icon: Icons.House,
        autofill: TextInputAutocomplete.ADDRESS_LINE1,
    },
    {
        key: CheckoutFormKey.LINE2,
        placeholder: 'address.line2',
        icon: Icons.House,
        autofill: TextInputAutocomplete.ADDRESS_LINE2,
    },
    {
        key: CheckoutFormKey.ACCESS_CODE,
        placeholder: 'address.access.code',
        icon: Icons.DoorOpen,
    },
    {
        key: CheckoutFormKey.POSTCODE,
        placeholder: 'address.postcode',
        icon: Icons.House,
        autofill: TextInputAutocomplete.POSTAL_CODE,
        suggestion: CheckoutSettingsKey.POST_CODE_FORMAT_SUGGESTION,
    },
    {
        key: CheckoutFormKey.TOWN_CITY,
        placeholder: 'address.townCity',
        icon: Icons.Globe,
    },
    {
        key: CheckoutFormKey.COUNTRY,
        placeholder: 'address.country',
        icon: Icons.Globe,
        autofill: TextInputAutocomplete.COUNTRY_NAME,
        disabled: true,
    },
    {
        key: CheckoutFormKey.CELLPHONE,
        placeholder: 'address.cellphone',
        icon: Icons.Phone,
        autofill: TextInputAutocomplete.TEL,
        suggestion: CheckoutSettingsKey.MOBILE_FORMAT_SUGGESION,
    },
]

export const CompanyShippingFieldList: FieldInfo[] = [
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_COMPANY_NAME,
        placeholder: 'registration.corporate.companyName',
        icon: Icons.House,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_COMPANY_CONTACT_PERSON,
        placeholder: 'registration.corporate.contactPerson',
        icon: Icons.User,
        breakRow: true,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_LINE1,
        placeholder: 'address.line1',
        icon: Icons.House,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_LINE2,
        placeholder: 'address.line2',
        icon: Icons.House,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_ACCESS_CODE,
        placeholder: 'address.entryCode',
        icon: Icons.DoorOpen,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_POSTCODE,
        placeholder: 'address.postcode',
        icon: Icons.House,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_CITY,
        placeholder: 'address.city',
        icon: Icons.Globe,
        breakRow: true,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_EMAIL,
        placeholder: 'shipping.address.email',
        icon: Icons.Envelope,
        autofill: TextInputAutocomplete.EMAIL,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_CELLPHONE,
        placeholder: 'shipping.address.cellphone',
        icon: Icons.Phone,
        autofill: TextInputAutocomplete.TEL,
    },
]

export const PrivateShippingFieldList: FieldInfo[] = [
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_FIRSTNAME,
        placeholder: 'address.firstName',
        icon: Icons.User,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_SURNAME,
        placeholder: 'address.lastName',
        icon: Icons.User,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_CAREOF,
        placeholder: 'address.co',
        icon: Icons.User,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_LINE1,
        placeholder: 'address.line1',
        icon: Icons.House,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_LINE2,
        placeholder: 'address.line2',
        icon: Icons.House,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_ACCESS_CODE,
        placeholder: 'address.entryCode',
        icon: Icons.DoorOpen,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_POSTCODE,
        placeholder: 'address.postcode',
        icon: Icons.House,
    },
    {
        key: CheckoutFormKey.SHIPPING_ADDRESS_CITY,
        placeholder: 'address.city',
        icon: Icons.Globe,
    },
]

export const PrivateFieldList: FieldInfo[] = [
    {
        key: CheckoutFormKey.FIRST_NAME,
        placeholder: 'address.firstName',
        icon: Icons.User,
        autofill: TextInputAutocomplete.GIVEN_NAME,
    },
    {
        key: CheckoutFormKey.LAST_NAME,
        placeholder: 'address.lastName',
        icon: Icons.User,
        autofill: TextInputAutocomplete.FAMILY_NAME,
    },
    {
        key: CheckoutFormKey.CAREOF,
        placeholder: 'address.co',
        icon: Icons.User,
    },
    {
        key: CheckoutFormKey.LINE1,
        placeholder: 'address.line1',
        icon: Icons.House,
        autofill: TextInputAutocomplete.ADDRESS_LINE1,
    },
    {
        key: CheckoutFormKey.LINE2,
        placeholder: 'address.line2',
        icon: Icons.House,
        autofill: TextInputAutocomplete.ADDRESS_LINE2,
    },
    {
        key: CheckoutFormKey.ACCESS_CODE,
        placeholder: 'address.entryCode',
        icon: Icons.DoorOpen,
    },
    {
        key: CheckoutFormKey.POSTCODE,
        placeholder: 'address.postcode',
        icon: Icons.House,
        autofill: TextInputAutocomplete.POSTAL_CODE,
        suggestion: CheckoutSettingsKey.POST_CODE_FORMAT_SUGGESTION,
    },
    {
        key: CheckoutFormKey.TOWN_CITY,
        placeholder: 'address.city',
        icon: Icons.Globe,
    },
    {
        key: CheckoutFormKey.COUNTRY,
        placeholder: 'address.country',
        icon: Icons.Globe,
        autofill: TextInputAutocomplete.COUNTRY_NAME,
        disabled: true,
    },
    {
        key: CheckoutFormKey.CELLPHONE,
        placeholder: 'address.cellphone',
        icon: Icons.Phone,
        autofill: TextInputAutocomplete.TEL,
        suggestion: CheckoutSettingsKey.MOBILE_FORMAT_SUGGESION,
    },
]
