export const NAME = 'account'

export const ActionTypes = {
    ACCOUNT_FETCH_STORED_CARDS_REQUESTED: `${NAME}/ACCOUNT_FETCH_STORED_CARDS_REQUESTED`,
    UPDATE_PAYMENT_OPTION_FOR_SUBS_REQUESTED: `${NAME}/UPDATE_PAYMENT_OPTION_FOR_SUBS_REQUESTED`,
    UPDATE_PAYMENT_OPTION_FOR_SUBS_SUCCESS: `${NAME}/UPDATE_PAYMENT_OPTION_FOR_SUBS_SUCCESS`,
    TOGGLE_PAYMENT_MODE_EDIT_ENABLED: `${NAME}/TOGGLE_PAYMENT_MODE_EDIT_ENABLED`,
    UPDATE_PAYMENT_OPTION_FOR_SUBS_FAILED: `${NAME}/UPDATE_PAYMENT_OPTION_FOR_SUBS_FAILED`,
    ACCOUNT_FETCH_SUBS_PAYMENT_OPTIONS_REQUESTED: `${NAME}/ACCOUNT_FETCH_SUBS_PAYMENT_OPTIONS_REQUESTED`,
    FETCHED_STORED_CARDS: `${NAME}/FETCHED_STORED_CARDS`,
    FETCHED_SUBSCRIPTION_PAYMENT_OPTIONS: `${NAME}/FETCHED_SUBSCRIPTION_PAYMENT_OPTIONS`,
    FETCH_ADYEN_ADD_NEW_CARD_PAYMENT_SESSION: `${NAME}/ADYEN_PAYMENT_ADD_NEW_CARD_SESSION`,
    FETCHED_ADYEN_PAYMENT_SESSION: `${NAME}/FETCHED_ADYEN_PAYMENT_SESSION`,
    TOGGLE_ADYEN_PAYMENT_SESSION_LOAD_FAILED: `${NAME}/TOGGLE_ADYEN_PAYMENT_SESSION_LOAD_FAILED`,
    CANCEL_ADD_CARD: `${NAME}/CANCEL_ADD_CARD`,
}
