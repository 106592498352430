import { CheckoutForm } from '@spa-ec/components/Checkout/interfaces'
import { ActionTypes, Modals } from './constants'
import {
    CheckDeliveryModePayload,
    CheckPaymentModePayload,
    DoAddressLookupPayload,
    FetchPickupPointsForAllDeliveryModesPayload,
    SaveCheckoutFormDataPayload,
    SetDisabledFieldsPayload,
    UpdateCheckoutSettingsPayload,
    ValidateAndSaveCheckoutFormDataPayload,
} from './interfaces'

export const fetchOrderConfirmation = (orderId: string) => ({
    type: ActionTypes.FETCH_ORDER_CONFIRMATION,
    payload: {
        orderId,
    },
})

export const notYou = () => ({
    type: ActionTypes.NOT_YOU,
})

export const fetchAdyenPaymentSession = (payload: string) => ({
    type: ActionTypes.FETCH_ADYEN_PAYMENT_SESSION,
    payload,
})

export const finalizeAdyenPaymentSession = (payload: string) => ({
    type: ActionTypes.FINALIZE_ADYEN_PAYMENT_SESSION,
    payload,
})

export const toggleFetchAdyenPaymentSessionFlag = (payload: boolean) => ({
    type: ActionTypes.TOGGLE_ADYEN_PAYMENT_SESSION_LOAD_FAILED,
    payload,
})

export const setCheckoutLocked = (payload: boolean) => ({
    type: ActionTypes.SET_CHECKOUT_LOCKED,
    payload,
})

export const fetchCheckoutSettings = () => ({
    type: ActionTypes.FETCH_CHECKOUT_SETTINGS,
})

export const validateAndSaveCheckoutFormData = (payload: ValidateAndSaveCheckoutFormDataPayload) => ({
    type: ActionTypes.VALIDATE_AND_SAVE_CHECKOUT_FORM_DATA,
    payload,
})

export const validateAndSaveCheckoutFormDataBatch = (payload: ValidateAndSaveCheckoutFormDataPayload[]) => ({
    type: ActionTypes.VALIDATE_AND_SAVE_CHECKOUT_FORM_DATA_BATCH,
    payload: {
        batch: payload,
    },
})

export const saveCheckoutFormData = (payload: SaveCheckoutFormDataPayload) => ({
    type: ActionTypes.SAVE_CHECKOUT_FORM_DATA,
    payload,
})

export const saveCheckoutFormDataBatch = (payload: SaveCheckoutFormDataPayload[]) => ({
    type: ActionTypes.SAVE_CHECKOUT_FORM_DATA_BATCH,
    payload: {
        batch: payload,
    },
})

export const fetchPickupPointsForAllDeliveryModes = (payload: FetchPickupPointsForAllDeliveryModesPayload) => ({
    type: ActionTypes.FETCH_PICKUP_POINTS_FOR_ALL_DELIVERY_MODES,
    payload,
})

export const checkPaymentMode = (payload: CheckPaymentModePayload) => ({
    type: ActionTypes.CHECK_PAYMENT_MODE,
    payload,
})

export const checkDeliveryMode = (payload: CheckDeliveryModePayload) => ({
    type: ActionTypes.CHECK_DELIVERY_MODE,
    payload,
})

export const placeOrder = (checkoutForm: CheckoutForm) => ({
    type: ActionTypes.PLACE_ORDER,
    payload: {
        checkoutForm,
    },
})

export const updateCheckoutSettings = (payload: UpdateCheckoutSettingsPayload) => ({
    type: ActionTypes.UPDATE_CHECKOUT_SETTINGS,
    payload,
})

export const checkSeparateShipmentAddress = (paymentMethod: string) => ({
    type: ActionTypes.CHECK_SEPARATE_SHIPMENT_ADDRESS,
    payload: {
        paymentMethod,
    },
})

export const doAddressLookup = (payload: DoAddressLookupPayload) => ({
    type: ActionTypes.DO_ADDRESS_LOOKUP,
    payload,
})

export const setDisabledFields = (payload: SetDisabledFieldsPayload) => ({
    type: ActionTypes.SET_DISABLED_FIELDS,
    payload,
})

export const fetchDeliveryModes = () => ({
    type: ActionTypes.FETCH_DELIVERY_MODES,
})

export const toggleOpenModal = (modal: Modals) => ({
    type: ActionTypes.TOGGLE_OPEN_MODAL,
    payload: {
        modal,
    },
})
