import { ActionTypes } from './constants'
import { SearchPayload } from './interfaces'

export const autocompleteSearch = (text: string) => ({
    type: ActionTypes.AUTOCOMPLETE_SEARCH,
    payload: {
        text,
    },
})

export const clearAutocompleteSearch = () => ({
    type: ActionTypes.CLEAR_AUTOCOMPLETE_SEARCH,
})

export const clearSearch = () => ({
    type: ActionTypes.CLEAR_SEARCH,
})

export const search = (payload: SearchPayload) => ({
    type: ActionTypes.SEARCH,
    payload,
})
