export const NAME: string = 'SiteLogo'

export type SiteLogoTheme = {
    siteLogo: {
        imagesRootPath: string
        paths: {
            b2c: {
                se?: {
                    sv?: string
                }
                no?: {
                    no?: string
                }
                dk?: {
                    da?: string
                }
                fi?: {
                    sv_FI?: string
                    fi?: string
                }
                default: string
            }
            b2b: {
                se?: {
                    sv?: string
                }
                no?: {
                    no?: string
                }
                dk?: {
                    da?: string
                }
                fi?: {
                    sv_FI?: string
                    fi?: string
                }
                default: string
            }
        }
    }
}
