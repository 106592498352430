import net from '@spa-core-js/services/networkSvc'
import i18n, { TFunction } from 'i18next'
import { initReactI18next } from 'react-i18next'
import browserSvc from '../services/browserSvc'
import { CurrencyCodes, CurrencyIsoLocalesMap, IsoLocales, Locales, PriceFormat, PriceFormats } from './constants'
import { LocalizedKeys } from './interfaces'
import { STR_DEBUG_MODE } from '@spa-ec/components/DebugInfo/DebugInfo'

const LOCALIZATION_ELEMENT_ID: string = 'ssr-locales'
const WINDOW_LOCALIZATION: string = '__LOCALES'
const FALLBACK_LANGUAGE: string = Locales.SV
const INTERPOLATION: any = {
    prefix: '{',
    suffix: '}',
}
const DEBUG_SHOW_STRING_KEYS: boolean = browserSvc.sessionGet(STR_DEBUG_MODE) === 'true'

let language: string
let isDecimalPricingEnabled: boolean

const setKeysForDebug = (keys: LocalizedKeys): LocalizedKeys => {
    Object.keys(keys).forEach((key: string) => {
        keys[key] = key
    })
    return keys
}

export const initLocalesFromDocument = (
    currentLanguageIsocode: string = FALLBACK_LANGUAGE,
    countryIso: string = 'SE',
    isDecimalPricingEnabledArg: boolean = false,
): void => {
    const localesInDocument: any = window[WINDOW_LOCALIZATION]
    if (!localesInDocument) return
    initLocalization(currentLanguageIsocode, countryIso, isDecimalPricingEnabledArg, localesInDocument)
}

export function* initLocalesFromFile(
    contextPath: string,
    siteName: string,
    currentLanguageIsocode: string = FALLBACK_LANGUAGE,
    countryIso: string = 'SE',
    isDecimalPricingEnabledArg: boolean = false,
) {
    const baseLocalePath: string = `${contextPath}/_ui/dist/locale/base_${currentLanguageIsocode}.json`
    const siteLocalePath: string = `${contextPath}/_ui/dist/siteLocale/site-${siteName?.toLocaleLowerCase()}_${currentLanguageIsocode}.json`

    const baseResult: LocalizedKeys = yield net.get(baseLocalePath)
    const siteResult: LocalizedKeys = yield net.get(siteLocalePath) || {}
    const localizedKeys: LocalizedKeys = {
        ...baseResult,
        ...siteResult,
    }
    if (!localizedKeys) return
    initLocalization(currentLanguageIsocode, countryIso, isDecimalPricingEnabledArg, localizedKeys, true)
}

const initLocalization = (
    currentLanguageIsocode: string,
    countryIso: string,
    isDecimalPricingEnabledArg: boolean,
    localizedKeys: LocalizedKeys,
    storeLocalization: boolean = false,
): void => {
    language = currentLanguageIsocode
    isDecimalPricingEnabled = isDecimalPricingEnabledArg
    const localizedKeysAndPricingKeys: LocalizedKeys = {
        ...(!DEBUG_SHOW_STRING_KEYS ? localizedKeys : setKeysForDebug(localizedKeys)),
        ...PriceFormats[language],
    }
    if (!i18n.isInitialized) {
        i18n.use(initReactI18next).init({
            resources: {
                [currentLanguageIsocode]: {
                    [countryIso]: localizedKeysAndPricingKeys,
                },
            },
            lng: currentLanguageIsocode,
            ns: countryIso,
            defaultNS: countryIso,
            fallbackLng: FALLBACK_LANGUAGE,
            debug: __DEV__,
            interpolation: INTERPOLATION,
        })
    } else {
        i18n.addResourceBundle(currentLanguageIsocode, countryIso, localizedKeysAndPricingKeys)
    }
    if (storeLocalization) {
        setLocalesInDocument(localizedKeysAndPricingKeys)
    }
}

const setLocalesInDocument = (localizedKeysAndPricingKeys: LocalizedKeys): void => {
    const localesInDocument: HTMLElement = document?.getElementById(LOCALIZATION_ELEMENT_ID)
    if (localesInDocument) {
        document.head.removeChild(localesInDocument)
    }
    const head: HTMLHeadElement = document.head || document.getElementsByTagName('head')[0]
    const newLocales: HTMLScriptElement = document.createElement('script')
    newLocales.id = LOCALIZATION_ELEMENT_ID
    newLocales.appendChild(
        document.createTextNode(`window.${WINDOW_LOCALIZATION} = ${JSON.stringify(localizedKeysAndPricingKeys)};`),
    )
    head.appendChild(newLocales)
}

export const formatPrice = (val: number, priceFormat: PriceFormat = undefined): string => {
    if (!language) return
    const usePriceFormat: PriceFormat = priceFormat || isDecimalPricingEnabled ? PriceFormat.DECIMAL : PriceFormat.LONG
    return i18n
        .t(usePriceFormat, {
            val: new Intl.NumberFormat(CurrencyIsoLocalesMap[language] || IsoLocales[language], {
                style: 'currency',
                currency: CurrencyCodes[language],
                currencyDisplay: 'code',
            })
                .format(val)
                ?.replace(CurrencyCodes[language], ''),
        })
        ?.replace(/^\s/g, '') // remove leading space
        ?.replace(/\s{2,}/g, ' ') // remove double space
}
export const formatDate = (date: Date): string => date?.toISOString()?.substring(0, 10)
export { PriceFormat }
export const t: TFunction = i18n.t
export default i18n
