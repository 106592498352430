import { ActionTypes } from './constants'
import { CampaignStore } from './interfaces'
import { Action } from '../interfaces'

export const INITIAL_STATE: CampaignStore = {
    redirectUrl: undefined,
    campaignPageId: undefined,
    campaignNumber: undefined,
    campaignActivated: false,
    trackingParams: [],
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): CampaignStore => {
    switch (type) {
        case ActionTypes.CAMPAIGN_ACTIVATED: {
            const { redirectUrl, campaignPageId, campaignNumber, campaignActivated, trackingParams }: CampaignStore = payload
            return {
                redirectUrl,
                campaignPageId,
                campaignNumber,
                campaignActivated,
                trackingParams,
            }
        }
        default:
            return state
    }
}
