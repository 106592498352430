import getStore, { getFromLocalStore } from '@spa-core/redux/store'
import cleanKey from '@spa-core-js/util/cleanKey'
import debounce from '@spa-core/util/debounce'
import { SSR_ELEMENTS_KEY } from '../constants'
import { SSRElements } from '../interfaces'

export const isPuppeteer: boolean = typeof navigator === 'undefined' ? false : navigator.userAgent.indexOf('HeadlessChrome') >= 0
export const isVTT: boolean = typeof navigator === 'undefined' ? false : navigator.userAgent.indexOf('(VTT)') >= 0

const ssrElements: SSRElements = {}

export const injectStyles = (css: string): void => {
    // Clear out the old
    const head = document.head || document.getElementsByTagName('head')[0]
    const style: HTMLStyleElement = document.createElement('style')
    style.id = 'initial-styles'
    style.setAttribute('type', 'text/css')
    if (style.style) {
        // This is required for IE8 and below.
        style.style.cssText = css
    } else {
        style.appendChild(document.createTextNode(css))
    }
    head.appendChild(style)
}

export const setStoreInDocument = (): void => {
    // clear out the old script tag before creating a new one
    // this is required for the old tag to execute
    const documentInitialStateElement: HTMLElement = document?.getElementById('initial-state')
    if (documentInitialStateElement) {
        document.head.removeChild(documentInitialStateElement)
    }
    const head: HTMLHeadElement = document.head || document.getElementsByTagName('head')[0]
    const newInitialState: HTMLScriptElement = document.createElement('script')
    newInitialState.id = 'initial-state'
    head.appendChild(newInitialState)

    newInitialState.appendChild(document.createTextNode(`window.__INITIAL_STATE = ${JSON.stringify(getStore().getState())};`))
    newInitialState.appendChild(
        document.createTextNode(`window.__NAV_SVC_DATA = ${JSON.stringify(getFromLocalStore('__NAV_SVC_DATA'))};`),
    )
}
export const injectState = debounce(setStoreInDocument, 50)

// Pre-render: Extract styles
// !Pre-render: Remove inital styles
export const extractStyles = (): void => {
    const initialStylesElement: HTMLElement = document.getElementById('initial-styles')
    if (initialStylesElement) {
        document.head.removeChild(initialStylesElement)
    }
    let __INITIAL_STYLES: string = ''
    for (let i: number = 0; i < document.styleSheets.length; i++) {
        try {
            for (let j: number = 0; j < document.styleSheets[i].cssRules.length; j++) {
                if (!document.styleSheets[i].href) {
                    __INITIAL_STYLES = `${__INITIAL_STYLES + document.styleSheets[i].cssRules[j].cssText}\n`
                }
            }
        } catch (err) {
            // do nothing
        }
    }
    injectStyles(__INITIAL_STYLES)
}

/**
 *
 * @returns Save the pre-rendered html before hydration
 */
export const getSSRElementsFromDOM = (): void => {
    if (typeof window === 'undefined' || typeof window[SSR_ELEMENTS_KEY] === 'undefined') return
    window[SSR_ELEMENTS_KEY].forEach((key) => {
        const dataSsrKeyElement: Element = document.querySelector(`[data-ssr-key*=${cleanKey(key)}]`)
        if (dataSsrKeyElement) {
            ssrElements[key] = dataSsrKeyElement.outerHTML
        }
    })
}

export const getHtmlForKey = (key: string): string => ssrElements?.[key] || ''

export const stripDiv = (
    html: string,
): {
    html: string
    classesInWrapper: string
} => {
    // Remopve the first part of the html up to the first class attribute
    const firstClassPos: number = html.indexOf('class="')
    html = html.substring(firstClassPos + 7)

    // Copy the content of the string up to the next " character which will extract the classes
    const enfOfClasses: number = html.indexOf('"')
    const classesInWrapper: string = html.substring(0, enfOfClasses)

    // Remove the classes from the string
    html = html.substring(enfOfClasses + 1)

    // Remove everything up to next > character
    const endOfDiv: number = html.indexOf('>')
    html = html.substring(endOfDiv + 1)

    // Find the last </div> and remove it
    const endOfDiv2: number = html.lastIndexOf('</div>')
    html = html.substring(0, endOfDiv2)

    return { html, classesInWrapper }
}
