import { Action } from '../interfaces'
import { ActionTypes } from './constants'
import { AccountStore } from './interfaces'

export const INITIAL_STATE: AccountStore = {
    adyenSessionForNewCardLoading: false,
    adyenPaymentSession: undefined,
    storedTokens: [],
    paymentOptionsForSubscription: [],
    fetchStoredTokensInProgress: false,
    fetchSubscriptionPaymentOptionsInProgress: false,
    updatePaymentInProgress: false,
    paymentModeEditEnabledRecord: {},
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): AccountStore => {
    switch (type) {
        case ActionTypes.ACCOUNT_FETCH_STORED_CARDS_REQUESTED: {
            return {
                ...state,
                fetchStoredTokensInProgress: true,
            }
        }
        case ActionTypes.UPDATE_PAYMENT_OPTION_FOR_SUBS_FAILED: {
            return {
                ...state,
                updatePaymentInProgress: false,
            }
        }
        case ActionTypes.UPDATE_PAYMENT_OPTION_FOR_SUBS_SUCCESS: {
            return {
                ...state,
                updatePaymentInProgress: false,
                paymentModeEditEnabledRecord: {
                    ...state.paymentModeEditEnabledRecord,
                    [payload.subscriptionCode]: false,
                },
            }
        }
        case ActionTypes.TOGGLE_PAYMENT_MODE_EDIT_ENABLED: {
            return {
                ...state,
                paymentModeEditEnabledRecord: {
                    ...state.paymentModeEditEnabledRecord,
                    [payload.subscriptionCode]: payload.value,
                },
            }
        }
        case ActionTypes.UPDATE_PAYMENT_OPTION_FOR_SUBS_REQUESTED: {
            return {
                ...state,
                updatePaymentInProgress: true,
            }
        }
        case ActionTypes.ACCOUNT_FETCH_SUBS_PAYMENT_OPTIONS_REQUESTED: {
            return {
                ...state,
                fetchSubscriptionPaymentOptionsInProgress: true,
            }
        }
        case ActionTypes.FETCHED_STORED_CARDS: {
            return {
                ...state,
                storedTokens: payload,
                fetchStoredTokensInProgress: false,
            }
        }
        case ActionTypes.FETCHED_SUBSCRIPTION_PAYMENT_OPTIONS: {
            return {
                ...state,
                paymentOptionsForSubscription: payload,
                fetchSubscriptionPaymentOptionsInProgress: false,
            }
        }
        case ActionTypes.TOGGLE_ADYEN_PAYMENT_SESSION_LOAD_FAILED: {
            return {
                ...state,
                adyenSessionForNewCardLoading: false,
            }
        }
        case ActionTypes.FETCH_ADYEN_ADD_NEW_CARD_PAYMENT_SESSION: {
            return {
                ...state,
                adyenSessionForNewCardLoading: true,
            }
        }
        case ActionTypes.FETCHED_ADYEN_PAYMENT_SESSION: {
            return {
                ...state,
                adyenPaymentSession: payload,
                adyenSessionForNewCardLoading: false,
            }
        }
        case ActionTypes.CANCEL_ADD_CARD: {
            return {
                ...state,
                adyenPaymentSession: null,
            }
        }
        default:
            return state
    }
}
